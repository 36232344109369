/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import * as summaryRowTypes from './summaryRowTypes';

const SummaryDetailKey = (props) => {
  const {
    keyValue,
    keyType,
    index,
    headerIndex,
    parentTitle,
  } = props;
  const bold = {
    fontWeight: 'bold',
    'padding-left': '15px',
  };

  switch (keyType) {
    case summaryRowTypes.SUMMARY_ROW_IDX:
      return (
        <div key={`header_${parentTitle}_${index}_${keyValue}`} className="col-xs-6">
          <span className="label">{headerIndex}</span> {keyValue}:
        </div>
      );
    case summaryRowTypes.SUMMARY_ROW_H5:
      return <h5 key={`header_${parentTitle}_${index}_${keyValue}`} >{keyValue}</h5>;
    case summaryRowTypes.SUMMARY_ROW_BOLD:
      return <h5 key={`header_${parentTitle}_${index}_${keyValue}`} style={bold}>{keyValue}</h5>;
    default:
      return <div key={`header_${parentTitle}_${index}_${keyValue}`} className="col-xs-6">{keyValue}: </div>;
  }
};

SummaryDetailKey.propTypes = {
  keyValue: PropTypes.string.isRequired,
  keyType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  headerIndex: PropTypes.number.isRequired,
  parentTitle: PropTypes.string.isRequired,
};

export default SummaryDetailKey;
