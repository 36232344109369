import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import { attachRecaptchaToken } from './attachRecaptchaToken';
import Config from '../Config';

const config = new Config();

export function clearApplicationData() {
  return {
    type: actionTypes.CLEAR_APPLICATION_DATA,
  };
}

export function fetchApplicationRequest() {
  return {
    type: actionTypes.FETCH_APPLICATION_REQUEST,
  };
}

export function fetchApplicationSuccess(application) {
  return {
    type: actionTypes.FETCH_APPLICATION_SUCCESS,
    application,
  };
}

export function fetchApplicationError(error) {
  return {
    type: actionTypes.FETCH_APPLICATION_ERROR,
    error,
  };
}

export function fetchApplication(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchApplicationRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchApplicationSuccess(json)))
      .catch(error => dispatch(fetchApplicationError(error)));
  };
}

export function startApplicationRequest() {
  return { type: actionTypes.START_APPLICATION_REQUEST };
}

export function startApplicationError(error) {
  return { type: actionTypes.START_APPLICATION_ERROR, error };
}

export function startApplicationSuccess(applicationId) {
  return { type: actionTypes.START_APPLICATION_SUCCESS, applicationId };
}

export function startApplication(userId, authToken) {
  return (dispatch) => {
    dispatch(startApplicationRequest());
    dispatch(clearApplicationData());
    return attachRecaptchaToken({ userId }, 'application')
      .then((data) => {
        const options = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: authToken,
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify(data),
        };
        return fetch(`${config.apiUrl}/users/${userId}/createApplication`, options);
      })
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(startApplicationSuccess(json)))
      .catch((error) => {
        dispatch(startApplicationError(error));
        return Promise.reject(error);
      });
  };
}

export function submitEntityApplicationRequest() {
  return { type: actionTypes.SUBMIT_ENTITY_APPLICATION_REQUEST };
}

export function submitEntityApplicationError(error) {
  return { type: actionTypes.SUBMIT_ENTITY_APPLICATION_ERROR, error };
}

export function submitEntityApplicationSuccess(application) {
  return { type: actionTypes.SUBMIT_ENTITY_APPLICATION_SUCCESS, application };
}

export function submitEntityApplication(body, applicationId, authToken) {
  return (dispatch) => {
    dispatch(submitEntityApplicationRequest());
    const options = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      mode: 'cors',
    };

    return fetch(
      `${config.apiUrl}/applications/${applicationId}/paperApplication`,
      options,
    )
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(submitEntityApplicationSuccess(json)))
      .catch((error) => {
        dispatch(submitEntityApplicationError(error));
        return Promise.reject(error);
      });
  };
}

export function arkoseTokenValidationRequest() {
  return { type: actionTypes.ARKOSE_TOKEN_VALIDATION_REQUEST };
}

export function arkoseTokenValidationError(error) {
  return { type: actionTypes.ARKOSE_TOKEN_VALIDATION_ERROR, error };
}

export function arkoseTokenValidationSuccess(json) {
  return { type: actionTypes.ARKOSE_TOKEN_VALIDATION_SUCCESS, json };
}

export function arkoseTokenValidation(email, data) {
  const body = {
    email,
    data: JSON.stringify(data),
  };
  return (dispatch) => {
    dispatch(arkoseTokenValidationRequest());
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(body),
    };
    return fetch(`${config.apiUrl}/applications/arkoseValidate`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(arkoseTokenValidationSuccess(json)))
      .catch((error) => {
        dispatch(arkoseTokenValidationError(error));
        return Promise.reject(error);
      });
  };
}
