import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, reduxForm, SubmissionError, change } from 'redux-form';
import { Cookies } from 'react-cookie';
import { findContactByType, isContactDataMissingOrInvalid } from './contactSearchAndFormat';
import AccountTypeForm from '../components/AccountType/AccountTypeForm';
import AccountTypeFormValidation from '../validations/AccountTypeFormValidation';
import * as helper from './AccountTypeFormContainerHelper';
import * as applicationActions from '../actions/applicationActions';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as countriesActions from '../actions/countriesActions';
import * as contactActions from '../actions/contactActions';
import * as salesRepsActions from '../actions/salesRepsActions';
import * as registrationActions from '../actions/registrationActions';
import * as iraAccountActions from '../actions/iraAccountActions';
import * as assetClassActions from '../actions/assetClassActions';
import * as campaingActions from '../actions/campaingActions';
import * as accountTypes from '../components/AccountType/accountTypes';
import * as assetTypes from '../components/AssetClass/assetTypes';
import * as employmentActions from '../actions/employmentActions';
import * as RouteNavigator from './RouteNavigator';
import * as sesssionStorageHelper from '../lib/sessionStorageHelper';
import * as addressActions from '../actions/addressActions';
import { mapSalesRepsToOptions, mapCountryOptions } from './RegistrationCredentialsFormContainer';
import optimizeHelper from '../lib/optimizeHelper';
import Config from '../Config';
import * as storageKeys from '../actions/storageKeys';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';
import { isCryptoSession } from '../lib/cryptoSessionHelper';
import { mapRegionOptions } from '../lib/sortFilterMapCountriesRegions';
import { setCookie, setRemindDate } from '../lib/cookieManager';
import validatePhone from '../validations/PhoneValidation';

const config = new Config();

const isSniderPromo = () => {
  const promoCodeSession = sesssionStorageHelper.getSessionStorage(storageKeys.PROMO_CODE_SESSION);
  return promoCodeSession && promoCodeSession === config.SNIDER_OFFER_CODE;
};

export const hasSalesRepChanged = (contacts, values) => {
  let salesRepChanged = false;
  if (contacts) {
    const contact = findContactByType('primary', contacts);
    salesRepChanged = contact.hearAboutUsSalesRep !== values.hearAboutUsSalesRep;
  }
  return salesRepChanged;
};

export const mapValuesToContact = (contacts, values) => {
  let contact = {};
  const promoCodeSession = sesssionStorageHelper.getSessionStorage(storageKeys.PROMO_CODE_SESSION);
  if (contacts) {
    contact = findContactByType('primary', contacts);
    contact.hearAboutUsSalesRep = values.hearAboutUsSalesRep;
    if (promoCodeSession) {
      contact.promoCode = promoCodeSession;
      setCookie(storageKeys.PROMO_CODE_COOKIE_NAME, promoCodeSession);
    }
  }
  return contact;
};

export const handleContactUpdate = (values, props) => {
  const changed = hasSalesRepChanged(props.contacts, values);
  if (changed) {
    const contact = mapValuesToContact(props.contacts, values);
    return props.actions.updateContact(contact, props.applicationId, props.authToken);
  }
  return Promise.resolve();
};

const mapValuesToJointContact = (values) => {
  const primary = values.primaryContact;
  const joint = values.jointContact;

  return {
    id: joint.id || null,
    type: 'joint',
    countryOfResidence: joint.countryOfResidence ? joint.countryOfResidence : primary.countryOfResidence,
    regionOfResidence: joint.regionOfResidence ? joint.regionOfResidence : primary.regionOfResidence,
    firstName: joint.firstName ? joint.firstName.trim() : '',
    lastName: joint.lastName ? joint.lastName.trim() : '',
    middleInitial: joint.middleInitial ? joint.middleInitial.trim() : null,
    email: joint.email,
    phone: joint.values.phone,
  };
};

export function mapValuesToAccountType(values) {
  const accountType = {
    accountType: values.accountType,
    assetTypes: [],
  };

  if (accountType.accountType === accountTypes.ACCOUNT_TYPE_JOINT) {
    accountType.accountType = values.jointType;
  } else if (accountType.accountType === accountTypes.ACCOUNT_TYPE_ENTITY) {
    accountType.accountType = values.entityType;
  } else if (accountType.accountType === accountTypes.ACCOUNT_TYPE_IRA) {
    accountType.accountType = values.iraType;
  }

  if (values.assetTypeEquities) {
    accountType.assetTypes.push(assetTypes.ASSET_CLASS_TYPES_EQUITIES);
  }

  if (values.assetTypeFutures) {
    accountType.assetTypes.push(assetTypes.ASSET_CLASS_TYPES_FUTURES);
  }

  if (values.assetTypeFuturesOptions) {
    accountType.assetTypes.push(assetTypes.ASSET_CLASS_TYPES_FUTURES_OPTIONS);
  }

  return accountType;
}

export function mapValuesToAssetClass(values) {
  const assetClass = {
    assetTypes: [],
    commissionSchedule: '',
    optionsStrategies: [],
    optionsInvestmentGoals: [],
    tradeFuturesOptions: false,
    tradeStockOptions: false,
    marginAccount: false,
  };

  assetClass.commissionSchedule = values.commissionSchedule;

  if (values.assetTypeEquities) {
    assetClass.assetTypes.push(assetTypes.ASSET_CLASS_TYPES_EQUITIES);

    assetClass.marginAccount = typeof values.equitiesAccountMargin === 'boolean'
      ? values.equitiesAccountMargin
      : false;

    assetClass.tradeStockOptions = typeof values.tradeStockOptions === 'boolean'
      ? values.tradeStockOptions
      : false;

    assetClass.commissionSchedule = values.commissionSchedule;

    if (!assetClass.commissionSchedule) {
      assetClass.commissionSchedule = assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_TSSELECT;
    }

    assetClass.fullyPaidLendingProgram = values.fullyPaidLendingProgram === 'yes';

    if (assetClass.tradeStockOptions) {
      assetClass.optionsInvestmentGoals = helper.mapInvestmentGoals(values.investmentObjectives);
      assetClass.optionsStrategies = helper.mapOptionsStrategies(values.optionsStrategies);
    }
  }

  if (values.assetTypeFutures) {
    assetClass.assetTypes.push(assetTypes.ASSET_CLASS_TYPES_FUTURES);
  }

  if (values.assetTypeFuturesOptions) {
    assetClass.assetTypes.push(assetTypes.ASSET_CLASS_TYPES_FUTURES_OPTIONS); // To satisfy api validation
    assetClass.tradeFuturesOptions = true;
  }

  if (config.optionsEnhancementsEnabled) {
    if (values.assetTypeEquities === true) {
      delete assetClass.optionsStrategies;
      delete assetClass.optionsInvestmentGoals;
      if (typeof values.tradeStockOptions !== 'boolean') delete assetClass.tradeStockOptions;
      if (typeof values.equitiesAccountMargin !== 'boolean') delete assetClass.marginAccount;
    } else {
      assetClass.optionsStrategies = [];
      assetClass.optionsInvestmentGoals = [];
      assetClass.tradeStockOptions = false;
      assetClass.marginAccount = false;
    }
  }
  return assetClass;
}

const submitCampaign = (props) => {
  const cookies = new Cookies();
  const campaign = {
    [storageKeys.UTM_CAMPAIGN_COOKIE_NAME]: cookies.get(storageKeys.UTM_CAMPAIGN_COOKIE_NAME)
      ? cookies.get(storageKeys.UTM_CAMPAIGN_COOKIE_NAME) : 'no_campaign',
    [storageKeys.UTM_CONTENT_COOKIE_NAME]: cookies.get(storageKeys.UTM_CONTENT_COOKIE_NAME)
      ? cookies.get(storageKeys.UTM_CONTENT_COOKIE_NAME) : 'no_content',
    [storageKeys.UTM_MEDIUM_COOKIE_NAME]: cookies.get(storageKeys.UTM_MEDIUM_COOKIE_NAME)
      ? cookies.get(storageKeys.UTM_MEDIUM_COOKIE_NAME) : 'no_medium',
    [storageKeys.UTM_OFFER_COOKIE_NAME]: cookies.get(storageKeys.UTM_OFFER_COOKIE_NAME)
      ? cookies.get(storageKeys.UTM_OFFER_COOKIE_NAME) : 'no_offer',
    [storageKeys.UTM_SOURCE_COOKIE_NAME]: cookies.get(storageKeys.UTM_SOURCE_COOKIE_NAME)
      ? cookies.get(storageKeys.UTM_SOURCE_COOKIE_NAME) : 'no_source',
    [storageKeys.UTM_TERM_COOKIE_NAME]: cookies.get(storageKeys.UTM_TERM_COOKIE_NAME)
      ? cookies.get(storageKeys.UTM_TERM_COOKIE_NAME) : 'no_term',
  };
  const submit = !props.campaignExists ? props.actions.submitCampaign : props.actions.updateCampaign;
  return submit(campaign, props.applicationId, props.authToken);
};

const submitAccountType = (values, props) => {
  const accountType = mapValuesToAccountType(values);
  const submit = !props.accountTypeExists ? props.actions.submitAccountType : props.actions.updateAccountType;
  return submit(accountType, props.applicationId, props.authToken);
};

const submitAssetClass = (values, props) => {
  const assetClass = mapValuesToAssetClass(values);
  const submit = !props.assetClassExists ? props.actions.submitAssetClass : props.actions.updateAssetClass;
  return submit(assetClass, props.applicationId, props.authToken);
};

const submitJointContact = (values, props) => {
  if (values.accountType !== 'joint') return Promise.resolve();

  const jointContact = mapValuesToJointContact(values);
  const submit = (jointContact && jointContact.id > 0) ? props.actions.updateContact : props.actions.submitContact;
  return submit(jointContact, props.applicationId, props.authToken);
};

export class AccountTypeFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChangeAccountType = this.handleOnChangeAccountType.bind(this);
    this.handleOnChangeAssetType = this.handleOnChangeAssetType.bind(this);
    this.handleOnChangeRisk = this.handleOnChangeRisk.bind(this);
    this.handleOnChangeOptions = this.handleOnChangeOptions.bind(this);
    this.handleOnChangeStrategy = this.handleOnChangeStrategy.bind(this);
    this.handleCryptoModalToggleOn = this.handleCryptoModalToggleOn.bind(this);
    this.handleCryptoModalToggleOff = this.handleCryptoModalToggleOff.bind(this);
    this.handleCommissionModalToggleOn = this.handleCommissionModalToggleOn.bind(this);
    this.handleCommissionModalToggleOff = this.handleCommissionModalToggleOff.bind(this);
    this.handleCryptoModalAcknowledgeOn = this.handleCryptoModalAcknowledgeOn.bind(this);
    this.handleCryptoModalAcknowledgeOff = this.handleCryptoModalAcknowledgeOff.bind(this);
    this.handleEquitiesModalToggleOff = this.handleEquitiesModalToggleOff.bind(this);
    this.handleError = this.handleError.bind(this);
    this.initializePhone = this.initializePhone.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.checkNonReduxFormFields = this.checkNonReduxFormFields.bind(this);

    this.state = {
      showEquitiesModal: false,
      showCryptoModal: false,
      cryptoModalAcknowledged: false,
      registration: this.props.registration,
      phoneCountry: 'us',
      phoneStatus: null,
      jointPhone: '',
      accountType: null,
    };
  }

  componentDidMount() {
    const {
      application,
      actions,
      applicationId,
      authToken,
      accountType,
      countries,
      salesReps,
      iraAccount,
      assetClass,
      campaign,
      employment,
      registration,
      address,
      contact,
    } = this.props;

    if (!application) actions.fetchApplication(applicationId, authToken);
    if (countries.length === 0) actions.fetchCountries();
    if (salesReps.length === 0) actions.fetchSalesReps();
    if (!iraAccount) actions.fetchIraAccount(applicationId, authToken);
    if (!campaign) actions.fetchCampaign(applicationId, authToken);
    if (!employment) actions.fetchEmployment(applicationId, authToken);
    if (!address) actions.fetchAddress(applicationId, authToken);
    if (!registration) {
      actions.fetchRegistration(applicationId, authToken)
        .then(() => {
          pushToAnalytics(aopAnalyticsSteps.ACCOUNT_TYPE.name, { applicationId, authToken, registration });
        });
    } else pushToAnalytics(aopAnalyticsSteps.ACCOUNT_TYPE.name, { applicationId, authToken, registration });

    setCookie(
      'ts_incomplete_app',
      {
        remind: 'yes',
        remindDate: setRemindDate(),
      },
      config.pendingApplicationsCookieExpirationDays,
      'strict',
    );

    if (!accountType || !contact || !assetClass) {
      Promise.all([
        actions.fetchContact(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
      ]).then(() => {
        this.initializePhone(this.props.contacts);
      });
    } else this.initializePhone(this.props.contacts);

    optimizeHelper.notify();
  }


  // eslint-disable-next-line
  handleError(error) {
    if (error.response) {
      return error.response.json()
        .then((json) => {
          const errorMessage = json.error.message;
          throw new SubmissionError({ _error: errorMessage });
        });
    }
    throw new SubmissionError({ _error: error.message });
  }

  handleSubmit(values) {
    if (this.props.cryptoWaitlistModalRequired && !values.cryptoModalAcknowledged) {
      this.handleCryptoModalAcknowledgeOn();
    } else if (values.accountType !== 'joint' || (values.accountType === 'joint' &&
    this.state.phoneStatus === 'true')) {
      this.handleCryptoModalToggleOff();
      const ignoreSummaryRedirect = this.props.switched;
      const submit = (newPath) => {
        return submitAccountType(values, this.props)
          .then(() => handleContactUpdate(values, this.props))
          .then(() => {
            submitJointContact(values, this.props)
              .then(() => {
                this.props.actions.fetchAccountType(this.props.applicationId, this.props.authToken);
              })
              .then(() => submitAssetClass(values, this.props));
          })
          .then(() => {
            submitCampaign(this.props)
              .then(() => {
                this.props.actions.fetchCampaign(this.props.applicationId, this.props.authToken)
                  .then(() => {
                    if (values.accountType.indexOf('entity') > -1) {
                      RouteNavigator.push(newPath || '/download-application', newPath !== null || ignoreSummaryRedirect);
                    } else {
                      RouteNavigator.push(newPath || '/personal-info', newPath !== null || ignoreSummaryRedirect);
                    }
                  });
              });
          })
          .catch(this.handleError);
      };
      return submit();
    }
    return null;
  }

  handleOnChangeAccountType(accountType) {
    this.setState({ accountType });
    const contact = findContactByType('primary', this.props.contacts);
    const country = this.props.countries.find((c) => { return c.countryCode === contact.countryOfResidence; });
    const canTradeEquities = helper.canTradeEquities(contact, country);
    const canTradeFutures = helper.canTradeFutures(contact, country, accountType);
    const canTradeCrypto = false;
    const existingCryptoWaitList = (this.props.initialValues && this.props.initialValues.assetTypeCryptoWaitList);
    const isCryptoWaitList =
      helper.isCryptoWaitListApplication(this.props.contacts, this.props.address, accountType, country);

    if (isSniderPromo()) {
      this.props.dispatch(change('accountType', 'investmentObjectives', 0));
      this.props.dispatch(change('accountType', 'optionsStrategies', 0));
    } else {
      if (canTradeEquities) {
        this.props.dispatch(change('accountType', 'assetTypeEquities', true));
      } else {
        this.props.dispatch(change('accountType', 'assetTypeEquities', false));
      }

      if (canTradeFutures) {
        this.props.dispatch(change('accountType', 'assetTypeFutures', true));
      } else {
        this.props.dispatch(change('accountType', 'assetTypeFutures', false));
        this.props.dispatch(change('accountType', 'assetTypeFuturesOptions', false));
      }

      if (canTradeCrypto && !isCryptoWaitList && !existingCryptoWaitList) {
        this.props.dispatch(change('accountType', 'assetTypeCrypto', true));
      } else {
        this.props.dispatch(change('accountType', 'assetTypeCrypto', false));
      }

      if (canTradeCrypto && !isCryptoWaitList && accountType === accountTypes.ACCOUNT_TYPE_INDIVIDUAL) {
        this.props.dispatch(change('accountType', 'assetTypeCrypto', true));
      }

      if (accountType === accountTypes.ACCOUNT_TYPE_IRA) {
        this.props.dispatch(change('accountType', 'assetTypeFutures', false));
        this.props.dispatch(change('accountType', 'assetTypeFuturesOptions', false));
      }
    }

    this.props.dispatch(change('accountType', 'investmentObjectives', 0));
    this.props.dispatch(change('accountType', 'optionsStrategies', 0));
  }

  handleOnChangeAssetType(assetType) {
    if (assetType === 'assetTypeCrypto') {
      if (!this.props.isCryptoSelected) {
        this.props.dispatch(change('accountType', 'assetTypeEquities', true));
      }
    }
    if (assetType === 'assetTypeEquities' && !this.props.isCryptoSelected && !config.optionsEnhancementsEnabled) {
      this.props.dispatch(change('accountType', 'equitiesAccountMargin', true));
    }
  }

  // eslint-disable-next-line
  handleOnChangeRisk(preventDefault, value, previousValue) {
    const isIra = this.props.selectedAccountType && this.props.selectedAccountType.indexOf('ira') > -1;
    const optionsLevel = this.props.optionsLevel;
    this.props.dispatch(change('accountType', 'optionsStrategies',
      helper.setOptionsLevel(optionsLevel, value, previousValue, isIra)));
  }

  // eslint-disable-next-line
  handleOnChangeOptions(preventDefault, value, previousValue) {
    const riskLevel = this.props.riskLevel;
    this.props.dispatch(change('accountType', 'investmentObjectives',
      helper.setInvestmentObjective(riskLevel, value, previousValue)));
  }

  handleOnChangeStrategy() {
    this.props.dispatch(change('accountType', 'investmentObjectives', 0));
    this.props.dispatch(change('accountType', 'optionsStrategies', 0));
  }

  handleCryptoModalToggleOn() {
    this.setState({ showCryptoModal: true });
  }

  handleCryptoModalToggleOff() {
    this.setState({ showCryptoModal: false });
  }

  handleCommissionModalToggleOn() {
    this.setState({ showCommissionModal: true });
  }

  handleCommissionModalToggleOff() {
    this.setState({ showCommissionModal: false });
  }

  handleCryptoModalAcknowledgeOn() {
    this.props.dispatch(change('accountType', 'cryptoModalAcknowledged', true));
  }

  handleCryptoModalAcknowledgeOff() {
    this.props.dispatch(change('accountType', 'cryptoModalAcknowledged', false));
  }

  handleEquitiesModalToggleOff() {
    this.props.dispatch(change('accountType', 'assetTypeEquities', true));
  }

  initializePhone(contacts) {
    const primaryContact = contacts[0];
    const jointContact = contacts.length > 1 ? contacts[1] : null;
    if (primaryContact) {
      let countryOfResidence = primaryContact.countryOfResidence;
      const missingCountriesUK = ['SW', 'IM', 'GG'];

      if (missingCountriesUK.includes(countryOfResidence)) {
        countryOfResidence = 'gb';
      } else if (countryOfResidence === 'EH') {
        countryOfResidence = 'ma';
      }
      this.setState({ phoneCountry: countryOfResidence ? countryOfResidence.toLowerCase() : 'us' });
    }
    if (jointContact && jointContact.phone) {
      this.setState({ jointPhone: jointContact.phone });
      this.handlePhoneChange(this.state.jointPhone);
    }
  }

  handlePhoneChange(phone = null) {
    this.props.dispatch(change('accountType.values.jointContact', 'phone', phone));
    if (!phone || phone.length <= 1) {
      this.setState({ phoneStatus: 'empty' });
    } else if (validatePhone(phone, this.props.countryOfResidence)) {
      this.setState({ phoneStatus: 'false' });
    } else {
      this.setState({ phoneStatus: 'true' });
    }
  }

  checkNonReduxFormFields() {
    if (this.state.phoneStatus === null && this.state.accountType === 'joint') {
      this.setState({ phoneStatus: 'empty' });
    }
  }

  render() {
    return (
      <AccountTypeForm
        {...this.props}
        onSubmit={this.handleSubmit}
        handleOnChangeAccountType={this.handleOnChangeAccountType}
        handleOnChangeAssetType={this.handleOnChangeAssetType}
        salesReps={mapSalesRepsToOptions(this.props.salesReps)}
        handleOnChangeRisk={this.handleOnChangeRisk}
        handleOnChangeOptions={this.handleOnChangeOptions}
        handleOnChangeStrategy={this.handleOnChangeStrategy}
        handleCryptoModalToggleOn={this.handleCryptoModalToggleOn}
        handleCryptoModalToggleOff={this.handleCryptoModalToggleOff}
        handleCommissionModalToggleOn={this.handleCommissionModalToggleOn}
        handleCommissionModalToggleOff={this.handleCommissionModalToggleOff}
        handleCryptoModalAcknowledgeOn={this.handleCryptoModalAcknowledgeOn}
        handleCryptoModalAcknowledgeOff={this.handleCryptoModalAcknowledgeOff}
        showCryptoModal={this.state.showCryptoModal}
        showEquitiesModal={this.state.showEquitiesModal}
        handleEquitiesModalToggleOff={this.handleEquitiesModalToggleOff}
        showCommissionModal={this.state.showCommissionModal}
        cryptoModalAcknowledged={this.state.cryptoModalAcknowledged}
        countries={mapCountryOptions(this.props.countries)}
        regions={mapRegionOptions(this.props.countries, this.props.countryOfResidence, true)}
        isExistingCustomer={this.props.isExistingCustomer}
        handlePhoneChange={this.handlePhoneChange}
        phoneCountry={this.state.phoneCountry}
        phoneStatus={this.state.phoneStatus}
        jointPhone={this.state.jointPhone}
        checkNonReduxFormFields={this.checkNonReduxFormFields}
      />
    );
  }
}

AccountTypeFormContainer.propTypes = {
  switched: PropTypes.bool,
  application: PropTypes.shape(),
  accountType: PropTypes.shape(),
  contact: PropTypes.shape(),
  assetClass: PropTypes.shape(),
  initialValues: PropTypes.shape(),
  accountTypeExists: PropTypes.bool,
  assetClassExists: PropTypes.bool,
  jointContactExists: PropTypes.bool,
  campaign: PropTypes.shape(),
  campaignExists: PropTypes.bool,
  actions: PropTypes.shape({
    fetchApplication: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    submitAccountType: PropTypes.func.isRequired,
    updateAccountType: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchSalesReps: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    fetchIraAccount: PropTypes.func.isRequired,
    updateIraAccount: PropTypes.func.isRequired,
    submitContact: PropTypes.func.isRequired,
    updateContact: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    submitAssetClass: PropTypes.func.isRequired,
    updateAssetClass: PropTypes.func.isRequired,
    fetchCampaign: PropTypes.func.isRequired,
    fetchEmployment: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape()),
  salesReps: PropTypes.arrayOf(PropTypes.shape()),
  contacts: PropTypes.arrayOf(PropTypes.shape()),
  registration: PropTypes.shape(),
  iraAccount: PropTypes.shape(),
  employment: PropTypes.shape(),
  address: PropTypes.shape(),
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  selectedAccountType: PropTypes.string.isRequired,
  isFuturesOptionsSelected: PropTypes.bool.isRequired,
  isFuturesSelected: PropTypes.bool.isRequired,
  isEquitiesSelected: PropTypes.bool.isRequired,
  isCryptoSelected: PropTypes.bool,
  canTradeEquities: PropTypes.bool.isRequired,
  canTradeFutures: PropTypes.bool.isRequired,
  canTradeCrypto: PropTypes.bool.isRequired,
  canTradeOptions: PropTypes.bool.isRequired,
  canTradeMargin: PropTypes.bool.isRequired,
  cryptoWaitlistModalRequired: PropTypes.bool.isRequired,
  cryptoModalAcknowledged: PropTypes.bool,
  riskLevel: PropTypes.number,
  optionsLevel: PropTypes.number.isRequired,
  dispatch: PropTypes.func,
  countryOfResidence: PropTypes.string,
  hasCryptoAccount: PropTypes.bool,
  isExistingCustomer: PropTypes.bool,
};

function mapContact(contact) {
  return {
    id: contact ? contact.id : null,
    type: contact ? contact.type : 'joint',
    firstName: contact ? contact.firstName : '',
    middleInitial: contact ? contact.middleInitial : '',
    lastName: contact ? contact.lastName : '',
    countryOfResidence: contact ? contact.countryOfResidence : '',
    regionOfResidence: contact ? contact.regionOfResidence : '',
    email: contact ? contact.email : '',
    phone: contact ? contact.phone : '',
    hearAboutUsSalesRep: contact ? contact.hearAboutUsSalesRep : '',
  };
}

export function mapStateToInitialValues(state) {
  const dbApplication = (state && state.application) ? state.application : null;
  const isReturnedApplication = (dbApplication && dbApplication.status.indexOf('returned') > -1);
  const hasCryptoAccount = (dbApplication && dbApplication.hasCryptoAccount) || isCryptoSession();
  const isTSGOAvailable = helper.isTSGOAvailable(state.contact, state.employment);
  const salesRepSession = sesssionStorageHelper.getSessionStorage(storageKeys.SALES_REP_SESSION);
  const promoCodeSession = sesssionStorageHelper.getSessionStorage(storageKeys.PROMO_CODE_SESSION);

  let accountType = accountTypes.ACCOUNT_TYPE_INDIVIDUAL;
  let jointType = accountTypes.JOINT_WITH_RIGHTS_OF_SURVIVORSHIP;
  let entityType = accountTypes.ENTITY_CORPORATE;
  let iraType = accountTypes.IRA_TRADITIONAL;
  let hearAboutUsSalesRep = '';
  let assets = [];
  let primaryContact = {};
  let jointContact = {};
  let contacts = [];
  let assetTypeCrypto = false;
  let assetTypeCryptoWaitList = false;
  let isExistingCustomer = false;

  let assetClass = {
    assetTypes: [],
    marginAccount: true,
    commissionSchedule: assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_TSSELECT,
    tradeStockOptions: true,
    tradeFuturesOptions: false,
    optionsStrategies: [],
    optionsInvestmentGoals: [],
    fullyPaidLendingProgram: true,
  };

  if (state.assetClass) {
    assetClass = JSON.parse(JSON.stringify(state.assetClass));
    if (assetClass.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_EQUITIES) < 0) {
      assetClass.fullyPaidLendingProgram = true;
    }

    if (state.assetClass.commissionSchedule === (assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_PER_TRADE
      || assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_PER_SHARE)) {
      assetClass.commissionSchedule = assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_TSSELECT;
    }
    if (state.assetClass.commissionSchedule === assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_TSGO && !isTSGOAvailable) {
      assetClass.commissionSchedule = assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_TSSELECT;
    }
  }

  if (state.accountType) {
    if (state.accountType.accountType.indexOf('joint') > -1) {
      accountType = accountTypes.ACCOUNT_TYPE_JOINT;
      jointType = state.accountType.accountType;
    } else if (state.accountType.accountType.indexOf('entity') > -1) {
      accountType = accountTypes.ACCOUNT_TYPE_ENTITY;
      entityType = state.accountType.accountType;
    } else if (state.accountType.accountType.indexOf('ira') > -1) {
      accountType = accountTypes.ACCOUNT_TYPE_IRA;
      iraType = state.accountType.accountType;
    } else {
      accountType = state.accountType.accountType;
    }

    assets = state.accountType.assetTypes;
    assetTypeCrypto = assets.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO);
    assetTypeCryptoWaitList = assets.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO_WAITLIST);
  }

  if (state && state.registration && state.registration.isExistingCustomer) {
    isExistingCustomer = true;
  }

  if (state && state.contact && state.contact.length > 0) {
    contacts = state.contact;
    jointContact = mapContact(findContactByType('joint', contacts));
    primaryContact = mapContact(findContactByType('primary', contacts));
    hearAboutUsSalesRep = primaryContact.hearAboutUsSalesRep;
    if (!jointContact.countryOfResidence) { jointContact.countryOfResidence = primaryContact.countryOfResidence; }
    if (assets.length === 0) {
      const contactCountry =
        state.countries.find((c) => { return c.countryCode === primaryContact.countryOfResidence; });
        assets = helper.getInitialAssets(primaryContact, contactCountry, hasCryptoAccount, promoCodeSession);
    }
  }

  if (hasCryptoAccount) { assetTypeCrypto = false; }

  if (salesRepSession && isExistingCustomer && !hearAboutUsSalesRep) hearAboutUsSalesRep = salesRepSession;

  if (state.contact && state.contact.length > 0) {
    const country = state.contact[0].countryOfResidence;
    if (!helper.canTradeOptions(country)) assetClass.tradeStockOptions = false;
    if (!helper.canTradeMargin(country)) assetClass.marginAccount = false;
  }

  if (config.optionsEnhancementsEnabled) {
    if (state && state.assetClass && typeof state.assetClass.marginAccount === 'boolean') {
      assetClass.marginAccount = state.assetClass.marginAccount;
    } else {
      delete assetClass.marginAccount;
    }

    if (state && state.assetClass && typeof state.assetClass.tradeStockOptions === 'boolean') {
      assetClass.tradeStockOptions = state.assetClass.tradeStockOptions;
    } else {
      delete assetClass.tradeStockOptions;
    }
  }

  return {
    accountType,
    jointType,
    entityType,
    iraType,
    isReturnedApplication,
    hearAboutUsSalesRep,
    primaryContact,
    jointContact,
    contacts,
    assetTypeEquities: assets.includes(assetTypes.ASSET_CLASS_TYPES_EQUITIES),
    assetTypeFutures: assets.includes(assetTypes.ASSET_CLASS_TYPES_FUTURES),
    assetTypeFuturesOptions: assets.includes(assetTypes.ASSET_CLASS_TYPES_FUTURES_OPTIONS),
    assetTypeCrypto: assetTypeCrypto || assets.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO),
    assetTypeCryptoWaitList,
    equitiesAccountMargin: assetClass.marginAccount,
    commissionSchedule: assetClass.commissionSchedule,
    tradeStockOptions: assetClass.tradeStockOptions,
    tradeFuturesOptions: assetClass.tradeFuturesOptions,
    fullyPaidLendingProgram: assetClass.fullyPaidLendingProgram ? 'yes' : 'no',
    optionsStrategies: assetClass.optionsStrategies ? helper.setOptionsStrategies(assetClass.optionsStrategies) : [],
    investmentObjectives: assetClass.optionsInvestmentGoals ? helper.setInvestmentGoals(assetClass.optionsInvestmentGoals) : [],
    cryptoModalAcknowledged: false,
  };
}


export function mapStateToProps(state) {
  const selector = formValueSelector('accountType');
  const selectedAccountType = selector(state, 'accountType');
  const selectedJointContact = selector(state, 'jointContact');
  const valuesAssetClass = selector(state,
    'accountType',
    'assetTypeEquities',
    'assetTypeFutures',
    'assetTypeFuturesOptions',
    'assetTypeCrypto',
    'tradeStockOptions',
    'tradeFuturesOptions',
    'equitiesAccountMargin',
    'investmentObjectives',
    'optionsStrategies',
    'fullyPaidLendingProgram',
    'cryptoModalAcknowledged',
  );
  const isEquitiesSelected = !!valuesAssetClass.assetTypeEquities;
  const isFuturesSelected = !!valuesAssetClass.assetTypeFutures;
  const isFuturesOptionsSelected = !!valuesAssetClass.assetTypeFuturesOptions;
  const isCryptoSelected = false;

  let country;
  let canTrade = {};
  let isLoading = false;
  let cryptoWaitlistModalRequired = false;
  let hasCryptoAccount = false;
  let isTSGOAvailable = false;
  let missingData = false;
  let countryOfResidence;
  let isExistingCustomer = false;

  if (state.registration && state.registration.isExistingCustomer) {
    isExistingCustomer = true;
  }

  if ((state.application && state.application.hasCryptoAccount) || isCryptoSession()) {
    hasCryptoAccount = true;
  }

  if (state.contact && state.contact.length > 0) {
    const primaryContact = findContactByType('primary', state.contact);
    const countryData = state.countries.find((c) => { return c.countryCode === primaryContact.countryOfResidence; });
    missingData = isContactDataMissingOrInvalid(primaryContact, countryData);
    if (missingData) RouteNavigator.push('/registration?status=missinginfo');
  }

  if (state.contact && state.contact.length > 0 && !missingData) {
    const primaryContact = findContactByType('primary', state.contact);
    if (state.countries && state.countries.length > 0) {
      isLoading = true;
      country = state.countries.find((c) => { return c.countryCode === primaryContact.countryOfResidence; });
      if (selectedAccountType) {
        canTrade = {
          Equities: helper.canTradeEquities(primaryContact, country),
          Futures: helper.canTradeFutures(primaryContact, country, selectedAccountType),
          Crypto: helper.canTradeCrypto(primaryContact, country, selectedAccountType, hasCryptoAccount),
          Options: helper.canTradeOptions(country.countryCode),
          Margin: helper.canTradeMargin(country.countryCode),
        };
        if (isCryptoSelected) {
          cryptoWaitlistModalRequired =
            helper.isCryptoWaitListApplication(state.contact, state.address, selectedAccountType, country);
          if (selectedJointContact && selectedAccountType.indexOf('joint') > -1) {
            cryptoWaitlistModalRequired =
              cryptoWaitlistModalRequired || helper.isCryptoWaitListContact(selectedJointContact, country);
          }
        }
      }
    }
    isTSGOAvailable = helper.isTSGOAvailable(state.contact, state.employment);
  }

  const assetClassExceptionMessage =
    helper.getAssetClassExceptionMessage(state.contact, selectedAccountType);
  const tradeStockOptionsSelected = valuesAssetClass.tradeStockOptions;
  const tradeFuturesOptionsSelected = valuesAssetClass.tradeFuturesOptions;

  const investmentRiskMarks = helper.setInvestmentRiskMarks(selectedAccountType, valuesAssetClass);
  const optionsStrategiesMarks = helper.setOptionsStrategiesMarks(selectedAccountType, valuesAssetClass);
  const riskLevel = valuesAssetClass.investmentObjectives;
  const optionsLevel = valuesAssetClass.optionsStrategies;
  const optionsLevelLabel = parseInt(optionsLevel / 25, 10) + 1;

  const initialValues = mapStateToInitialValues(state);
  const switched = helper.switchedToJoint(selectedAccountType, initialValues.accountType);
  const jointContactExists = initialValues.jointContact && initialValues.jointContact.id > 0;
  const cryptoModalAcknowledged = valuesAssetClass.cryptoModalAcknowledged;

  // remove this toggle once the Futures IRA feature is operational
  const showInheritedIras = (selectedAccountType !== accountTypes.ACCOUNT_TYPE_IRA);

  if (initialValues.primaryContact) {
    countryOfResidence = initialValues.primaryContact.countryOfResidence;
  }

  if (initialValues && initialValues.assetTypeCryptoWaitList) {
    canTrade.Crypto = false;
    if (initialValues.primaryContact && country) {
      const wasJointAccountType = initialValues.accountType === accountTypes.ACCOUNT_TYPE_JOINT;
      const canPrimaryCrypto =
        helper.canTradeCrypto(initialValues.primaryContact, country, selectedAccountType, hasCryptoAccount);
      if (wasJointAccountType && selectedAccountType === accountTypes.ACCOUNT_TYPE_INDIVIDUAL && canPrimaryCrypto) {
        canTrade.Crypto = true;
      }
    }
  }

  if (state && state.registration && state.accountType) {
    setCookie(
      'pendingApplication',
      { userId: state.registration.userId, fdcnId: state.registration.fdcnId },
      config.pendingApplicationsCookieExpirationDays,
    );
  }

  return {
    initialValues,
    isLoading,
    switched,
    selectedAccountType: selectedAccountType || '',
    assetClassExceptionMessage,
    showIraAccountType: country && country.countryCode === 'US',
    showSalesRep: true,
    showInheritedIras,
    canTradeCrypto: !!canTrade.Crypto,
    canTradeEquities: !!canTrade.Equities,
    canTradeFutures: !!canTrade.Futures,
    canTradeOptions: canTrade.Options,
    canTradeMargin: canTrade.Margin,
    isEquitiesSelected: !!isEquitiesSelected,
    isFuturesSelected: !!isFuturesSelected,
    isFuturesOptionsSelected: !!isFuturesOptionsSelected,
    isCryptoSelected: !!isCryptoSelected,
    isTSGOAvailable: !!isTSGOAvailable,
    applicationId: state.applicationId,
    authToken: state.authToken,
    accountTypeExists: !!state.accountType,
    assetClassExists: !!state.assetClass,
    jointContactExists,
    campaign: state.campaign,
    campaignExists: !!state.campaign,
    countries: state.countries,
    salesReps: state.salesReps,
    iraAccount: state.iraAccount,
    contacts: initialValues.contacts,
    address: state.address,
    jointContact: initialValues.jointContact,
    primaryContact: initialValues.primaryContact,
    investmentRiskMarks,
    optionsStrategiesMarks,
    riskLevel,
    optionsLevel,
    optionsLevelLabel,
    tradeStockOptionsSelected,
    tradeFuturesOptionsSelected,
    isReturnedApplication: !!initialValues.isReturnedApplication,
    showFplQuestion: false,
    showCryptoModal: state.showCryptoModal,
    showEquitiesModal: state.showEquitiesModal,
    cryptoModalAcknowledged,
    cryptoWaitlistModalRequired,
    missingData,
    registration: state.registration,
    countryOfResidence,
    hasCryptoAccount,
    isExistingCustomer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(
      {},
      applicationActions,
      accountTypeActions,
      contactActions,
      countriesActions,
      registrationActions,
      iraAccountActions,
      salesRepsActions,
      assetClassActions,
      campaingActions,
      employmentActions,
      addressActions,
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'accountType',
  validate: AccountTypeFormValidation,
})(AccountTypeFormContainer));
