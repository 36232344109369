import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import * as assetClassActions from '../actions/assetClassActions';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as contactActions from '../actions/contactActions';
import * as tradingProfileActions from '../actions/tradingProfileActions';
import * as countriesActions from '../actions/countriesActions';
import * as accountTypes from '../components/AccountType/accountTypes';
import * as financialActions from '../actions/financialActions';
import * as assetTypes from '../components/AssetClass/assetTypes';
import EquitiesAccountSettings from '../components/EquitiesAccountSettings/EquitiesAccountSettings';
import {
  mapOptionsStrategyLevel,
  setOptionsStrategyLevel,
  mapOptionsInvestmentObjective,
  setOptionsInvestmentObjective,
  canTradeOptions,
  canTradeMargin,
} from './AccountTypeFormContainerHelper';
import { findContactByType } from './contactSearchAndFormat';
import * as RouteNavigator from './RouteNavigator';

export class EquitiesAccountSettingsContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExitModal: false,
    };

    this.handleExitModalOn = this.handleExitModalOn.bind(this);
    this.handleExitModalOff = this.handleExitModalOff.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getMaxOptionsLevel = this.getMaxOptionsLevel.bind(this);
    this.handleMaxOptionsLevel = this.handleMaxOptionsLevel.bind(this);
    this.handleTradeStockOptionsChange = this.handleTradeStockOptionsChange.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      applicationId,
      authToken,
      assetClass,
      accountType,
      contact,
      tradingProfile,
      countries,
      financial,
    } = this.props;

    if (!assetClass) actions.fetchAssetClass(applicationId, authToken);
    if (!accountType) actions.fetchAccountType(applicationId, authToken);
    if (!contact) actions.fetchContact(applicationId, authToken);
    if (!tradingProfile) actions.fetchTradingProfile(applicationId, authToken);
    if (!countries || countries.length === 0) actions.fetchCountries();
    if (!financial) actions.fetchFinancial(applicationId, authToken);
  }

  getMaxOptionsLevel() {
    const {
      marginAccount,
      investmentObjective,
      primaryAnnualIncome,
      primaryLiquidNetWorth,
      primaryLiquidNetWorthSpecified,
      primaryYearsExperienceOptions,
      isIraApplication,
      jointYearsExperienceOptions,
      jointAnnualIncome,
      jointLiquidNetWorth,
      jointLiquidNetWorthSpecified,
      isJointApp,
    } = this.props;
    const hasAtLeastOneYearOfOptionsExperience =
      primaryYearsExperienceOptions === '1to5years' || primaryYearsExperienceOptions === 'over5years' ||
      jointYearsExperienceOptions === '1to5years' || jointYearsExperienceOptions === 'over5years';
    let annualIncome = Number(primaryAnnualIncome);
    let liquidNetWorth = Number(primaryLiquidNetWorth);
    let liquidNetWorthSpecified = Number(primaryLiquidNetWorthSpecified);
    if (isJointApp) {
      if (Number(jointAnnualIncome) > annualIncome) annualIncome = Number(jointAnnualIncome);
      if (Number(jointLiquidNetWorth) > liquidNetWorth) liquidNetWorth = Number(jointLiquidNetWorth);
      if (liquidNetWorth === 1 && Number(jointLiquidNetWorthSpecified) > liquidNetWorthSpecified) {
        liquidNetWorthSpecified = Number(jointLiquidNetWorthSpecified);
      }
    }

    // Levels 4 and 5
    if (marginAccount &&
      investmentObjective === 'speculation' &&
      (annualIncome >= 2) &&
      (liquidNetWorth >= 2 || liquidNetWorthSpecified >= 50000) &&
      hasAtLeastOneYearOfOptionsExperience) {
        return isIraApplication ? 4 : 5; // IRA accounts have a max level of 4
      }

    if (investmentObjective === 'growth' || investmentObjective === 'speculation') {
      // Level 3
      if ((liquidNetWorth >= 2 || liquidNetWorthSpecified >= 25000) &&
      hasAtLeastOneYearOfOptionsExperience) {
        return 3;
      }

      // Level 2
      if (liquidNetWorth >= 2 || liquidNetWorthSpecified >= 10000) {
        return 2;
      }
    }

    // Level 1
    return 1;
  }

  handleMaxOptionsLevel() {
    const maxOptionsLevel = this.getMaxOptionsLevel();
    const { selectedLevel, dispatch } = this.props;
    if (maxOptionsLevel < selectedLevel) dispatch(change('equitiesAccountSettings', 'selectedLevel', null));
    return maxOptionsLevel;
  }

  // eslint-disable-next-line class-methods-use-this
  handleBack() {
    RouteNavigator.push('/financial');
  }

  handleExitModalOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModalOff() {
    this.setState({ showExitModal: false });
  }

  handleTradeStockOptionsChange(event) {
    const { dispatch } = this.props;
    if (!event.target.checked) {
      dispatch(change('equitiesAccountSettings', 'investmentObjective', null));
    }
  }

  handleSubmit(values) {
    const { assetClass } = this.props;
    const tradeStockOptions = typeof values.tradeStockOptions === 'boolean' ? values.tradeStockOptions : false;
    const newAssetClass = {
      ...assetClass,
      tradeStockOptions,
      marginAccount: typeof values.marginAccount === 'boolean' ? values.marginAccount : false,
      optionsStrategies: values.selectedLevel && tradeStockOptions ? mapOptionsStrategyLevel(values.selectedLevel) : [],
      optionsInvestmentGoals: values.investmentObjective && tradeStockOptions ?
        mapOptionsInvestmentObjective(values.investmentObjective) : [],
    };

    this.props.actions.updateAssetClass(newAssetClass, this.props.applicationId, this.props.authToken)
    .then(() => {
      if (this.props.isIraApplication && this.props.isEquitiesApplication) {
        RouteNavigator.push('/ira-account');
      } else {
        RouteNavigator.push('/additional-info');
      }
    });
  }

  render() {
    return (
      <Field
        {...this.props}
        component={EquitiesAccountSettings}
        onSubmit={this.handleSubmit}
        onBack={this.handleBack}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModalOn}
        onExitModalOff={this.handleExitModalOff}
        selectedLevel={this.props.selectedLevel}
        initialValues={this.props.initialValues}
        handleMaxOptionsLevel={this.handleMaxOptionsLevel}
        handleTradeStockOptionsChange={this.handleTradeStockOptionsChange}
        name="selectedLevel"
      />
    );
  }
}

EquitiesAccountSettingsContainer.propTypes = {
  applicationId: PropTypes.string,
  authToken: PropTypes.string,
  assetClass: PropTypes.shape(),
  accountType: PropTypes.shape(),
  contact: PropTypes.shape(),
  tradingProfile: PropTypes.shape(),
  countries: PropTypes.arrayOf(PropTypes.shape()),
  financial: PropTypes.shape(),
  actions: PropTypes.shape({
    updateAssetClass: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    fetchTradingProfile: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchFinancial: PropTypes.func.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedLevel: PropTypes.number,
  initialValues: PropTypes.shape(),
  isIraApplication: PropTypes.bool.isRequired,
  isEquitiesApplication: PropTypes.bool.isRequired,
  getMaxOptionsLevel: PropTypes.func,
  handleMaxOptionsLevel: PropTypes.func,
  canTradeOptions: PropTypes.bool,
  canTradeMargin: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  investmentObjective: PropTypes.string,
  marginAccount: PropTypes.bool,
  primaryYearsExperienceOptions: PropTypes.string,
  primaryAnnualIncome: PropTypes.string,
  primaryAnnualIncomeSpecified: PropTypes.string,
  primaryLiquidNetWorth: PropTypes.string,
  primaryLiquidNetWorthSpecified: PropTypes.string,
  jointYearsExperienceOptions: PropTypes.string,
  jointAnnualIncome: PropTypes.string,
  jointAnnualIncomeSpecified: PropTypes.string,
  jointLiquidNetWorth: PropTypes.string,
  jointLiquidNetWorthSpecified: PropTypes.string,
  isJointApp: PropTypes.bool,
};

function mapStateToInitialValues(state) {
  let selectedLevel = null;
  let tradeStockOptions = false;
  let marginAccount = false;
  let investmentObjective = null;
  let primaryTradingProfile;
  let jointTradingProfile;
  let primaryYearsExperienceOptions;
  let primaryAnnualIncome;
  let primaryAnnualIncomeSpecified;
  let primaryLiquidNetWorth;
  let primaryLiquidNetWorthSpecified;
  let jointYearsExperienceOptions;
  let jointAnnualIncome;
  let jointAnnualIncomeSpecified;
  let jointLiquidNetWorth;
  let jointLiquidNetWorthSpecified;
  let isJointApp = false;

  if (state.contact && state.contact.length > 0) {
    const primaryContact = findContactByType('primary', state.contact);
    const jointContact = findContactByType('joint', state.contact);
    if (state.tradingProfile && state.tradingProfile.length > 0) {
      primaryTradingProfile = state.tradingProfile.find((f) => { return f.contactId === primaryContact.id; });
      primaryYearsExperienceOptions = primaryTradingProfile.yearsExperienceOptions;
      if (state.tradingProfile.length > 1) {
        jointTradingProfile = state.tradingProfile.find((f) => { return f.contactId === jointContact.id; });
        jointYearsExperienceOptions = jointTradingProfile.yearsExperienceOptions;
      }
    }

    if (state.financial && state.financial.length > 0) {
      const primaryFinancial = state.financial.find((f) => { return f.contactId === primaryContact.id; });
      primaryAnnualIncome = primaryFinancial.annualIncome;
      primaryAnnualIncomeSpecified = primaryFinancial.annualIncomeSpecified;
      primaryLiquidNetWorth = primaryFinancial.liquidNetWorth;
      primaryLiquidNetWorthSpecified = primaryFinancial.liquidNetWorthSpecified;
      if (jointContact && state.financial.length > 1) {
        const jointFinancial = state.financial.find((f) => { return f.contactId === jointContact.id; });
        isJointApp = true;
        jointAnnualIncome = jointFinancial.annualIncome;
        jointAnnualIncomeSpecified = jointFinancial.annualIncomeSpecified;
        jointLiquidNetWorth = jointFinancial.liquidNetWorth;
        jointLiquidNetWorthSpecified = jointFinancial.liquidNetWorthSpecified;
      }
    }
  }

  if (state && state.assetClass) {
    selectedLevel = state.assetClass.optionsStrategies && state.assetClass.optionsStrategies.length > 0 ?
    setOptionsStrategyLevel(state.assetClass.optionsStrategies) : null;
    investmentObjective = state.assetClass.optionsInvestmentGoals &&
      state.assetClass.optionsInvestmentGoals.length > 0 ?
      setOptionsInvestmentObjective(state.assetClass.optionsInvestmentGoals) : null;
    tradeStockOptions = state.assetClass.tradeStockOptions !== null ? state.assetClass.tradeStockOptions :
      primaryTradingProfile && primaryTradingProfile.yearsExperienceOptions !== 'none';
    marginAccount = state.assetClass.marginAccount !== null ? state.assetClass.marginAccount : true;
  }

  const values = {
    selectedLevel,
    tradeStockOptions,
    marginAccount,
    investmentObjective,
    primaryYearsExperienceOptions,
    primaryAnnualIncome,
    primaryAnnualIncomeSpecified,
    primaryLiquidNetWorth,
    primaryLiquidNetWorthSpecified,
    jointYearsExperienceOptions,
    jointAnnualIncome,
    jointAnnualIncomeSpecified,
    jointLiquidNetWorth,
    jointLiquidNetWorthSpecified,
    isJointApp,
  };

  return values;
}

function mapStateToProps(state) {
  const initialValues = mapStateToInitialValues(state);
  const selector = formValueSelector('equitiesAccountSettings');
  const valuesAssetClass =
    selector(state, 'marginAccount', 'tradeStockOptions', 'investmentObjective', 'selectedLevel');
  let isIraApplication = false;
  let isEquitiesApplication = false;
  let country;
  let canTrade = {};

  const iraAccountTypes = [
    accountTypes.IRA_SEP,
    accountTypes.IRA_SIMPLE,
    accountTypes.IRA_ROTH,
    accountTypes.IRA_ROTH_INHERITED,
    accountTypes.IRA_TRADITIONAL,
    accountTypes.IRA_TRADITIONAL_INHERITED,
  ];

  if (state.accountType) {
    if (iraAccountTypes.includes(state.accountType.accountType)) {
      isIraApplication = true;
    }

    if (state.accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_EQUITIES)) {
      isEquitiesApplication = true;
    }
  }

  if (state.contact && state.contact.length > 0) {
    const primaryContact = findContactByType('primary', state.contact);
    if (state.countries && state.countries.length > 0) {
      country = state.countries.find((c) => { return c.countryCode === primaryContact.countryOfResidence; });
      canTrade = {
        Options: canTradeOptions(country.countryCode),
        Margin: canTradeMargin(country.countryCode),
      };
      if (!canTrade.Margin) initialValues.marginAccount = false;
      if (!canTrade.Options) initialValues.tradeStockOptions = false;
    }
  }

  return {
    initialValues,
    applicationId: state.applicationId,
    authToken: state.authToken,
    assetClass: state.assetClass,
    isIraApplication,
    isEquitiesApplication,
    canTradeOptions: canTrade.Options,
    canTradeMargin: canTrade.Margin,
    marginAccount: valuesAssetClass ? !!valuesAssetClass.marginAccount : false,
    tradeStockOptions: valuesAssetClass ? !!valuesAssetClass.tradeStockOptions : false,
    investmentObjective: valuesAssetClass ? valuesAssetClass.investmentObjective : null,
    selectedLevel: valuesAssetClass ? valuesAssetClass.selectedLevel : null,
    primaryYearsExperienceOptions: initialValues.primaryYearsExperienceOptions,
    primaryAnnualIncome: initialValues.primaryAnnualIncome,
    primaryAnnualIncomeSpecified: initialValues.primaryAnnualIncomeSpecified,
    primaryLiquidNetWorth: initialValues.primaryLiquidNetWorth,
    primaryLiquidNetWorthSpecified: initialValues.primaryLiquidNetWorthSpecified,
    jointYearsExperienceOptions: initialValues.jointYearsExperienceOptions,
    jointAnnualIncome: initialValues.jointAnnualIncome,
    jointAnnualIncomeSpecified: initialValues.jointAnnualIncomeSpecified,
    jointLiquidNetWorth: initialValues.jointLiquidNetWorth,
    jointLiquidNetWorthSpecified: initialValues.jointLiquidNetWorthSpecified,
    isJointApp: initialValues.isJointApp,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(
      {},
      accountTypeActions,
      assetClassActions,
      contactActions,
      tradingProfileActions,
      financialActions,
      countriesActions),
      dispatch),
  };
}

const validate = (values) => {
  const errors = {};
  if (values && values.tradeStockOptions) {
    if (!values.investmentObjective) {
        errors.investmentObjective = 'Required';
    }
    if (!values.selectedLevel && values.investmentObjective) {
      errors.selectedLevel = 'You must select an options level';
    }
  }

  return Object.keys(errors).length === 0 ? null : errors;
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'equitiesAccountSettings',
  validate,
})(EquitiesAccountSettingsContainer));
