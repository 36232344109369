/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, SubmissionError, change, stopSubmit } from 'redux-form';
import * as countriesActions from '../actions/countriesActions';
import * as salesRepsActions from '../actions/salesRepsActions';
import * as hearAboutUsActions from '../actions/hearAboutUsActions';
import * as securityCredentialsActions from '../actions/securityCredentialsActions';
import * as registrationCredentialsActions from '../actions/registrationCredentialsActions';
import * as applicationActions from '../actions/applicationActions';
import * as storageKeys from '../actions/storageKeys';
import RegistrationCredentialsForm from '../components/RegistrationCredentials/RegistrationCredentialsForm';
import RegistrationCredentialsFormValidation from '../validations/RegistrationCredentialsFormValidation';
import * as RouteNavigator from './RouteNavigator';
import { getCookie } from '../lib/cookieManager';
import { findContactByType, isContactDataMissingOrInvalid, contactMissingFields } from './contactSearchAndFormat';
import * as contactActions from '../actions/contactActions';
import * as registrationActions from '../actions/registrationActions';
import * as hearAboutUsHelper from '../lib/containerHelpers/registration/hearAboutUsHelper';
import optimizeHelper from '../lib/optimizeHelper';
import Config from '../Config';
import { getPromoCode, getOfferCodes } from '../lib/promoCode';
import { mapRegionOptions, filterAndSortAllowedCountriesAndRegions } from '../lib/sortFilterMapCountriesRegions';
import validatePhone from '../validations/PhoneValidation';
import auth0Login from '../lib/auth0Login';
import Arkose from '../components/Arkose';
import { shouldCheckUsernameAvailability } from '../lib/containerHelpers/registration/usernameHelper';
import { shouldCheckAccountAvailability } from '../lib/containerHelpers/registration/accountAvailabilityHelper';

const config = new Config();

const accountAlreadyExists = 'Account already exists';
const emailIsInvalid = 'The `contact` instance is not valid. Details: `email` is invalid';
const promoCodeError = 'promoCode';
const contactAlreadyExistsMessage = 'An account with this personal information already exists. Please login to continue.';
const emailIsInvalidMessage = 'An account with this email address already exists. Please log in to continue.';
const promoCodeErrorMessage = 'Not a valid offer code.';
let isAccountAvailable = true;

const accountAlreadyExistsError = {
  response: {
    status: 422,
    json: () => Promise.resolve({
      error: {
        message: 'Account already exists',
      },
    }),
  },
};

const isFailedLogin = () => {
  const login = RouteNavigator.getURLParameterByName(storageKeys.LOGIN);
  if (!login) {
    return false;
  }
  return true;
};

const isAuthRedirect = () => {
  const authCode = RouteNavigator.getURLParameterByName('code');
  if (!authCode) {
    return false;
  }
  return true;
};

export const asyncValidate = (values, dispatch, props) => {
  const { email, firstName, lastName } = values;
  const userInfo = { email, firstName, lastName };

  // Checking missingContactData if true this means a logged in user and we should not async validate
  if (props && props.missingContactData) {
    return Promise.resolve(true);
  }

  if (config.duplicateRemediationEnabled && shouldCheckAccountAvailability(email, firstName, lastName, props)) {
    dispatch(securityCredentialsActions.checkAccountAvailability(userInfo)).then((result) => {
      isAccountAvailable = result;
    });
  }

  const checkUsername = shouldCheckUsernameAvailability(values.username, props);
  if (checkUsername) {
    return dispatch(securityCredentialsActions.checkUsernameAvailability(props.applicationId, values.username));
  } return Promise.resolve(true);
};

export const mapSalesRepsToOptions = (salesReps) => {
  let options = [{ value: '', text: '' }];

  if (salesReps) {
    options = options.concat(salesReps
      .map((salesRep) => { return { value: salesRep.key, text: salesRep.title }; }),
    );

    options.sort((a, b) => {
      return (a.text > b.text) || (a.text === b.text) - 1;
    });
  }

  return options;
};

export const mapCountryOptions = (countries) => {
  let options = [{ value: '', text: '' }];

  if (countries) {
    options = options.concat(countries
      .map((country) => { return { value: country.countryCode, text: country.country }; }),
    );
  }

  return options;
};

export function validatePromoCode(promoCode) {
  return promoCode && (/[0-9a-zA-Z]{8}/g).test(promoCode);
}

export function getSalesRepId(salesRepList, salesRepId) {
  if (!salesRepList || salesRepList.length === 0) {
    return null;
  }

  const id = salesRepId.toLowerCase();
  return salesRepList.map(s => s.key)
    .filter(sr => sr.toLowerCase() === id)
    .slice(0, 1)
    .pop();
}

export function getSalesRepFromURL(salesRepList) {
  if (!salesRepList || salesRepList.length === 0) {
    return null;
  }

  const salesRepParam = RouteNavigator.getURLParameterByName(storageKeys.SALES_REP_PARAM_KEY);
  if (!salesRepParam) {
    return null;
  }
  // Makes sure the salesRepParam is valid in our salesRepList
  const salesRepId = getSalesRepId(salesRepList, salesRepParam);
  return salesRepId || null;
}

export function mapValuesToContact(values, salesRepList, recaptchaToken) {
  const urlPromoCode = RouteNavigator.getURLParameterByName(storageKeys.PROMO_CODE_PARAM_KEY);
  const promoCodeCookie = getCookie(storageKeys.PROMO_CODE_COOKIE_NAME);
  const utmCode = getCookie(storageKeys.UTM_OFFER_COOKIE_NAME);
  const promoCode = getPromoCode(values.promoCode, urlPromoCode, promoCodeCookie, utmCode);
  const offerCodes = getOfferCodes(values.promoCode, urlPromoCode, promoCodeCookie, utmCode);

  return {
    type: 'primary',
    firstName: values.firstName ? values.firstName.trim() : '',
    lastName: values.lastName ? values.lastName.trim() : '',
    middleInitial: values.middleInitial,
    email: values.email,
    phone: values.phone,
    countryOfResidence: values.countryOfResidence,
    regionOfResidence: values.regionOfResidence,
    hearAboutUs: values.hearAboutUs,
    hearAboutUsOther: hearAboutUsHelper.isOtherRequired(values.hearAboutUs)
      ? values.hearAboutUsOther
      : null,
    hearAboutUsSalesRep: hearAboutUsHelper.isSalesRepRequired(values.hearAboutUs)
      ? values.hearAboutUsSalesRep
      : getSalesRepFromURL(salesRepList),
    promoCode,
    cryptoEnable: true,
    authorization: recaptchaToken,
    offerCodes,
  };
}

export const userRedirectLocation = (applicationId, authToken) => {
  let location = null;

  if (applicationId) {
    location = '/account-type';
  } else if (authToken) {
    location = '/pending-applications';
  }

  return location;
};

function mapValuesToCredentials(values) {
  return {
    username: values.username,
    password: values.password,
    cryptoEnable: true,
    credentialsValidationVersion: config.credentialsValidationVersion,
  };
}

function onArkoseError(callbackError) {
  console.log('Error:', callbackError);
  console.log('Error:', callbackError.error);
}

export class RegistrationCredentialsFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showLoading: isAuthRedirect(),
      showExistingAccountModal: false,
      showLoggedAccountErrorModal: isFailedLogin(),
      showSupportedSymbolsModal: false,
      passwordVisible: false,
      confirmPasswordVisible: false,
      recaptchaToken: null,
      phoneCountry: 'us',
      phoneStatus: null,
      showPasswordIcons: false,
      arkoseSolved: false,
      token: null,
      formValues: null,
      correlationId: null,
    };

    this.arkoseRef = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleLoggedAccountErrorModalToggleOff = this.handleLoggedAccountErrorModalToggleOff.bind(this);
    this.handleSupportedSymbolsModalToggleOn = this.handleSupportedSymbolsModalToggleOn.bind(this);
    this.handleSupportedSymbolsModalToggleOff = this.handleSupportedSymbolsModalToggleOff.bind(this);
    this.handleShowHidePassword = this.handleShowHidePassword.bind(this);
    this.handleShowHideConfirmPassword = this.handleShowHideConfirmPassword.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.checkNonReduxFormFields = this.checkNonReduxFormFields.bind(this);
    this.handleShowHidePasswordIcons = this.handleShowHidePasswordIcons.bind(this);

    this.onArkoseSubmit = this.onArkoseSubmit.bind(this);
    this.onArkoseCompleted = this.onArkoseCompleted.bind(this);
  }

  componentDidMount() {
    if (this.props.countries.length === 0) {
      this.props.actions.fetchCountries();
    }

    if (this.props.salesReps.length === 0) {
      this.props.actions.fetchSalesReps();
    }

    if (this.props.hearAboutUsOptions.length === 0) {
      this.props.actions.fetchHearAboutUs();
    }

    const missingContactData = RouteNavigator.getURLParameterByName('status') === 'missinginfo';
    const redirectLocation = userRedirectLocation(this.props.applicationId, this.props.authToken);
    const shouldRedirect = RouteNavigator.getURLParameterByName('redirectTo') !== null;

    const authorizationError = RouteNavigator.getURLParameterByName('error');
    if (authorizationError) {
      this.props.dispatch(stopSubmit('registration', { _error: 'Failed to register' }));
    }

    const authorizationCode = RouteNavigator.getURLParameterByName('code');
    if (authorizationCode) {
      const applicationId = this.props.applicationId;
      const authToken = this.props.authToken;
      return (
        this.props.actions
          .updateRegistrationCredentials(
            applicationId,
            authToken,
            authorizationCode,
          )
          .then(() => {
            RouteNavigator.push('/account-type');
          })
          .catch(() => {
            this.setState({ showLoading: false });
            this.props.dispatch(stopSubmit('registration', { _error: 'Failed to register' }));
          })
      );
    }

    if (shouldRedirect) {
      return 0;
    }

    if (redirectLocation && !missingContactData && !authorizationCode) {
      RouteNavigator.push(redirectLocation);
    }

    if (missingContactData) {
      if (!this.props.contacts || !this.props.contacts.length) {
        this.props.actions.fetchContact(this.props.applicationId, this.props.authToken);
      }
      if (!this.props.registration) {
        this.props.actions.fetchRegistration(this.props.applicationId, this.props.authToken)
        .then(() => {
          this.handleCountryChange();
        });
      }
    }

    optimizeHelper.notify();
    return 0;
  }

  onArkoseSubmit() {
    if (!this.state.token && this.props.valid) {
      this.arkoseRef.current.myEnforcement.run();
    }
  }

  onArkoseCompleted(callbackToken) {
    if (this.props.valid) {
      this.setState({ token: callbackToken });

      const email = this.state.formValues.email;
      const payload = {
        token: this.state.token,
      };
      this.props.actions.arkoseTokenValidation(email, payload).then((response) => {
        const json = response && response.json ? JSON.parse(response.json) : null;
        const correlationId = json && json.correlationId ? json.correlationId : null;
        if (json && json.session_details.solved) {
          this.setState({ arkoseSolved: true });
          this.setState({ correlationId });
          this.handleSubmit(this.state.formValues);
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.dispatch(stopSubmit('registration', { _error: 'Failed to register' }));
      });
    }
  }

  handleError(error) {
    if (error.response && error.response.status === 422) {
      return error.response.json()
        .then((json) => {
          let errorMessage = json.error.message;
          if (json.error.message.indexOf(accountAlreadyExists) > -1) {
            errorMessage = contactAlreadyExistsMessage;
          }

          if (json.error.message.indexOf(emailIsInvalid) > -1) {
            errorMessage = emailIsInvalidMessage;
          }

          if (json.error.message.indexOf(promoCodeError) > -1) {
            errorMessage = promoCodeErrorMessage;
          }

          if (json.error.message.indexOf('Account already exists') > -1) {
            errorMessage = 'An account with this name already exists. Please login to continue';
            this.setState({ showExistingAccountModal: true });
          }

          const msg = 'The `registration` instance is not valid. Details: `username` Username already taken';
          if (json.error.message.indexOf(msg) > -1) {
            errorMessage = 'This Username is already taken. Please select a different Username.';
          }

          errorMessage = errorMessage.includes('Unprocessable') ? 'Unprocessable Entity' : errorMessage;
          throw new SubmissionError({ _error: errorMessage });
        });
    }
    throw new SubmissionError({ _error: error.message });
  }

  handleSubmit(values) {
    if (config.arkoseEnabled) {
      if (this.state.arkoseSolved) {
        this.handleSubmitForm(values);
      } else {
        this.setState({ formValues: values });
      }
    } else {
      this.handleSubmitForm(values);
    }
    return 0;
  }

  handleSubmitForm(values) {
    if (isAccountAvailable) {
      if (this.state.phoneStatus === 'true') {
        const contact = mapValuesToContact(values, this.props.salesReps);
        const credentials = mapValuesToCredentials(values);
        credentials.correlationId = this.state.correlationId;

        if (this.props.missingContactData) {
          contact.id = values.contactId;
          return this.props.actions.updateContact(contact, this.props.applicationId, this.props.authToken)
            .then(() => {
              RouteNavigator.push('/account-type');
            })
            .catch((err) => {
              this.setState({ showLoading: false });
              this.handleError(err);
            });
          // eslint-disable-next-line no-else-return
        } else {
          const auth0Enabled = config.auth0Enabled;
          if (!auth0Enabled) {
            this.setState({ showExistingAccountModal: false });
            return this.props.actions.submitRegistrationCredentials({ contact, credentials })
              .then(() => {
                RouteNavigator.push('/account-type');
              })
              .catch(this.handleError);
          }
          if (auth0Enabled) {
            this.setState({ showExistingAccountModal: false });
            const { username, password } = credentials;
            // credentials.password = 'registration_Default0';
            return this.props.actions.submitRegistrationCredentials({ contact, credentials })
              .then(() => {
                this.setState({ showLoading: true });
                return auth0Login(contact, { username, password }).then(() => {
                })
                .catch((err) => {
                  this.setState({ showLoading: false });
                  this.props.dispatch(stopSubmit('registration', { _error: 'Failed to register' }));
                  return this.handleError(err);
                });
              })
              .catch((err) => {
                this.setState({ showLoading: false });
                this.props.dispatch(stopSubmit('registration', { _error: 'Failed to register' }));
                return this.handleError(err);
              });
          }
        }
      }
    } else {
      return this.handleError(accountAlreadyExistsError);
    }
    return 0;
  }

  handleLoggedAccountErrorModalToggleOff() {
    this.setState({ showLoggedAccountErrorModal: false });
  }

  handleSupportedSymbolsModalToggleOn() {
    this.setState({ showSupportedSymbolsModal: true });
  }

  handleSupportedSymbolsModalToggleOff() {
    this.setState({ showSupportedSymbolsModal: false });
  }

  handleShowHidePassword() {
    const { passwordVisible } = this.state;
    this.setState({ passwordVisible: !passwordVisible });
  }

  handleShowHideConfirmPassword() {
    const { confirmPasswordVisible } = this.state;
    this.setState({ confirmPasswordVisible: !confirmPasswordVisible });
  }

  handleShowHidePasswordIcons(isFocused, input) {
    this.setState({ showPasswordIcons: isFocused || input.length > 0 });
  }

  // eslint-disable-next-line
  handleBack() {
    RouteNavigator.push('/intro');
  }

  handleCountryChange() {
    if (!this.props.missingContactData) {
      this.props.dispatch(change('registration', 'regionOfResidence', ''));
    }
    setTimeout(() => {
      let country = this.props.countryOfResidence;
      const missingCountriesUK = ['SW', 'IM', 'GG'];

      if (missingCountriesUK.includes(country)) {
        country = 'gb';
      } else if (country === 'EH') {
        country = 'ma';
      }
      this.setState({ phoneCountry: country ? country.toLowerCase() : country });
    }, 0);
    if (this.state.phoneStatus === 'true') this.handlePhoneChange();
  }

  handlePhoneChange(phone = null) {
    this.props.dispatch(change('registration', 'phone', phone));
    if (!phone || phone.length <= 1) {
      this.setState({ phoneStatus: 'empty' });
    } else if (validatePhone(phone, this.props.countryOfResidence)) {
      this.setState({ phoneStatus: 'false' });
    } else {
      this.setState({ phoneStatus: 'true' });
    }
  }

  checkNonReduxFormFields() {
    if (this.state.phoneStatus === null) {
      this.setState({ phoneStatus: 'empty' });
    }
  }

  render() {
    const {
      countries,
      salesReps,
      hearAboutUsOptions,
      countryOfResidence,
    } = this.props;
    return (
      <>
        <RegistrationCredentialsForm
          {...this.props}
          countries={mapCountryOptions(countries)}
          salesReps={mapSalesRepsToOptions(salesReps)}
          hearAboutUsOptions={hearAboutUsHelper.mapHearAboutUsOptions(hearAboutUsOptions)}
          onSubmit={this.handleSubmit}
          onBack={this.handleBack}
          handleLoggedAccountErrorModalToggleOff={this.handleLoggedAccountErrorModalToggleOff}
          handleShowHidePassword={this.handleShowHidePassword}
          handleShowHideConfirmPassword={this.handleShowHideConfirmPassword}
          regions={mapRegionOptions(countries, countryOfResidence, true)}
          showExistingAccountModal={this.state.showExistingAccountModal}
          showLoading={this.state.showLoading}
          showLoggedAccountErrorModal={this.state.showLoggedAccountErrorModal}
          passwordVisible={this.state.passwordVisible}
          confirmPasswordVisible={this.state.confirmPasswordVisible}
          handleCountryChange={this.handleCountryChange}
          handlePhoneChange={this.handlePhoneChange}
          checkNonReduxFormFields={this.checkNonReduxFormFields}
          phoneCountry={this.state.phoneCountry}
          phoneStatus={this.state.phoneStatus}
          showPasswordIcons={this.state.showPasswordIcons}
          handleShowHidePasswordIcons={this.handleShowHidePasswordIcons}
          handleSupportedSymbolsModalToggleOn={this.handleSupportedSymbolsModalToggleOn}
          handleSupportedSymbolsModalToggleOff={this.handleSupportedSymbolsModalToggleOff}
          showSupportedSymbolsModal={this.state.showSupportedSymbolsModal}
          valid={this.props.valid}
        />
        { config.arkoseEnabled &&
          <Arkose
            selector="#registration_next_btn"
            publicKey={config.arkosePublicKey}
            onCompleted={this.onArkoseCompleted}
            // onShow={this.onArkoseShow}
            onError={onArkoseError}
            ref={this.arkoseRef}
          />
        }
      </>
    );
  }
}

RegistrationCredentialsFormContainer.propTypes = {
  applicationId: PropTypes.string,
  authToken: PropTypes.string,
  userId: PropTypes.number,
  actions: PropTypes.shape({
    fetchCountries: PropTypes.func.isRequired,
    fetchSalesReps: PropTypes.func.isRequired,
    fetchHearAboutUs: PropTypes.func.isRequired,
    submitRegistrationCredentials: PropTypes.func.isRequired,
    updateRegistrationCredentials: PropTypes.func.isRequired,
    arkoseTokenValidation: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    updateContact: PropTypes.func.isRequired,
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
  })),
  salesReps: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  hearAboutUsOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  countryOfResidence: PropTypes.string,
  contacts: PropTypes.arrayOf(PropTypes.shape()),
  registration: PropTypes.shape(),
  missingContactData: PropTypes.bool,
  valid: PropTypes.bool,
  dispatch: PropTypes.func,
  change: PropTypes.func,
};

export function mapStateToInitialValues(state) {
  const registration = state.registration || {};
  const promoCode = getPromoCode(
    null,
    RouteNavigator.getURLParameterByName(storageKeys.PROMO_CODE_PARAM_KEY),
    getCookie(storageKeys.PROMO_CODE_COOKIE_NAME),
    getCookie(storageKeys.UTM_OFFER_COOKIE_NAME),
  );
  const salesRep = getSalesRepFromURL(state.salesReps);

  if (validatePromoCode(promoCode)) {
    registration.promoCode = promoCode;
  }

  if (salesRep) {
    registration.hearAboutUs = hearAboutUsHelper.HEAR_ABOUT_US_SALES_REP;
    registration.hearAboutUsSalesRep = salesRep;
  }

  if (state.contact) {
    registration.contactId = findContactByType('primary', state.contact).id;
  }

  return registration;
}

function mapStateToProps(state) {
  const selector = formValueSelector('registration');
  const countryOfResidence = selector(state, 'countryOfResidence');
  const hearAboutUs = selector(state, 'hearAboutUs');
  const missingContactData = RouteNavigator.getURLParameterByName('status') === 'missinginfo';
  const urlSalesRep = getSalesRepFromURL(state.salesReps);

  let allowedCountries = state.countries;
  let contactMissingFieldList = [];

  if (state.countries && state.countries.length > 0) {
    allowedCountries = filterAndSortAllowedCountriesAndRegions(state.countries);
  }

  if (state.contact && state.contact.length > 0) {
    const primaryContact = findContactByType('primary', state.contact);
    const countryData = state.countries.find((c) => { return c.countryCode === primaryContact.countryOfResidence; });
    const missingData = isContactDataMissingOrInvalid(primaryContact, countryData);
    contactMissingFieldList = contactMissingFields(primaryContact);
    if (!missingData) RouteNavigator.push('/account-type');
  }

  return {
    initialValues: mapStateToInitialValues(state),
    countries: allowedCountries,
    salesReps: state.salesReps,
    countryOfResidence,
    hearAboutUsOptions: state.hearAboutUs,
    hearAboutUsOtherSelected: hearAboutUsHelper.isOtherRequired(hearAboutUs),
    hearAboutUsSalesRepSelected: hearAboutUsHelper.isSalesRepRequired(hearAboutUs),
    applicationId: state.applicationId,
    authToken: state.authToken,
    isSubmitting: state.isSubmitting,
    userId: state.userId,
    missingContactData,
    contactMissingFieldList,
    urlSalesRep,
    credentialsValidationVersion: config.credentialsValidationVersion,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      countriesActions,
      salesRepsActions,
      hearAboutUsActions,
      securityCredentialsActions,
      registrationCredentialsActions,
      applicationActions,
      contactActions,
      registrationActions,
      change,
      { stopSubmit },
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'registration',
  validate: RegistrationCredentialsFormValidation,
  asyncValidate,
  asyncBlurFields: ['username', 'email', 'firstName', 'lastName'],
})(RegistrationCredentialsFormContainer));
