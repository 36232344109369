import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as summaryValueMapper from './summaryValueMapper';
import SummarySection from '../../components/SummaryV1/SummarySection';
import * as employmentActions from '../../actions/employmentActions';
import * as contactActions from '../../actions/contactActions';
import * as EmploymentFormContainer from '../../containers/EmploymentFormContainer';
import * as summaryRowTypes from '../../components/SummaryV1/summaryRowTypes';
import * as employmentQuestions from '../../components/Employment/employmentQuestions';
import employmentTexts from '../../components/Employment/employmentTexts';
import * as employmentTypes from '../../components/Employment/employmentTypes';
import * as industriesActions from '../../actions/industriesActions';
import * as contactSearchAndFormat from '../../containers/contactSearchAndFormat';

export const mapJobTitleOptions = (industries, selectedIndustry) => {
  let options = [{ value: '', text: '' }];

  if (industries) {
    const industry = industries.find(c => c.industryCode === selectedIndustry);

    if (industry && industry.jobs) {
      options = options.concat(industry.jobs
        .map((job) => {
          return {
            value: job.jobCode,
            text: job.job,
          };
        }),
      );
    }
  }

  return options;
};

export const mapIndustryOptions = (industries) => {
  let options = [{ value: '', text: '' }];

  if (industries) {
    options = options.concat(industries
      .map((industry) => {
        return {
          value: industry.industryCode,
          text: industry.industry,
        };
      }),
    );
  }

  return options;
};

export class SummaryEmploymentContainer extends React.Component {
  componentDidMount() {
    if (!this.props.employment) {
      this.props.actions.fetchEmployment(this.props.applicationId, this.props.authToken);
    }

    if (!this.props.contact) {
      this.props.actions.fetchContact(this.props.applicationId, this.props.authToken);
    }

    if (this.props.industries.length === 0) {
      this.props.actions.fetchIndustries();
    }
  }

  render() {
    return (
      <SummarySection {...this.props} />
    );
  }
}

SummaryEmploymentContainer.propTypes = {
  actions: PropTypes.shape({
    fetchEmployment: PropTypes.func,
    fetchContact: PropTypes.func,
    fetchIndustries: PropTypes.func,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  employment: PropTypes.shape(),
  contact: PropTypes.shape(),
  industries: PropTypes.arrayOf(PropTypes.shape({
    industry: PropTypes.string.isRequired,
    industryCode: PropTypes.string.isRequired,
  })),
};

const getEmploymentStatus = (employmentStatusOptions, employmentStatusCode) => {
  return summaryValueMapper.getTextFromValue(employmentStatusOptions, employmentStatusCode);
};

const getEmploymentIndustry = (employmentIndustries, employmentIndustryCode) => {
  return summaryValueMapper.getTextFromValue(employmentIndustries, employmentIndustryCode);
};

const getEmploymentTitle = (employmentTitles, employmentTitleCode) => {
  return summaryValueMapper.getTextFromValue(employmentTitles, employmentTitleCode);
};

const getEmploymentQuestion = (employmentQuestionTexts, employmentQuestionCode) => {
  return summaryValueMapper.getTextFromValue(employmentQuestionTexts, employmentQuestionCode);
};

function mapStateToInitialValues(state, groupIndex) {
  let isLoading = true;
  const contacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;
  const employments = (state.employment && state.employment.length > 0) ? state.employment : null;
  const isExistingCustomer = state.registration ? state.registration.isExistingCustomer : false;

  if (state.employment) {
    isLoading = false;
  }

  let employment = null;

  const contactType = (groupIndex === 0) ? 'primary' : 'joint';
  const contact = contacts ? contactSearchAndFormat.findContactByType(contactType, contacts) : null;
  if (contact) {
    employment = employments ? contactSearchAndFormat.findEntityByContactId(contact.id, employments) : null;
  }

  const employmentStatusOptions = EmploymentFormContainer.employmentStatusOptions;
  const ntseText = getEmploymentQuestion(employmentTexts, employmentQuestions.EMPLOYMENT_QUESTION_NYSE);
  const directorText = getEmploymentQuestion(employmentTexts, employmentQuestions.EMPLOYMENT_QUESTION_DIRECTOR);

  const marketDataText =
    getEmploymentQuestion(employmentTexts, employmentQuestions.EMPLOYMENT_QUESTION_MARKET_DATA);
  const registeredSecuritiesText =
    getEmploymentQuestion(employmentTexts, employmentQuestions.EMPLOYMENT_QUESTION_REGISTERED_SECURITIES);
  const investmentAdvisorText =
    getEmploymentQuestion(employmentTexts, employmentQuestions.EMPLOYMENT_QUESTION_INVESTMENT_ADVISOR);

  let employmentInformation = [
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'EMPLOYMENT STATUS',
      value: employment ? getEmploymentStatus(employmentStatusOptions, employment.employmentStatus) : '',
    },
  ];

  if (employment && (employment.employmentStatus === employmentTypes.EMPLOYMENT_STATUS_EMPLOYED ||
    employment.employmentStatus === employmentTypes.EMPLOYMENT_STATUS_SELF_EMPLOYED)) {
    employmentInformation = employmentInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'INDUSTRY',
        value: employment ? getEmploymentIndustry(mapIndustryOptions(state.industries), employment.industry) : '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'JOB TITLE',
        value: employment ? getEmploymentTitle(
          mapJobTitleOptions(state.industries, employment.industry), employment.title) : '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'COMPANY NAME',
        value: employment ? employment.employmentName : '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'COUNTRY',
        value: employment ? summaryValueMapper.getCountryName(state.countries, employment.employmentCountry) : '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'ADDRESS 1',
        value: employment ? employment.employmentAddress1 : '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'ADDRESS 2',
        value: employment ? employment.employmentAddress2 : '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'CITY',
        value: employment ? employment.employmentCity : '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'STATE OR PROVINCE',
        value: employment ?
          summaryValueMapper.getRegionName(
            state.countries, employment.employmentCountry, employment.employmentRegion,
          ) :
          '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'ZIP CODE',
        value: employment ? employment.employmentZip : '',
      },
    ]);
  }

  employmentInformation = employmentInformation.concat([
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: ntseText,
      value: employment && employment.isBrokerage ? 'Yes' : 'No',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: directorText,
      value: employment && employment.isPublic ? 'Yes' : 'No',
    },
  ]);

  const isBrokerageSlice = 1;
  if (employment && employment.isBrokerage) {
    employmentInformation.splice(
      employmentInformation.length - isBrokerageSlice, 0,
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'COMPANY NAME',
        value: employment ? employment.brokerageCompanyName : '',
      },
    );
  }

  const isPublicSlice = 0;
  if (employment && employment.isPublic) {
    employmentInformation.splice(
      employmentInformation.length - isPublicSlice, 0,
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'PROFESSIONAL TRADING SYMBOL',
        value: employment ? employment.publicSymbol : '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'PROFESSIONAL COMPANY',
        value: employment ? employment.publicCompanyName : '',
      },
    );
  }

  if (!isExistingCustomer) {
    employmentInformation = employmentInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: marketDataText,
        value: employment && employment.isMarketDataForBusiness ? 'Yes' : 'No',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: registeredSecuritiesText,
        value: employment && employment.isRegisteredWithSecurities ? 'Yes' : 'No',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: investmentAdvisorText,
        value: employment && employment.isInvestmentAdvisor ? 'Yes' : 'No',
      },
    ]);

    if (employment && employment.isRegisteredWithSecurities) {
      employmentInformation.splice(
        employmentInformation.length - 1, 0,
        {
          keyType: summaryRowTypes.SUMMARY_ROW_TXT,
          valueType: summaryRowTypes.SUMMARY_ROW_TXT,
          key: 'CRD NUMBER',
          value: employment ? employment.crdNumber : '',
        },
      );
    }
  }

  return {
    isLoading,
    key: 'employment',
    value: employmentInformation,
    title: 'EMPLOYMENT',
    editLink: '/employment',
  };
}

function mapStateToProps(state, ownProps) {
  const groupIndex = ownProps.groupIndex;
  const initialValues = mapStateToInitialValues(state, groupIndex);
  const errorMessage = '';

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    isLoading: initialValues.isLoading,
    entity: initialValues.key,
    summaryInfo: initialValues.value,
    title: initialValues.title,
    editLink: initialValues.editLink,
    industries: state.industries,
    errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, employmentActions, industriesActions, contactActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryEmploymentContainer);
