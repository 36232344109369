import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, reduxForm, SubmissionError } from 'redux-form';
import FinancialForm from '../components/Financial/FinancialForm';
import FinancialFormValidation, { validateMoney } from '../validations/FinancialFormValidation';
import * as financialActions from '../actions/financialActions';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as assetClassActions from '../actions/assetClassActions';
import * as identityActions from '../actions/identityActions';
import * as addressActions from '../actions/addressActions';
import * as accountTypes from '../components/AccountType/accountTypes';
import * as assetTypes from '../components/AssetClass/assetTypes';
import * as employmentTypes from '../components/Employment/employmentTypes';
import * as contactActions from '../actions/contactActions';
import * as employmentActions from '../actions/employmentActions';
import * as RouteNavigator from './RouteNavigator';
import * as contactSearchAndFormat from './contactSearchAndFormat';
import * as tradingProfileTypes from '../components/TradingProfile/tradingProfileTypes';
import * as tradingProfileActions from '../actions/tradingProfileActions';
import * as registrationActions from '../actions/registrationActions';
import * as countriesActions from '../actions/countriesActions';
import optimizeHelper from '../lib/optimizeHelper';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';
import { setCookie } from '../lib/cookieManager';
import Config from '../Config';

const config = new Config();

const conciergeCookieName = 'isConcierge';
const cookieExpirationDays = 30;

export const annualIncomeOptions = [
  { value: '', text: '' },
  { value: '1', text: 'Under $50,000' },
  { value: '2', text: '$50,000 – $99,999' },
  { value: '3', text: '$100,000 – $249,999' },
  { value: '4', text: '$250,000 – $999,999' },
  { value: '5', text: 'Over $1,000,000' },
];

export const sourceOfAssetsForTradingOptions = [
  { value: '', text: '' },
  { value: '1', text: 'Salary' },
  { value: '2', text: 'Gifts' },
  { value: '3', text: 'Investments' },
  { value: '4', text: 'Inheritance' },
  { value: '5', text: 'Legal Settlements' },
  { value: '6', text: 'Savings' },
  { value: '8', text: 'Retirement/Pension/401K/IRA' },

];

export const netWorthOptions = [
  { value: '', text: '' },
  { value: '1', text: 'Under $75,000' },
  { value: '2', text: '$75,000 – $99,999' },
  { value: '3', text: '$100,000 – $199,999' },
  { value: '4', text: '$200,000 – $499,999' },
  { value: '5', text: '$500,000 – $999,999' },
  { value: '6', text: '$1,000,000 – $4,999,999' },
  { value: '7', text: '$5,000,000 - $49,999,999' },
  { value: '8', text: '$50 million or more' },
];

export const sourceOfIncomeOptionsInternal = [
  { value: '', text: '' },
  { value: '4', text: 'Salary' },
  { value: '1', text: 'Investments' },
  { value: '2', text: 'Inheritance' },
  { value: '5', text: 'Retirement/Pension/401K/IRA' },
];

const defaultTradingProfile = () => {
  return {
    id: null,
    tellUsAboutYourself: '',
    yearsExperienceStocks: tradingProfileTypes.NONE,
    monthsExperienceStocks: '',
    yearsExperienceOptions: tradingProfileTypes.NONE,
    monthsExperienceOptions: '',
    yearsExperienceFutures: tradingProfileTypes.NONE,
    monthsExperienceFutures: '',
    isTSEL: false,
  };
};

function formatMoney(value) {
  const money = validateMoney(value);
  if (!isNaN(money)) {
    return money.toFixed(2);
  }
  return '';
}

function mapFinancial(financial, contact) {
  return Object.assign({}, financial, contact ? {
    sort: contact.type === 'primary' ? 0 : contact.id,
    contact: contactSearchAndFormat.mapContact(contact),
  } : {});
}

export function mapValuesToFinancial(financial) {
  const mapEntity = mapFinancial(financial);

  mapEntity.totalNetWorthSpecified = financial.totalNetWorth === '1' ?
    formatMoney(financial.totalNetWorthSpecified) :
    null;
  mapEntity.liquidNetWorthSpecified = financial.liquidNetWorth === '1' ?
    formatMoney(financial.liquidNetWorthSpecified) :
    null;
  mapEntity.annualIncomeSpecified = financial.annualIncome === '1' ?
    formatMoney(financial.annualIncomeSpecified) :
    null;

  return mapEntity;
}

export const mapValuesToFinancials = (values) => {
  const financials = [];
  values.financials.forEach((i) => {
    financials.push(mapValuesToFinancial(i));
  });

  return financials;
};

const mapValuesToTradingProfile = (values) => {
  const tradingProfile = defaultTradingProfile();
  tradingProfile.id = values.id || null;
  tradingProfile.contactId = values.contact.id || null;
  tradingProfile.isTSEL = values.isTSEL;
  tradingProfile.tellUsAboutYourself = values.tellUsAboutYourself;
  tradingProfile.yearsExperienceFutures = values.yearsExperienceFutures;
  tradingProfile.yearsExperienceOptions = values.yearsExperienceOptions;
  tradingProfile.yearsExperienceStocks = values.yearsExperienceStocks;

  if (tradingProfile.yearsExperienceFutures === tradingProfileTypes.UNDER_ONE) {
    tradingProfile.monthsExperienceFutures = values.monthsExperienceFutures;
  }
  if (tradingProfile.yearsExperienceOptions === tradingProfileTypes.UNDER_ONE) {
    tradingProfile.monthsExperienceOptions = values.monthsExperienceOptions;
  }
  if (tradingProfile.yearsExperienceStocks === tradingProfileTypes.UNDER_ONE) {
    tradingProfile.monthsExperienceStocks = values.monthsExperienceStocks;
  }

  return tradingProfile;
};

export const mapValuesToTradingProfiles = (values) => {
  const tradingProfiles = [];
  values.tradingProfiles.forEach((i) => {
    tradingProfiles.push(mapValuesToTradingProfile(i));
  });

  return tradingProfiles;
};

// set concierge cookie to true if liquid net worth is >= $1 million for primary or joint applicant
function setCookieForConcierge(financials) {
  if (financials && financials.length > 0) {
    let cookieValue = 'false';
    financials.forEach((financial) => {
      const liquidNetWorth = financial.liquidNetWorth ? Number(financial.liquidNetWorth) : 1;
      if (liquidNetWorth >= 6) {
        cookieValue = 'true';
      }
    });
    setCookie(conciergeCookieName, cookieValue, cookieExpirationDays, true);
  }
}

export class FinancialFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExitModal: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleExitModelOn = this.handleExitModelOn.bind(this);
    this.handleExitModelOff = this.handleExitModelOff.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      applicationId,
      authToken,
      countries,
      employment,
      tradingProfile,
      contact,
      financial,
      registration,
      accountType,
      assetClass,
      identity,
      address,
    } = this.props;

    if (!countries || countries.length === 0) actions.fetchCountries();
    if (!financial) actions.fetchFinancial(applicationId, authToken);
    if (!contact) actions.fetchContact(applicationId, authToken);
    if (!employment) actions.fetchEmployment(applicationId, authToken);
    if (!tradingProfile) actions.fetchTradingProfile(applicationId, authToken);
    if (!registration || !accountType || !assetClass || !identity || !address) {
      Promise.all([
        actions.fetchRegistration(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
        actions.fetchIdentity(applicationId, authToken),
        actions.fetchAddress(applicationId, authToken),
      ]).then(() => {
        pushToAnalytics(
          aopAnalyticsSteps.FINANCIAL.name,
          { applicationId, authToken, registration, accountType, assetClass, address, identity },
        );
      });
    } else {
      pushToAnalytics(
        aopAnalyticsSteps.FINANCIAL.name,
        { applicationId, authToken, registration, accountType, assetClass, address, identity },
      );
    }

    optimizeHelper.notify();
  }

  handleNext() {
    if (config.optionsEnhancementsEnabled && this.props.isEquitiesApplication) {
      RouteNavigator.push('/equities-account-settings');
    } else if (this.props.isIraApplication && this.props.isEquitiesApplication) {
      RouteNavigator.push('/ira-account');
    } else {
      RouteNavigator.push('/additional-info');
    }
  }


  handleSubmit(values) {
    const financials = mapValuesToFinancials(values);
    const tradingProfiles = mapValuesToTradingProfiles(values);
    setCookieForConcierge(financials);

    return this.props.actions.upsertFinancial(financials, this.props.applicationId, this.props.authToken)
      .then(() => {
        this.props.actions.upsertTradingProfile(tradingProfiles, this.props.applicationId, this.props.authToken)
          .then(() => {
            this.handleNext();
          });
      })
      .catch((error) => { throw new SubmissionError({ _error: error.message }); });
  }

  // eslint-disable-next-line
  handleBack() {
    RouteNavigator.push('/employment');
  }

  handleExitModelOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModelOff() {
    this.setState({ showExitModal: false });
  }

  render() {
    return (
      <FinancialForm
        {...this.props}
        annualIncomeOptions={annualIncomeOptions}
        netWorthOptions={netWorthOptions}
        sourceOfAssetsForTradingOptions={sourceOfAssetsForTradingOptions}
        onSubmit={this.handleSubmit}
        onBack={this.handleBack}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModelOn}
        onExitModalOff={this.handleExitModelOff}
      />
    );
  }
}

FinancialFormContainer.propTypes = {
  financial: PropTypes.shape(),
  accountType: PropTypes.shape(),
  contact: PropTypes.shape(),
  employment: PropTypes.shape(),
  tradingProfile: PropTypes.arrayOf(PropTypes.shape()),
  registration: PropTypes.shape(),
  actions: PropTypes.shape({
    fetchFinancial: PropTypes.func.isRequired,
    upsertFinancial: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    fetchEmployment: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    fetchTradingProfile: PropTypes.func.isRequired,
    upsertTradingProfile: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  isIraApplication: PropTypes.bool.isRequired,
  isEquitiesApplication: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
  })),
  assetClass: PropTypes.shape(),
  identity: PropTypes.shape(),
  address: PropTypes.shape(),
};

function newFinancial(contact) {
  return mapFinancial(
    {
      id: null,
      sort: contact && contact.type === 'primary' ? 0 : 1,
      annualIncome: '',
      annualIncomeSpecified: '',
      totalNetWorth: '',
      totalNetWorthSpecified: '',
      liquidNetWorth: '',
      liquidNetWorthSpecified: '',
      sourceOfIncome: '',
      contact: contactSearchAndFormat.mapContact(contact),
    },
    contact,
  );
}

export function findEmploymentById(contactId, employments) {
  return employments.find((e) => { return e.contactId === contactId; });
}

const mapTradingProfile = (tradingProfile, contact, isTSEL) => {
  const tp = defaultTradingProfile();
  tp.id = tradingProfile.id;
  tp.tellUsAboutYourself = tradingProfile.tellUsAboutYourself;
  tp.yearsExperienceStocks = tradingProfile.yearsExperienceStocks;
  tp.yearsExperienceFutures = tradingProfile.yearsExperienceFutures;
  tp.yearsExperienceOptions = tradingProfile.yearsExperienceOptions;

  tp.monthsExperienceStocks =
    (tp.yearsExperienceStocks !== tradingProfileTypes.UNDER_ONE) ? 0 : tradingProfile.monthsExperienceStocks;

  tp.monthsExperienceOptions =
    (tp.yearsExperienceOptions !== tradingProfileTypes.UNDER_ONE) ? 0 : tradingProfile.monthsExperienceOptions;

  tp.monthsExperienceFutures =
    (tp.yearsExperienceFutures !== tradingProfileTypes.UNDER_ONE) ? 0 : tradingProfile.monthsExperienceFutures;

  tp.isTSEL = isTSEL;
  tp.sort = contact.type === 'primary' ? 0 : contact.id;
  tp.contact = contactSearchAndFormat.mapContact(contact);

  return tp;
};

const newTradingProfile = (contact, isTSEL) => {
  const newProfile = defaultTradingProfile();
  newProfile.id = null;
  newProfile.sort = contact && contact.type === 'primary' ? 0 : 1;
  newProfile.contact = contactSearchAndFormat.mapContact(contact);
  newProfile.isTSEL = isTSEL;
  return newProfile;
};

const isTSELCountry = (state, countryCode) => {
  let country = null;
  if (state.countries && state.countries.length > 0 && countryCode) {
    country = state.countries.find((item) => {
      return item.countryCode === countryCode;
    });
  }
  return country !== null ? country.isTSEL : false;
};

export function mapStateToInitialValues(state) {
  const dbAccountType = (state && state.accountType) ? state.accountType : null;
  const dbContacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;
  const dbEmployments = (state && state.employment && state.employment.length > 0) ? state.employment : null;
  const dbFinancials = (state && state.financial && state.financial.length > 0) ? state.financial : null;

  const isJointApplication = (dbAccountType && dbAccountType.accountType.indexOf('joint') === 0);
  const isEntityApplication = (dbAccountType && dbAccountType.accountType.indexOf('entity') === 0);

  const financials = [];
  if (dbFinancials && dbFinancials.length > 0) {
    dbFinancials.forEach((financial, i) => {
      if (dbContacts && dbContacts.length > 0) {
        const contact = contactSearchAndFormat.findContactById(financial.contactId, dbContacts);
        financials.push(mapFinancial(financial, contact));
      }
      if (dbEmployments && dbEmployments.length > 0) {
        const employment = findEmploymentById(financial.contactId, dbEmployments);
        if (employment.employmentStatus !== employmentTypes.EMPLOYMENT_STATUS_EMPLOYED
          && employment.employmentStatus !== employmentTypes.EMPLOYMENT_STATUS_SELF_EMPLOYED) {
          if (financials.length > 0 && financials[i].sourceOfIncome === '4') {
            financials[i].sourceOfIncome = null;
          }
        }
      }
    });
  }

  if (dbContacts && dbContacts.length > 0) {
    if (financials.length < 1) {
      const contact = contactSearchAndFormat.findContactByType('primary', dbContacts);
      financials.push(newFinancial(contact));
    }

    if (financials.length < 2 && isJointApplication) {
      const contact = contactSearchAndFormat.findContactByType('joint', dbContacts);
      financials.push(newFinancial(contact));
    }
  }

  financials.sort((a, b) => {
    return a.sort - b.sort;
  });

  const dbTradingProfiles =
    (state && state.tradingProfile && state.tradingProfile.length > 0) ? state.tradingProfile : null;
  const tradingProfiles = [];

  let primaryContact = null;

  if (dbContacts && dbContacts.length > 0) {
    primaryContact = contactSearchAndFormat.findContactByType('primary', dbContacts);
  }

  if (dbTradingProfiles && dbTradingProfiles.length > 0) {
    dbTradingProfiles.forEach((tradingProfile) => {
      if (dbContacts && dbContacts.length > 0) {
        const contact = contactSearchAndFormat.findContactById(tradingProfile.contactId, dbContacts);
        tradingProfiles.push(mapTradingProfile(tradingProfile, contact,
          isTSELCountry(state, contact.countryOfResidence)));
      }
    });
  }

  if (dbContacts && dbContacts.length > 0) {
    if (tradingProfiles.length < 1) {
      tradingProfiles.push(newTradingProfile(primaryContact, isTSELCountry(state, primaryContact.countryOfResidence)));
    }
    if (tradingProfiles.length < 2 && isJointApplication) {
      const jointContact = contactSearchAndFormat.findContactByType('joint', dbContacts);
      tradingProfiles.push(newTradingProfile(jointContact, isTSELCountry(state, jointContact.countryOfResidence)));
    }
  }

  tradingProfiles.sort((a, b) => {
    return a.sort - b.sort;
  });

  return { isJointApplication, isEntityApplication, financials, tradingProfiles };
}

function mapStateToProps(state) {
  let isIraApplication = false;
  let isEquitiesApplication = false;
  const contactName = [];
  const showSpecificAnnualIncome = [false, false];
  const showSpecificTotalNetWorth = [false, false];
  const showSpecificLiquidNetWorth = [false, false];
  const sourceOfIncomeOptions = [];
  const iraAccountTypes = [
    accountTypes.IRA_SEP,
    accountTypes.IRA_SIMPLE,
    accountTypes.IRA_ROTH,
    accountTypes.IRA_ROTH_INHERITED,
    accountTypes.IRA_TRADITIONAL,
    accountTypes.IRA_TRADITIONAL_INHERITED,
  ];
  const selector = formValueSelector('financial');
  const financials = selector(state, 'financials');

  /* Trading Profile props */
  const showTSELAssets = [false, false];
  const tellUsAboutYourselfSelections = [];
  const stockSelections = [];
  const optionSelections = [];
  const futuresSelections = [];

  const initialValues = mapStateToInitialValues(state);
  const tradingProfileExists = (state.tradingProfile && state.tradingProfile.length > 0);

  const tradingProfiles = selector(state, 'tradingProfiles');
  /* END */

  if (financials && financials.length > 0) {
    for (let i = 0; i < financials.length; i += i + 1) {
      contactName[i] = contactSearchAndFormat.formatContactName(financials[i].contact);
      if (financials[i].annualIncome === '1') {
        showSpecificAnnualIncome[i] = true;
      }
      if (financials[i].totalNetWorth === '1') {
        showSpecificTotalNetWorth[i] = true;
      }
      if (financials[i].liquidNetWorth === '1') {
        showSpecificLiquidNetWorth[i] = true;
      }
      if (state.employment && state.employment.length > 0) {
        const employment = state.employment.find(e => e.contactId === financials[i].contact.id);
        const status = employment && employment.employmentStatus;
        if (status === employmentTypes.EMPLOYMENT_STATUS_EMPLOYED
          || status === employmentTypes.EMPLOYMENT_STATUS_SELF_EMPLOYED) {
          sourceOfIncomeOptions[i] = sourceOfIncomeOptionsInternal;
        } else {
          sourceOfIncomeOptions[i] = sourceOfIncomeOptionsInternal.filter((option) => {
            return option.value !== '4';
          });
        }
      }
    }
  }

  if (state.accountType) {
    if (iraAccountTypes.includes(state.accountType.accountType)) {
      isIraApplication = true;
    }

    if (state.accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_EQUITIES)) {
      isEquitiesApplication = true;
    }
  }

  if (tradingProfiles && tradingProfiles.length > 0) {
    for (let i = 0; i < tradingProfiles.length; i += 1) {
      showTSELAssets[i] = tradingProfiles.isTSEL;

      contactName[i] = contactSearchAndFormat.formatContactName(tradingProfiles[i].contact);
      tellUsAboutYourselfSelections[i] = {
        tellUsAboutYourself: '',
      };
      stockSelections[i] = {
        yearsExperience: tradingProfiles[i].yearsExperienceStocks || tradingProfileTypes.NONE,
      };
      optionSelections[i] = {
        yearsExperience: tradingProfiles[i].yearsExperienceOptions || tradingProfileTypes.NONE,
      };
      futuresSelections[i] = {
        yearsExperience: tradingProfiles[i].yearsExperienceFutures || tradingProfileTypes.NONE,
      };
    }
  }

  const financialExists = (state.financial && state.financial.length > 0);

  return {
    initialValues,
    isEntityApplication: !!initialValues.isEntityApplication,
    isJointApplication: !!initialValues.isJointApplication,
    applicationId: state.applicationId,
    authToken: state.authToken,
    financialExists: !!financialExists,
    contactName,
    showSpecificAnnualIncome,
    showSpecificTotalNetWorth,
    showSpecificLiquidNetWorth,
    sourceOfIncomeOptions,
    isIraApplication,
    isEquitiesApplication,
    tradingProfileExists: !!tradingProfileExists,
    countries: state.countries,
    tradingProfile: tradingProfiles,
    showTSELAssets,
    tellUsAboutYourselfSelections,
    stockSelections,
    optionSelections,
    futuresSelections,
    registration: state.registration,
    accountType: state.accountType,
    assetClass: state.assetClass,
    identity: state.identity,
    address: state.address,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      accountTypeActions,
      contactActions,
      employmentActions,
      tradingProfileActions,
      countriesActions,
      registrationActions,
      accountTypeActions,
      assetClassActions,
      identityActions,
      addressActions,
      financialActions,
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'financial',
  validate: FinancialFormValidation,
})(FinancialFormContainer));
