/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const MarginAndOptionsSettings = (props) => {
  const {
    canTradeOptions,
    canTradeMargin,
    handleTradeStockOptionsChange,
  } = props;

  const LINK_CLASS_NAME_MARGIN =
    `btn btn-img checkbox btn-img-auto form-group col-xs-12 LINKCLASSNAME ${!canTradeMargin ? 'disabled' : ''}`;

  const LINK_CLASS_NAME_OPTIONS =
    `btn btn-img checkbox btn-img-auto form-group col-xs-12 LINKCLASSNAME ${!canTradeOptions ? 'disabled' : ''}`;

  return (
    <div className="col col-sm-6 col-xs-12">
      <div className="row">
        <div className="panel-equities-left">
          <h1 className="h2_subHeading equities-account-settings-header">
            Trading Permissions
          </h1>
          <div className="full-width-border" />
          <a href="#" className={LINK_CLASS_NAME_MARGIN}>
            <label className="row" htmlFor="marginAccount">
              <Field
                data-toggle="modal"
                name="marginAccount"
                id={'marginAccount'}
                component="input"
                type="checkbox"
                value="marginAccount"
                disabled={!canTradeMargin ? 'disabled' : ''}
              />
              <div className="col">
                <div className="col col-md-12">
                  <div className="btn-label">Margin Trading</div>
                </div>
              </div>
            </label>
          </a>
          <br /><br /><br />
          <a href="#" className={LINK_CLASS_NAME_OPTIONS}>
            <label className="row" htmlFor="tradeStockOptions">
              <Field
                data-toggle="modal"
                name="tradeStockOptions"
                id={'tradeStockOptions'}
                component="input"
                type="checkbox"
                value="tradeStockOptions"
                onChange={handleTradeStockOptionsChange}
                disabled={!canTradeOptions ? 'disabled' : ''}
              />
              <div className="col">
                <div className="col col-md-12">
                  <div className="btn-label">Options Trading</div>
                </div>
              </div>
            </label>
          </a>
          <br /><br /><br />
        </div>
      </div>
    </div>
  );
};

MarginAndOptionsSettings.propTypes = {
  canTradeOptions: PropTypes.bool,
  canTradeMargin: PropTypes.bool,
  handleTradeStockOptionsChange: PropTypes.func,
};

export default MarginAndOptionsSettings;
