/* eslint-disable no-underscore-dangle */
import { deburr } from 'lodash';

export const mapValuesToAddress = (address) => {
  const a = {};

  a.id = address.id || null;
  a.contactId = address.contact.id || null;
  a.doNotContact = !!address.residential.dontContact;
  a.sameMailingAddress = !address.residential.sameAddress;

  const r = address.residential;
  const m = a.sameMailingAddress ? address.residential : address.mailing;

  a.residentialAddress1 = deburr(r.address1);
  a.residentialAddress2 = deburr(r.address2);
  a.residentialCity = deburr(r.city);
  a.residentialCountry = r.country;
  a.residentialRegion = r.region;
  a.residentialZip = r.zip;
  a.sameSecondaryResidential = !!r.copyFromPrimary;

  a.mailingAddress1 = deburr(m.address1);
  a.mailingAddress2 = deburr(m.address2);
  a.mailingCity = deburr(m.city);
  a.mailingCountry = m.country;
  a.mailingRegion = m.region;
  a.mailingZip = m.zip;
  a.sameSecondaryMailing = !!m.copyFromPrimary;

  a.deviceSessionId = null;
  if (window.localStorage && window.localStorage._s_did) {
    a.deviceSessionId = window.localStorage._s_did;
  }
  if (!a.deviceSessionId) delete a.deviceSessionId;

  return a;
};

export function map(values) {
  const addresses = [];
  values.addresses.forEach((a) => {
    addresses.push(mapValuesToAddress(a));
  });

  if (addresses.length > 1) {
    if (addresses[1].sameSecondaryResidential) {
      addresses[1].residentialAddress1 = addresses[0].residentialAddress1;
      addresses[1].residentialAddress2 = addresses[0].residentialAddress2;
      addresses[1].residentialCity = addresses[0].residentialCity;
      addresses[1].residentialCountry = addresses[0].residentialCountry;
      addresses[1].residentialRegion = addresses[0].residentialRegion;
      addresses[1].residentialZip = addresses[0].residentialZip;
    }

    if (addresses[1].sameSecondaryMailing) {
      addresses[1].mailingAddress1 = addresses[0].mailingAddress1;
      addresses[1].mailingAddress2 = addresses[0].mailingAddress2;
      addresses[1].mailingCity = addresses[0].mailingCity;
      addresses[1].mailingCountry = addresses[0].mailingCountry;
      addresses[1].mailingRegion = addresses[0].mailingRegion;
      addresses[1].mailingZip = addresses[0].mailingZip;
    }
  }

  return addresses;
}
