import React from 'react';
import { withRouter } from 'react-router';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';

const SUMMARY_INDEX = 4;
const FINISHED_INDEX = 5;
const DEPOSIT_FUNDS_STEP = ['Deposit', 'Funds'];
const ONE_LINE_STEP = { position: 'relative !important', top: '6px !important' };

const steps = [
  ['Account', 'Setup'],
  ['Personal', 'Information'],
  ['Employment', 'Information'],
  ['Financials &', 'Experience'],
  ['Settings &', 'Agreements'],
  ['Finished', ''],
];

const currentStep = (location) => {
  const path = location.location.pathname.substring(1);
  return parseInt(location.routes[0].childRoutes.find(r => r.path === path).step, 10);
};

const title = (stepIndex) => {
  return <div><span>{steps[stepIndex][0]}</span><span>{steps[stepIndex][1]}</span></div>;
};

const progressBarSteps = isReturned => steps.map((step, index) => {
  if (isReturned && index !== SUMMARY_INDEX) {
    return <Step key={index.toString()} title={title(index)} status="finish" />;
  }
  if (index !== FINISHED_INDEX) {
    return <Step key={index.toString()} title={title(index)} style={ONE_LINE_STEP} />;
  }
  return <Step key={index.toString()} title={title(index)} />;
});

const isUserInDepositFundsPage = (location) => {
  const locationIncludes = route => location.location.pathname.indexOf(route) > -1;
  if (locationIncludes('deposit-funds')) {
    steps.splice(5, 1, DEPOSIT_FUNDS_STEP);
  }
};

export const BaseProgressBar = (location) => {
  const isReturned = () => {
    const loc = location.location;
    return (loc.pathname.indexOf('summary') > -1 && loc.search.indexOf('returned') > -1);
  };
  isUserInDepositFundsPage(location);

  return (
    <section id="progressBar">
      <div className="fitv_element container-fluid">
        <div className="container notranslate progress-steps-container">
          <div className="row">
            <div className=" col col-md-12 col-lg-12">
              <Steps current={currentStep(location)}>
                {progressBarSteps(isReturned())}
              </Steps>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(BaseProgressBar);
