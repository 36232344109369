import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const InvestmentObjectivesSection = (props) => {
  const { meta: { touched, error } } = props;

  return (
    <div>
      <div className="row">
        <div className="col col-md-12">
          <h1 className="h2_subHeading">
            Options Investment Objective
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-12">
          <div className="panel panel-clear investmentObjectiveQuestion">
            <div className="form-group form-group-inputs">
              <div className="radio">
                <label htmlFor="incomeInvestmentObjective">
                  <Field
                    name="investmentObjective"
                    id="incomeInvestmentObjective"
                    component="input"
                    type="radio"
                    value="income"
                  />
                  <p className="investmentObjectiveAnswer">Income</p>
                  You focus on generating regular returns through dividends or interest from stable investments,
                  ideal for consistent cash flow.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-12">
          <div className="panel panel-clear investmentObjectiveQuestion">
            <div className="form-group form-group-inputs">
              <div className="radio">
                <label htmlFor="growthInvestmentObjective">
                  <Field
                    name="investmentObjective"
                    id="growthInvestmentObjective"
                    component="input"
                    type="radio"
                    value="growth"
                  />
                  <p className="investmentObjectiveAnswer">Growth</p>
                  You aim to increase the value of investments over time by investing in companies
                  or assets expected to appreciate.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-12">
          <div className="panel panel-clear investmentObjectiveQuestion">
            <div className="form-group form-group-inputs">
              <div className="radio">
                <label htmlFor="speculationInvestmentObjective">
                  <Field
                    name="investmentObjective"
                    id="speculationInvestmentObjective"
                    component="input"
                    type="radio"
                    value="speculation"
                  />
                  <p className="investmentObjectiveAnswer">Speculation</p>
                  You take higher risk positions in volatile markets or asssets with the potential
                  for significant short-term gains or losses.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {error && touched && (
        <div className="row">
          <div className="col col-md-12">
            <span className="text-danger">{error}</span>
          </div>
        </div>
      )}
    </div>
  );
};

InvestmentObjectivesSection.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};

export default InvestmentObjectivesSection;
