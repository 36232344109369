import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import Toggle from 'react-toggle';
import CustomSlider from '../CustomSlider';
import OptionsStrategiesList from '../../components/AssetClass/OptionsStrategiesList';
import Config from '../../Config';

const config = new Config();

const AssetClassEquities = (props) => {
  const {
    tradeStockOptionsSelected,
    investmentRiskMarks,
    optionsStrategiesMarks,
    optionsLevel,
    optionsLevelLabel,
    handleOnChangeRisk,
    handleOnChangeOptions,
    handleOnChangeStrategy,
    showFplQuestion,
    canTradeOptions,
    canTradeMargin,
  } = props;

  const panelStyle = {
    paddingBottom: '0px',
  };

  const renderToggleInputOptions = field => (
    <Toggle
      id={field.input.name}
      name={field.input.name}
      checked={(typeof field.input.value === 'boolean' ? field.input.value : false) && canTradeOptions}
      disabled={!canTradeOptions}
      onChange={field.input.onChange}
    />
  );

  const renderToggleInputMargin = field => (
    <Toggle
      id={field.input.name}
      name={field.input.name}
      checked={(typeof field.input.value === 'boolean' ? field.input.value : false) && canTradeMargin}
      disabled={!canTradeMargin}
      onChange={field.input.onChange}
    />
  );

  return (
    <div className="row">
      <div className="panel-equities-left">
        <h1 className="h2_subHeading h2_subHeadingBorderless">
          Equities Account Settings
        </h1>
        <div className="row">
          <div className="col col-xs-12">
            <div className="panel panel-clear panel-bordered">
              <div className="panel-body">
                <div className="row">
                  <div className="col col-xs-10">
                    <Field
                      component={renderToggleInputMargin}
                      name="equitiesAccountMargin"
                      onChange={event => handleOnChangeStrategy(event)}
                    />
                    <span className="panel-question">Margin Trading</span>
                    <p className="help-block" />
                  </div>
                  <div className="col col-xs-2">
                    {!config.optionsEnhancementsEnabled &&
                      <div className="help-circle">
                        <a data-tip data-for="marginOrCash">
                          <i className="material-icons">help</i>
                        </a>
                        <ReactTooltip id="marginOrCash" place="bottom" class="tooltip">
                          <span>In cash-only accounts, customer trades must be fully paid for on or before the
                            settlement date, which is the day after the trade date for options transactions and
                            three days after the trade date for stock trades. Investors with cash-only accounts
                            cannot initiate or hold uncovered short positions. In a margin account, customers are
                            permitted to borrow money from the firm to fund transactions. Margin accounts may also
                            utilize acceptable collateral to finance requirements and debit balances. Margin accounts
                            may also (if approved) hold complex positions, including uncovered short positions.
                            Margin should only be used by sophisticated investors who understand that losses can
                            exceed their original investment.
                          </span>
                        </ReactTooltip>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-clear panel-bordered">
              <div className="panel-body">
                <div className="row">
                  <div className="col col-xs-10">
                    <Field
                      component={renderToggleInputOptions}
                      name="tradeStockOptions"
                    />
                    <span className="panel-question">Options Trading</span>
                    <p className="help-block" />
                  </div>
                </div>
              </div>
            </div>
            {tradeStockOptionsSelected && canTradeOptions && !config.optionsEnhancementsEnabled &&
              <div>
                <div className="panel panel-clear panel-bordered">
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="panel-question">
                          Investment Risk Tolerance
                        </p>
                        <div className="row">
                          <div className="col col-xs-12">
                            <div className="form-group form-group-inputs">
                              <CustomSlider
                                id="investmentObjectives"
                                name="investmentObjectives"
                                marks={investmentRiskMarks}
                                onChange={(preventDefault, value, previousValue) =>
                                  handleOnChangeRisk(preventDefault, value, previousValue,
                                  )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel panel-clear panel-bordered">
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="help-circle">
                          <a data-tip data-for="investmentObjectsTooltip">
                            <i className="material-icons">help</i>
                          </a>
                          <ReactTooltip id="investmentObjectsTooltip" place="left" class="tooltip">
                            <span>Cash accounts are eligible for buying calls and puts, European index spreads,
                              protective and covered puts. Margin accounts are eligible for all cash objectives plus
                              straddles, uncovered calls
                              and puts.
                            </span>
                          </ReactTooltip>
                        </div>
                        <p className="panel-question">
                          Options Trading Level
                          <span className="options-level-label">Level {optionsLevelLabel}</span>
                        </p>
                        <div className="row">
                          <div className="col col-xs-12">
                            <div className="form-group form-group-inputs">
                              <CustomSlider
                                id="optionsStrategies"
                                name="optionsStrategies"
                                marks={optionsStrategiesMarks}
                                onChange={(preventDefault, value, previousValue) =>
                                  handleOnChangeOptions(preventDefault, value, previousValue,
                                  )}
                              />
                            </div>
                            <OptionsStrategiesList optionsLevel={optionsLevel} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="col col-xs-12">
            {showFplQuestion &&
              <div className="panel panel-clear panel-bordered">
                <div className="panel-body" style={panelStyle}>
                  <div className="row">
                    <div className="col-md-12">
                      <p className="panel-question">You could earn extra interest income on your equities account
                        if you qualify for our Fully Paid Stock Program. Would you like to apply?&nbsp;
                        <a
                          rel="noopener noreferrer"
                          href="https://www.tradestation.com/promo/fully-paid-lending/"
                          target="_blank"
                        >
                          Learn more.
                        </a>
                      </p>
                    </div>
                    <div className="col col-xs-12">
                      <div className="form-group form-group-inputs">
                        <div className="radio">
                          <label htmlFor="fullyPaidLendingProgramYes">
                            <Field
                              name="fullyPaidLendingProgram"
                              id="fullyPaidLendingProgramYes"
                              component="input"
                              type="radio"
                              value="yes"
                            />
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col col-xs-12">
                      <div className="form-group form-group-inputs">
                        <div className="radio">
                          <label htmlFor="fullyPaidLendingProgramNo">
                            <Field
                              name="fullyPaidLendingProgram"
                              id="fullyPaidLendingProgramNo"
                              component="input"
                              type="radio"
                              value="no"
                            />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="panel panel-clear panel-bordered">
              <p className="panel-text">Note: Options trading is not suitable for all investors. &nbsp;
                <a
                  href="https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AssetClassEquities.propTypes = {
  tradeStockOptionsSelected: PropTypes.bool.isRequired,
  investmentRiskMarks: PropTypes.shape(),
  optionsStrategiesMarks: PropTypes.shape(),
  optionsLevel: PropTypes.number.isRequired,
  optionsLevelLabel: PropTypes.string.isRequired,
  handleOnChangeRisk: PropTypes.func.isRequired,
  handleOnChangeOptions: PropTypes.func.isRequired,
  handleOnChangeStrategy: PropTypes.func.isRequired,
  showFplQuestion: PropTypes.bool.isRequired,
  canTradeOptions: PropTypes.bool,
  canTradeMargin: PropTypes.bool,
};

export default AssetClassEquities;
