import React from 'react';
import PropTypes from 'prop-types';

export const SvgCheckmark = ({ color, cssClass = '' }) => (
  <svg
    width="12.5"
    height="12.5"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="3.5"
    strokeLinecap="square"
    strokeLinejoin="miter"
    className={cssClass}
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

SvgCheckmark.propTypes = {
  color: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
};

export const strategies = [
  'Covered Calls',
  'Covered/Married Puts',
  'Long Options',
  'Verticals',
  'Butterflies/Condors',
  'Iron Butterflies/Condors',
  'Long Calendars & Ratio Spreads',
  'Naked Short Puts',
  'Naked Short Calls',
  'Naked Short Straddles/Strangles',
];

export const levels = [
  { id: 1, name: 'Level 1', shortName: 'L1', strategies: [0, 1] },
  { id: 2, name: 'Level 2', shortName: 'L2', strategies: [0, 1, 2] },
  { id: 3, name: 'Level 3', shortName: 'L3', strategies: [0, 1, 2, 3, 4, 5, 6] },
  { id: 4, name: 'Level 4', shortName: 'L4', strategies: [0, 1, 2, 3, 4, 5, 6, 7] },
  { id: 5, name: 'Level 5', shortName: 'L5', strategies: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] },
];
