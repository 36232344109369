import React from 'react';
import { Route, IndexRedirect } from 'react-router';
import App from './components/App';
import RegistrationCredentialsPage from './components/RegistrationCredentials/RegistrationCredentialsPage';
import PersonalInfoPage from './components/PersonalInfo/PersonalInfoPage';
import AgreementsPage from './components/Agreements/AgreementsPage';
import AccountTypePage from './components/AccountType/AccountTypePage';
import DocumentsPage from './components/Documents/DocumentsPage';
import EmploymentPage from './components/Employment/EmploymentPage';
import ApprovalStatusPage from './components/ApprovalStatus/ApprovalStatusPage';
import DepositFundsPage from './components/DepositFunds/DepositFundsPage';
import InlineFundsPage from './components/InlineFunds/InlineFundsPage';
import FinancialPage from './components/Financial/FinancialPage';
import IraAccountPage from './components/IraAccount/IraAccountPage';
import TradingAuthorizationPage from './components/TradingAuthorization/TradingAuthorizationPage';
// eslint-disable-next-line import/no-named-default
import { default as SummaryPageV1 } from './components/SummaryV1/SummaryPage';
import SummaryPage from './components/Summary/SummaryPage';
import requireAuthentication from './requireAuthentication';
import PendingApplicationsPage from './components/PendingApplications/PendingApplicationsPage';
import IntroPage from './components/Intro/IntroPage';
import IdVerifyPage from './components/IdVerify/IdVerifyPage';
import IdVerifyResubmitPage from './components/IdVerifyResubmit/IdVerifyResubmitPage';
import FinishedPage from './components/Finished/FinishedPage';
import requiereAutoLogin from './requireAutoLogin';
import EntityApplicationPage from './components/EntityApplication/EntityApplicationPage';
import ApprovalEntityPage from './components/ApprovalEntity/ApprovalEntityPage';
import LogOffPage from './components/LogOff/LogOffPage';
import PhoneVerificationPage from './components/PhoneVerification/PhoneVerificationPage';
import EquitiesAccountSettingsPage from './components/EquitiesAccountSettings/EquitiesAccountSettingsPage';
import Config from './Config';

const config = new Config();

export default (
  <Route path="/" component={App} >
    <IndexRedirect to="/intro" />
    <Route path="intro" component={requiereAutoLogin(IntroPage)} step="0" />
    <Route
      path="registration"
      component={requiereAutoLogin(RegistrationCredentialsPage)}
      step="0"
    />
    <Route
      path="pending-applications"
      component={requireAuthentication(PendingApplicationsPage)}
      step="0"
    />
    <Route
      path="phone-verification"
      component={requireAuthentication(PhoneVerificationPage)}
      step="0"
    />
    <Route
      path="account-type"
      component={requireAuthentication(AccountTypePage)}
      step="0"
    />
    <Route
      path="personal-info"
      component={requireAuthentication(PersonalInfoPage)}
      step="1"
    />
    <Route
      path="download-application"
      component={requireAuthentication(EntityApplicationPage)}
      step="1"
    />
    <Route path="finish-business-application" component={requireAuthentication(ApprovalEntityPage)} step="2" />
    <Route path="employment" component={requireAuthentication(EmploymentPage)} step="2" />
    <Route path="financial" component={requireAuthentication(FinancialPage)} step="3" />
    <Route path="equities-account-settings" component={requireAuthentication(EquitiesAccountSettingsPage)} step="4" />
    <Route path="ira-account" component={requireAuthentication(IraAccountPage)} step="4" />
    <Route path="documents" component={requireAuthentication(DocumentsPage)} step="4" />
    <Route
      path="additional-info"
      component={requireAuthentication(TradingAuthorizationPage)}
      step="4"
    />
    <Route path="resubmit" component={requireAuthentication(IdVerifyResubmitPage)} step="4" />
    <Route path="id-verify" component={requireAuthentication(IdVerifyPage)} step="4" />
    {
      config.optionsEnhancementsEnabled
        ? <Route path="summary" component={requireAuthentication(SummaryPage)} step="4" />
        : <Route path="summary" component={requireAuthentication(SummaryPageV1)} step="4" />
    }
    <Route path="agreements" component={requireAuthentication(AgreementsPage)} step="4" />
    <Route path="thank-you" component={requireAuthentication(ApprovalStatusPage)} step="5" />
    <Route path="deposit-funds" component={requireAuthentication(DepositFundsPage)} step="5" />
    <Route path="funding" component={requireAuthentication(InlineFundsPage)} step="5" />

    <Route path="finished" component={requireAuthentication(FinishedPage)} step="2" />
    <Route path="logoff" component={LogOffPage} step="0" />
  </Route>
);

