import React from 'react';
import PropTypes from 'prop-types';
import { SvgCheckmark } from './EquitiesAccountSettingsHelper';

const OptionsLevelColumn = ({ input, level, selectedLevel, maxOptionsLevel, strategies }) => {
  const isDisabled = level.id > maxOptionsLevel;
  const disabledStyle = isDisabled ? 'column-disabled' : '';
  const selectedStyle = selectedLevel === level.id ? 'selected' : '';

  const handleClick = () => {
    if (!isDisabled) {
      input.onChange(level.id);
    }
  };

  const handleKeyPress = (event) => { // add ability to select level with keyboard
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  return (
    <div
      className={`options-level-column ${selectedStyle} ${disabledStyle}`}
      onClick={handleClick}
      data-short-name={level.shortName}
      tabIndex={isDisabled ? -1 : 0}
      role="button"
      onKeyDown={handleKeyPress}
    >
      <h5 className="level-text">{level.name}</h5>
      {/* show dot/checkmark if options level includes strategy (checkmark if selected, dot if not selected) */}
      {strategies.map((_, index) => (
        <div key={index} className="options-level-circle">
          {selectedLevel === level.id && level.strategies.includes(index) &&
            <span className="checkmark"><SvgCheckmark color="#ffffff" /></span>
          }
          {selectedLevel !== level.id && level.strategies.includes(index) &&
            <span className={`options-dot ${isDisabled ? 'options-dot-disabled' : ''}`}>•</span>
          }
        </div>
      ))}
      <div className="options-level-radio">
        <input
          type="radio"
          name="selectedLevel"
          value={level.id}
          checked={selectedLevel === level.id}
          onChange={() => input.onChange(level.id)}
        />
        {/* show lock if disabled, show button if enabled, add checkmark to button if selected */}
        {isDisabled ? <img src="images/lock-solid.svg" className="lock-icon" alt="Lock icon" />
          : <div className="custom-radio">{selectedLevel === level.id && (<SvgCheckmark color="#0089cf" />)}</div>
        }
      </div>
    </div>
  );
};

OptionsLevelColumn.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  level: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    shortName: PropTypes.string,
    strategies: PropTypes.arrayOf(PropTypes.number),
  }),
  selectedLevel: PropTypes.number,
  maxOptionsLevel: PropTypes.number,
  strategies: PropTypes.arrayOf(PropTypes.string),
};

export default OptionsLevelColumn;
