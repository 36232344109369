import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as summaryValueMapper from './summaryValueMapper';
import SummarySection from '../../components/SummaryV1/SummarySection';
import * as financialActions from '../../actions/financialActions';
import * as tradingProfileActions from '../../actions/tradingProfileActions';
import * as contactActions from '../../actions/contactActions';
import {
  annualIncomeOptions,
  netWorthOptions,
  sourceOfIncomeOptionsInternal,
  sourceOfAssetsForTradingOptions,
} from '../../containers/FinancialFormContainer';
import * as summaryRowTypes from '../../components/SummaryV1/summaryRowTypes';
import * as TradingProfileTypes from '../../components/TradingProfile/tradingProfileTypes';
import * as contactSearchAndFormat from '../../containers/contactSearchAndFormat';

export class SummaryFinancialContainer extends React.Component {
  componentDidMount() {
    if (!this.props.financial) {
      this.props.actions.fetchFinancial(this.props.applicationId, this.props.authToken);
    }
    if (!this.props.tradingProfile) {
      this.props.actions.fetchTradingProfile(this.props.applicationId, this.props.authToken);
    }
  }

  render() {
    return (
      <SummarySection {...this.props} />
    );
  }
}

SummaryFinancialContainer.propTypes = {
  actions: PropTypes.shape({
    fetchTradingProfile: PropTypes.func,
    fetchFinancial: PropTypes.func,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  financial: PropTypes.shape(),
  tradingProfile: PropTypes.shape(),
};

function findTradingProfileByContactId(contactId, tradingProfiles) {
  return tradingProfiles.find((a) => { return a.contactId === contactId; });
}

export const toCurrencyString = (value) => {
  const number = Number(value);

  if (isNaN(number)) {
    return null;
  }

  return `$${number.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
};

export const getAnnualIncomeText = (financial) => {
  if (financial.annualIncomeSpecified) {
    return toCurrencyString(financial.annualIncomeSpecified);
  }

  return summaryValueMapper.getTextFromValue(annualIncomeOptions, financial.annualIncome);
};

export const getLiquidNetWorthText = (financial) => {
  if (financial.liquidNetWorthSpecified) {
    return toCurrencyString(financial.liquidNetWorthSpecified);
  }

  return summaryValueMapper.getTextFromValue(netWorthOptions, financial.liquidNetWorth);
};

export const getTotalNetWorthText = (financial) => {
  if (financial.totalNetWorthSpecified) {
    return toCurrencyString(financial.totalNetWorthSpecified);
  }

  return summaryValueMapper.getTextFromValue(netWorthOptions, financial.totalNetWorth);
};

export const getSourceOfIncomeText = (financial) => {
  return summaryValueMapper.getTextFromValue(sourceOfIncomeOptionsInternal, financial.sourceOfIncome);
};

const getSourceOfAssetsForTradingText = (sourceOfAssetsForTradingCode) => {
  return summaryValueMapper.getTextFromValue(sourceOfAssetsForTradingOptions, sourceOfAssetsForTradingCode);
};

const getTradingYears = (tradingYears, tradingYearsCode) => {
  return summaryValueMapper.getTextFromValue(tradingYears, tradingYearsCode);
};

function mapStateToInitialValues(state, groupIndex) {
  let isLoading = true;
  const contacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;
  const financials = (state.financial && state.financial.length > 0) ? state.financial : null;
  const tradingProfiles =
  (state && state.tradingProfile && state.tradingProfile.length > 0) ? state.tradingProfile : null;

  if (state.financial) {
    isLoading = false;
  }

  let financial = null;
  let tradingProfileInfo = [];
  let tradingProfile = null;

  const contactType = (groupIndex === 0) ? 'primary' : 'joint';
  const contact = contacts ? contactSearchAndFormat.findContactByType(contactType, contacts) : null;
  if (contact) {
    financial = financials ? contactSearchAndFormat.findEntityByContactId(contact.id, financials) : null;
  }

  if (contact && tradingProfiles) {
    tradingProfile = tradingProfiles ? findTradingProfileByContactId(contact.id, tradingProfiles) : null;
  }

  const financialInformation = [
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'APPROXIMATE ANNUAL INCOME',
      value: financial ? getAnnualIncomeText(financial) : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'APPROXIMATE TOTAL NET WORTH',
      value: financial ? getTotalNetWorthText(financial) : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'APPROXIMATE LIQUID NET WORTH',
      value: financial ? getLiquidNetWorthText(financial) : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'WHAT IS YOUR SOURCE OF INCOME',
      value: financial ? getSourceOfIncomeText(financial) : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'HOW WILL YOU FUND YOUR ACCOUNT',
      value: financial ? getSourceOfAssetsForTradingText(financial.sourceOfAssetsForTrading) : '',
    },
  ];

  if (tradingProfile) {
    isLoading = false;

    const tradingYears = TradingProfileTypes.TRADING_PROFILE_OPTIONS.yearsExperience;
    const tradingTypes = ['Stocks', 'Options', 'Futures'];

    if (tradingProfile) {
      tradingProfileInfo = tradingProfileInfo.concat(tradingTypes.map((tradingType) => {
        let tradingProfileSection = [
          {
            keyType: summaryRowTypes.SUMMARY_ROW_BOLD,
            valueType: summaryRowTypes.SUMMARY_ROW_NONE,
            key: tradingType,
            value: '',
          },
          {
            keyType: summaryRowTypes.SUMMARY_ROW_TXT,
            valueType: summaryRowTypes.SUMMARY_ROW_TXT,
            key: 'NUMBER OF YEARS TRADING',
            value: tradingProfile ?
            getTradingYears(tradingYears, tradingProfile[`yearsExperience${tradingType.replace(/ /g, '')}`]) :
            '',
          },
        ];

        if (tradingProfile[`yearsExperience${tradingType.replace(/ /g, '')}`] === TradingProfileTypes.UNDER_ONE) {
          tradingProfileSection = tradingProfileSection.concat([
            {
              keyType: summaryRowTypes.SUMMARY_ROW_TXT,
              valueType: summaryRowTypes.SUMMARY_ROW_TXT,
              key: 'NUMBER OF MONTHS',
              value:
                tradingProfile ? tradingProfile[`monthsExperience${tradingType.replace(/ /g, '')}`].toString() : '',
            },
          ]);
        }

        return tradingProfileSection;
      }));
      tradingProfileInfo = [].concat(...tradingProfileInfo);
    }
  }

  const tradingExpAndFinancial = financialInformation.concat(tradingProfileInfo);

  return {
    isLoading,
    key: 'financial',
    value: tradingExpAndFinancial,
    title: 'FINANCIAL & TRADING EXP.',
    editLink: '/financial',
  };
}

function mapStateToProps(state, ownProps) {
  const groupIndex = ownProps.groupIndex;
  const initialValues = mapStateToInitialValues(state, groupIndex);
  const errorMessage = '';

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    isLoading: initialValues.isLoading,
    entity: initialValues.key,
    summaryInfo: initialValues.value,
    title: initialValues.title,
    editLink: initialValues.editLink,
    errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      financialActions,
      tradingProfileActions,
      contactActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryFinancialContainer);
