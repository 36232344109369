export const CHECK_USERNAME_AVAILABILITY_REQUEST = 'CHECK_USERNAME_AVAILABILITY_REQUEST';
export const CHECK_USERNAME_AVAILABILITY_SUCCESS = 'CHECK_USERNAME_AVAILABILITY_SUCCESS';
export const CHECK_USERNAME_AVAILABILITY_ERROR = 'CHECK_USERNAME_AVAILABILITY_ERROR';

export const CHECK_ACCOUNT_AVAILABILITY_REQUEST = 'CHECK_ACCOUNT_AVAILABILITY_REQUEST';
export const CHECK_ACCOUNT_AVAILABILITY_SUCCESS = 'CHECK_ACCOUNT_AVAILABILITY_SUCCESS';
export const CHECK_ACCOUNT_AVAILABILITY_ERROR = 'CHECK_ACCOUNT_AVAILABILITY_ERROR';

export const FETCH_ACCOUNT_TYPE_REQUEST = 'FETCH_ACCOUNT_TYPE_REQUEST';
export const FETCH_ACCOUNT_TYPE_SUCCESS = 'FETCH_ACCOUNT_TYPE_SUCCESS';
export const FETCH_ACCOUNT_TYPE_ERROR = 'FETCH_ACCOUNT_TYPE_ERROR';

export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES_ERROR';

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const UNSET_AUTH_TOKEN = 'UNSET_AUTH_TOKEN';
export const SET_APPLICATION_ID = 'SET_APPLICATION_ID';
export const UNSET_APPLICATION_ID = 'UNSET_APPLICATION_ID';
export const UNSET_USER_ID = 'UNSET_USER_ID';

export const SUBMIT_APPLICATION_MESSAGES_REQUEST = 'SUBMIT_APPLICATION_MESSAGES_REQUEST';
export const SUBMIT_APPLICATION_MESSAGES_SUCCESS = 'SUBMIT_APPLICATION_MESSAGES_SUCCESS';
export const SUBMIT_APPLICATION_MESSAGES_ERROR = 'SUBMIT_APPLICATION_MESSAGES_ERROR';

export const FETCH_APPLICATION_REQUEST = 'FETCH_APPLICATION_REQUEST';
export const FETCH_APPLICATION_SUCCESS = 'FETCH_APPLICATION_SUCCESS';
export const FETCH_APPLICATION_ERROR = 'FETCH_APPLICATION_ERROR';

export const FETCH_APPLICATION_ELIGIBLE_ACCOUNTS_REQUEST = 'FETCH_APPLICATION_ELIGIBLE_ACCOUNTS_REQUEST';
export const FETCH_APPLICATION_ELIGIBLE_ACCOUNTS_SUCCESS = 'FETCH_APPLICATION_ELIGIBLE_ACCOUNTS_SUCCESS';
export const FETCH_APPLICATION_ELIGIBLE_ACCOUNTS_ERROR = 'FETCH_APPLICATION_ELIGIBLE_ACCOUNTS_ERROR';

export const START_APPLICATION_REQUEST = 'START_APPLICATION_REQUEST';
export const START_APPLICATION_SUCCESS = 'START_APPLICATION_SUCCESS';
export const START_APPLICATION_ERROR = 'START_APPLICATION_ERROR';

export const ARKOSE_TOKEN_VALIDATION_REQUEST = 'ARKOSE_TOKEN_VALIDATION_REQUEST';
export const ARKOSE_TOKEN_VALIDATION_SUCCESS = 'ARKOSE_TOKEN_VALIDATION_SUCCESS';
export const ARKOSE_TOKEN_VALIDATION_ERROR = 'ARKOSE_TOKEN_VALIDATION_ERROR';

export const SUBMIT_ENTITY_APPLICATION_REQUEST = 'SUBMIT_ENTITY_APPLICATION_REQUEST';
export const SUBMIT_ENTITY_APPLICATION_SUCCESS = 'SUBMIT_ENTITY_APPLICATION_SUCCESS';
export const SUBMIT_ENTITY_APPLICATION_ERROR = 'SUBMIT_ENTITY_APPLICATION_ERROR';

export const FETCH_REGISTRATION_REQUEST = 'FETCH_REGISTRATION_REQUEST';
export const FETCH_REGISTRATION_SUCCESS = 'FETCH_REGISTRATION_SUCCESS';
export const FETCH_REGISTRATION_ERROR = 'FETCH_REGISTRATION_ERROR';

export const SUBMIT_ACCOUNT_TYPE_REQUEST = 'SUBMIT_ACCOUNT_TYPE_REQUEST';
export const SUBMIT_ACCOUNT_TYPE_SUCCESS = 'SUBMIT_ACCOUNT_TYPE_SUCCESS';
export const SUBMIT_ACCOUNT_TYPE_ERROR = 'SUBMIT_ACCOUNT_TYPE_ERROR';

export const FETCH_CAMPAIGN_REQUEST = 'FETCH_CAMPAIGN_REQUEST';
export const FETCH_CAMPAIGN_SUCCESS = 'FETCH_CAMPAIGN_SUCCESS';
export const FETCH_CAMPAIGN_ERROR = 'FETCH_CAMPAIGN_ERROR';

export const SUBMIT_CAMPAIGN_REQUEST = 'SUBMIT_CAMPAIGN_REQUEST';
export const SUBMIT_CAMPAIGN_SUCCESS = 'SUBMIT_CAMPAIGN_SUCCESS';
export const SUBMIT_CAMPAIGN_ERROR = 'SUBMIT_CAMPAIGN_ERROR';

export const SUBMIT_REGISTRATION_REQUEST = 'SUBMIT_REGISTRATION_REQUEST';
export const SUBMIT_REGISTRATION_SUCCESS = 'SUBMIT_REGISTRATION_SUCCESS';
export const SUBMIT_REGISTRATION_ERROR = 'SUBMIT_REGISTRATION_ERROR';

export const SUBMIT_REGISTRATION_CREDENTIALS_REQUEST = 'SUBMIT_REGISTRATION_CREDENTIALS_REQUEST';
export const SUBMIT_REGISTRATION_CREDENTIALS_SUCCESS = 'SUBMIT_REGISTRATION_CREDENTIALS_SUCCESS';
export const SUBMIT_REGISTRATION_CREDENTIALS_ERROR = 'SUBMIT_REGISTRATION_CREDENTIALS_ERROR';

export const UPDATE_REGISTRATION_CREDENTIALS_REQUEST = 'UPDATE_REGISTRATION_CREDENTIALS_REQUEST';
export const UPDATE_REGISTRATION_CREDENTIALS_SUCCESS = 'UPDATE_REGISTRATION_CREDENTIALS_SUCCESS';
export const UPDATE_REGISTRATION_CREDENTIALS_ERROR = 'UPDATE_REGISTRATION_CREDENTIALS_ERROR';

export const SUBMIT_ASSET_CLASS_REQUEST = 'SUBMIT_ASSET_CLASS_REQUEST';
export const SUBMIT_ASSET_CLASS_SUCCESS = 'SUBMIT_ASSET_CLASS_SUCCESS';
export const SUBMIT_ASSET_CLASS_ERROR = 'SUBMIT_ASSET_CLASS_ERROR';

export const FETCH_ASSET_CLASS_REQUEST = 'FETCH_ASSET_CLASS_REQUEST';
export const FETCH_ASSET_CLASS_SUCCESS = 'FETCH_ASSET_CLASS_SUCCESS';
export const FETCH_ASSET_CLASS_ERROR = 'FETCH_ASSET_CLASS_ERROR';

export const FETCH_ADDRESS_REQUEST = 'FETCH_ADDRESS_REQUEST';
export const FETCH_ADDRESS_SUCCESS = 'FETCH_ADDRESS_SUCCESS';
export const FETCH_ADDRESS_ERROR = 'FETCH_ADDRESS_ERROR';

export const UPSERT_ADDRESS_REQUEST = 'UPSERT_ADDRESS_REQUEST';
export const UPSERT_ADDRESS_SUCCESS = 'UPSERT_ADDRESS_SUCCESS';
export const UPSERT_ADDRESS_ERROR = 'UPSERT_ADDRESS_ERROR';

export const FETCH_IDENTITY_REQUEST = 'FETCH_IDENTITY_REQUEST';
export const FETCH_IDENTITY_SUCCESS = 'FETCH_IDENTITY_SUCCESS';
export const FETCH_IDENTITY_ERROR = 'FETCH_IDENTITY_ERROR';

export const UPSERT_IDENTITY_REQUEST = 'UPSERT_IDENTITY_REQUEST';
export const UPSERT_IDENTITY_SUCCESS = 'UPSERT_IDENTITY_SUCCESS';
export const UPSERT_IDENTITY_ERROR = 'UPSERT_IDENTITY_ERROR';

export const FETCH_EMPLOYMENT_REQUEST = 'FETCH_EMPLOYMENT_REQUEST';
export const FETCH_EMPLOYMENT_SUCCESS = 'FETCH_EMPLOYMENT_SUCCESS';
export const FETCH_EMPLOYMENT_ERROR = 'FETCH_EMPLOYMENT_ERROR';

export const SUBMIT_EMPLOYMENT_REQUEST = 'SUBMIT_EMPLOYMENT_REQUEST';
export const SUBMIT_EMPLOYMENT_SUCCESS = 'SUBMIT_EMPLOYMENT_SUCCESS';
export const SUBMIT_EMPLOYMENT_ERROR = 'SUBMIT_EMPLOYMENT_ERROR';

export const FETCH_TRADING_PROFILE_REQUEST = 'FETCH_TRADING_PROFILE_REQUEST';
export const FETCH_TRADING_PROFILE_SUCCESS = 'FETCH_TRADING_PROFILE_SUCCESS';
export const FETCH_TRADING_PROFILE_ERROR = 'FETCH_TRADING_PROFILE_ERROR';

export const UPSERT_TRADING_PROFILE_REQUEST = 'UPSERT_TRADING_PROFILE_REQUEST';
export const UPSERT_TRADING_PROFILE_SUCCESS = 'UPSERT_TRADING_PROFILE_SUCCESS';
export const UPSERT_TRADING_PROFILE_ERROR = 'UPSERT_TRADING_PROFILE_ERROR';

export const FETCH_FINANCIAL_REQUEST = 'FETCH_FINANCIAL_REQUEST';
export const FETCH_FINANCIAL_SUCCESS = 'FETCH_FINANCIAL_SUCCESS';
export const FETCH_FINANCIAL_ERROR = 'FETCH_FINANCIAL_ERROR';

export const UPSERT_FINANCIAL_REQUEST = 'UPSERT_FINANCIAL_REQUEST';
export const UPSERT_FINANCIAL_SUCCESS = 'UPSERT_FINANCIAL_SUCCESS';
export const UPSERT_FINANCIAL_ERROR = 'UPSERT_FINANCIAL_ERROR';

export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_ERROR = 'FETCH_DOCUMENTS_ERROR';

export const FETCH_TRADING_AUTHORIZATION_REQUEST = 'FETCH_TRADING_AUTHORIZATION_REQUEST';
export const FETCH_TRADING_AUTHORIZATION_SUCCESS = 'FETCH_TRADING_AUTHORIZATION_SUCCESS';
export const FETCH_TRADING_AUTHORIZATION_ERROR = 'FETCH_TRADING_AUTHORIZATION_ERROR';

export const SUBMIT_DOCUMENTS_REQUEST = 'SUBMIT_DOCUMENTS_REQUEST';
export const SUBMIT_DOCUMENTS_SUCCESS = 'SUBMIT_DOCUMENTS_SUCCESS';
export const SUBMIT_DOCUMENTS_ERROR = 'SUBMIT_DOCUMENTS_ERROR';

export const DELETE_DOCUMENTS_REQUEST = 'DELETE_DOCUMENTS_REQUEST';
export const DELETE_DOCUMENTS_SUCCESS = 'DELETE_DOCUMENTS_SUCCESS';
export const DELETE_DOCUMENTS_ERROR = 'DELETE_DOCUMENTS_ERROR';

export const FETCH_DOCUMENTS_REQUIRED_REQUEST = 'FETCH_DOCUMENTS_REQUIRED_REQUEST';
export const FETCH_DOCUMENTS_REQUIRED_SUCCESS = 'FETCH_DOCUMENTS_REQUIRED_SUCCESS';
export const FETCH_DOCUMENTS_REQUIRED_ERROR = 'FETCH_DOCUMENTS_REQUIRED_ERROR';

export const SUBMIT_TRADING_AUTHORIZATION_REQUEST = 'SUBMIT_TRADING_AUTHORIZATION_REQUEST';
export const SUBMIT_TRADING_AUTHORIZATION_SUCCESS = 'SUBMIT_TRADING_AUTHORIZATION_SUCCESS';
export const SUBMIT_TRADING_AUTHORIZATION_ERROR = 'SUBMIT_TRADING_AUTHORIZATION_ERROR';

export const FETCH_AGREEMENTS_REQUEST = 'FETCH_AGREEMENTS_REQUEST';
export const FETCH_AGREEMENTS_SUCCESS = 'FETCH_AGREEMENTS_SUCCESS';
export const FETCH_AGREEMENTS_ERROR = 'FETCH_AGREEMENTS_ERROR';

export const SUBMIT_AGREEMENTS_REQUEST = 'SUBMIT_AGREEMENTS_REQUEST';
export const SUBMIT_AGREEMENTS_SUCCESS = 'SUBMIT_AGREEMENTS_SUCCESS';
export const SUBMIT_AGREEMENTS_ERROR = 'SUBMIT_AGREEMENTS_ERROR';

export const SET_DOCUMENTS_TO_UPLOAD = 'SET_DOCUMENTS_TO_UPLOAD';
export const FETCH_DOCUMENTS_TO_UPLOAD = 'FETCH_DOCUMENTS_TO_UPLOAD';

export const FETCH_SALESREPS_REQUEST = 'FETCH_SALESREPS_REQUEST';
export const FETCH_SALESREPS_SUCCESS = 'FETCH_SALESREPS_SUCCESS';
export const FETCH_SALESREPS_ERROR = 'FETCH_SALESREPS_ERROR';

export const FETCH_HEARABOUTUS_REQUEST = 'FETCH_HEARABOUTUS_REQUEST';
export const FETCH_HEARABOUTUS_SUCCESS = 'FETCH_HEARABOUTUS_SUCCESS';
export const FETCH_HEARABOUTUS_ERROR = 'FETCH_HEARABOUTUS_ERROR';

export const FETCH_CONTACT_REQUEST = 'FETCH_CONTACT_REQUEST';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_CONTACT_ERROR = 'FETCH_CONTACT_ERROR';

export const SUBMIT_PRIMARY_CONTACT_REQUEST = 'SUBMIT_PRIMARY_CONTACT_REQUEST';
export const SUBMIT_PRIMARY_CONTACT_SUCCESS = 'SUBMIT_PRIMARY_CONTACT_SUCCESS';
export const SUBMIT_PRIMARY_CONTACT_ERROR = 'SUBMIT_PRIMARY_CONTACT_ERROR';

export const SUBMIT_CONTACT_REQUEST = 'SUBMIT_CONTACT_REQUEST';
export const SUBMIT_CONTACT_SUCCESS = 'SUBMIT_CONTACT_SUCCESS';
export const SUBMIT_CONTACT_ERROR = 'SUBMIT_CONTACT_ERROR';

export const FETCH_SECURITY_CREDENTIALS_REQUEST = 'FETCH_SECURITY_CREDENTIALS_REQUEST';
export const FETCH_SECURITY_CREDENTIALS_SUCCESS = 'FETCH_SECURITY_CREDENTIALS_SUCCESS';
export const FETCH_SECURITY_CREDENTIALS_ERROR = 'FETCH_SECURITY_CREDENTIALS_ERROR';

export const SUBMIT_SECURITY_CREDENTIALS_REQUEST = 'SUBMIT_SECURITY_CREDENTIALS_REQUEST';
export const SUBMIT_SECURITY_CREDENTIALS_SUCCESS = 'SUBMIT_SECURITY_CREDENTIALS_SUCCESS';
export const SUBMIT_SECURITY_CREDENTIALS_ERROR = 'SUBMIT_SECURITY_CREDENTIALS_ERROR';

export const PURGE_SECURITY_CREDENTIALS = 'PURGE_SECURITY_CREDENTIALS';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const CLEAR_APPLICATION_DATA = 'CLEAR_APPLICATION_DATA';

export const FETCH_INDUSTRIES_REQUEST = 'FETCH_INDUSTRIES_REQUEST';
export const FETCH_INDUSTRIES_SUCCESS = 'FETCH_INDUSTRIES_SUCCESS';
export const FETCH_INDUSTRIES_ERROR = 'FETCH_INDUSTRIES_ERROR';

export const FETCH_PENDING_APPLICATIONS_REQUEST = 'FETCH_PENDING_APPLICATIONS_REQUEST';
export const FETCH_PENDING_APPLICATIONS_SUCCESS = 'FETCH_PENDING_APPLICATIONS_SUCCESS';
export const FETCH_PENDING_APPLICATIONS_ERROR = 'FETCH_PENDING_APPLICATIONS_ERROR';

export const FETCH_IRA_ACCOUNT_REQUEST = 'FETCH_IRA_ACCOUNT_REQUEST';
export const FETCH_IRA_ACCOUNT_SUCCESS = 'FETCH_IRA_ACCOUNT_SUCCESS';
export const FETCH_IRA_ACCOUNT_ERROR = 'FETCH_IRA_ACCOUNT_ERROR';

export const SUBMIT_IRA_ACCOUNT_REQUEST = 'SUBMIT_IRA_ACCOUNT_REQUEST';
export const SUBMIT_IRA_ACCOUNT_SUCCESS = 'SUBMIT_IRA_ACCOUNT_SUCCESS';
export const SUBMIT_IRA_ACCOUNT_ERROR = 'SUBMIT_IRA_ACCOUNT_ERROR';

export const FETCH_APPLICATION_ERRORS_REQUEST = 'FETCH_APPLICATION_ERRORS_REQUEST';
export const FETCH_APPLICATION_ERRORS_SUCCESS = 'FETCH_APPLICATION_ERRORS_SUCCESS';
export const FETCH_APPLICATION_ERRORS_ERROR = 'FETCH_APPLICATION_ERRORS_ERROR';

export const FETCH_TRUSTED_CONTACT_REQUEST = 'FETCH_TRUSTED_CONTACT_REQUEST';
export const FETCH_TRUSTED_CONTACT_SUCCESS = 'FETCH_TRUSTED_CONTACT_SUCCESS';
export const FETCH_TRUSTED_CONTACT_ERROR = 'FETCH_TRUSTED_CONTACT_ERROR';

export const SUBMIT_TRUSTED_CONTACT_REQUEST = 'SUBMIT_TRUSTED_CONTACT_REQUEST';
export const SUBMIT_TRUSTED_CONTACT_SUCCESS = 'SUBMIT_TRUSTED_CONTACT_SUCCESS';
export const SUBMIT_TRUSTED_CONTACT_ERROR = 'SUBMIT_TRUSTED_CONTACT_ERROR';

export const DELETE_TRUSTED_CONTACT_REQUEST = 'DELETE_TRUSTED_CONTACT_REQUEST';
export const DELETE_TRUSTED_CONTACT_SUCCESS = 'DELETE_TRUSTED_CONTACT_SUCCESS';
export const DELETE_TRUSTED_CONTACT_ERROR = 'DELETE_TRUSTED_CONTACT_ERROR';

export const SET_RECAPTCHA_VERIFIED = 'SET_RECAPTCHA_VERIFIED';
export const UNSET_RECAPTCHA_VERIFIED = 'UNSET_RECAPTCHA_VERIFIED';

export const FETCH_IDVERIFY_REQUEST = 'FETCH_IDVERIFY_REQUEST';
export const FETCH_IDVERIFY_SUCCESS = 'FETCH_IDVERIFY_SUCCESS';
export const FETCH_IDVERIFY_ERROR = 'FETCH_IDVERIFY_ERROR';

export const USER_TOKEN_REFRESH_REQUEST = 'USER_TOKEN_REFRESH_REQUEST';
export const USER_TOKEN_REFRESH_SUCCESS = 'USER_TOKEN_REFRESH_SUCCESS';
export const USER_TOKEN_REFRESH_ERROR = 'USER_TOKEN_REFRESH_ERROR';
