import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Config from '../../Config';
import { ID_FRONT, ID_BACK } from '../../components/PersonalInfo/Identity/identificationTypes';

const config = new Config();

const isImage = (mimeType) => {
  return mimeType.indexOf('image/') !== -1;
};

const fetchFile = async (fileURL, authToken) => {
  const response = await fetch(fileURL, {
    headers: {
      Authorization: authToken,
    },
  });
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export const removeFile = (files, id, key, onChange, onFileRemove, sectionIndex,
  documentType, isSmallBox, handleErrors = null) => {
  function handleClick(e) {
    e.preventDefault();

    const file = files.filter(x => x.documentId === id);
    const value = files.filter(x => x !== file[0]);
    onChange(value);
    onFileRemove(sectionIndex, documentType, key);
    if (handleErrors && isSmallBox) {
      const name = `docs${sectionIndex}.${documentType}`;
      handleErrors(name, []);
    }
  }

  return (
    <a href="#" onClick={handleClick} alt="Remove">
      {isSmallBox &&
        <i className="material-icons removeFileIcon">delete_forever</i>
      }
      {!isSmallBox &&
        <i className="material-icons">delete_forever</i>
      }
    </a>
  );
};

export const viewFile = (file, authToken, isSmallBox = false) => {
  const fileURL = `${config.apiUrl}${file.documentPath}`;
  const [fileSrc, setFileSrc] = useState('');

  useEffect(() => {
    (async () => {
      const src = await fetchFile(fileURL, authToken);
      setFileSrc(src);
    })();
  }, [fileURL, authToken]);

  if (isImage(file.mimeType)) {
    return (
      <span>
        <a data-tip data-for={file.documentId}>
          {isSmallBox &&
            <i className="material-icons viewFileIcon">zoom_in</i>
          }
          {!isSmallBox &&
            <i className="material-icons">zoom_in</i>
          }
        </a>
        <ReactTooltip id={file.documentId} place="left" class="tooltip">
          <div className="preview">
            <img src={fileSrc} alt="" />
          </div>
        </ReactTooltip>
      </span>
    );
  }

  return (<a href={fileSrc} target="_blank" rel="noreferrer"><i className="material-icons">zoom_in</i></a>);
};

const DocumentsUploaded = (props) => {
  const {
    input,
    sectionIndex,
    documentType,
    authToken,
    handleFileRemove,
    idSubtype,
    handleErrors,
  } = props;

  let files = input.value;
  let isSmallBox = false;

  if (files && (idSubtype === ID_FRONT || idSubtype === ID_BACK)) {
    files = files.filter(f => f.documentSubType === idSubtype);
    isSmallBox = true;
  }

  return (
    <div>
      <input type="Hidden" />
      {files && Array.isArray(files) && !isSmallBox && (
        <table className="table table-bordered table-layout">
          <tbody>
            {files.filter(x => x.documentType === documentType).map((file, i) =>
              (<tr key={i}>
                <td className="cell-documentUploadFile">{file.documentName}</td>
                <td className="cell-documentUploadControl">{viewFile(file, authToken)}</td>
                <td className="cell-documentUploadControl">{
                  removeFile(input.value, file.documentId, file.documentId, input.onChange, handleFileRemove,
                    sectionIndex, documentType, isSmallBox)}
                </td>
              </tr>),
            )}
          </tbody>
        </table>
      )}
      {files && Array.isArray(files) && isSmallBox && (
        <table className="table table-bordered table-layout" style={{ tableLayout: 'fixed' }}>
          <tbody>
            {files.filter(x => x.documentType === documentType).map((file, i) =>
              (<tr key={i}>
                <td className="cell-documentUploadFileMitek">{file.documentName}</td>
                <td className="cell-documentUploadControlMitek">{viewFile(file, authToken, isSmallBox)}</td>
                <td className="cell-documentUploadControlMitek">{
                  removeFile(input.value, file.documentId, file.documentId, input.onChange, handleFileRemove,
                    sectionIndex, documentType, isSmallBox, handleErrors)}
                </td>
              </tr>),
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

DocumentsUploaded.propTypes = {
  input: PropTypes.shape(),
  sectionIndex: PropTypes.number,
  documentType: PropTypes.string,
  authToken: PropTypes.string,
  handleFileRemove: PropTypes.func.isRequired,
  idSubtype: PropTypes.string,
  handleErrors: PropTypes.func,
};

export default DocumentsUploaded;
