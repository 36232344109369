import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../Config';
import * as summaryRowTypes from './summaryRowTypes';

const config = new Config();

const SummaryDetailValue = (props) => {
  const {
    keyValue,
    valueType,
    value,
    authToken,
    canEdit,
    editClasses,
    editLink,
  } = props;

  switch (valueType) {
    case summaryRowTypes.SUMMARY_ROW_TXT:
      return (
        <div key={`value_${keyValue}`} className="col-xs-6">{value}</div>
      );
    case summaryRowTypes.SUMMARY_ROW_BOX: {
      const boxes = value.split('||').map((subValue) => {
        return <span key={`value_${keyValue}_${subValue}`} className="label label-success">{subValue}</span>;
      });
      return (
        <div key={`value_${keyValue}`} className="col-xs-6">
          {boxes}
        </div>
      );
    }
    case summaryRowTypes.SUMMARY_ROW_FILE: {
      const docs = JSON.parse(value);
      const files = docs.map((doc) => {
        return (
          <a
            key={`value_${keyValue}_${doc.documentId}`}
            target="_blank"
            rel="noopener noreferrer"
            href={`${config.apiUrl}${doc.documentPath}?access_token=${authToken}`}
          >
            <i className="material-icons">attach_file</i>
          </a>
        );
      });
      return (
        <div key={`value_${keyValue}`} className="col-xs-6">
          {files}
        </div>
      );
    }
    case summaryRowTypes.SUMMARY_ROW_EDIT: {
      return (canEdit &&
        <div className="col-xs-6 slimv_element text-right bg-white">
          <a className={editClasses} href={`${editLink}?redirectTo=/summary`}>
            Edit
            <i className="material-icons">mode_edit</i>
          </a>
        </div>
      );
    }
    default: return <div />;
  }
};

SummaryDetailValue.propTypes = {
  keyValue: PropTypes.string.isRequired,
  valueType: PropTypes.string.isRequired,
  value: PropTypes.string,
  authToken: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  editClasses: PropTypes.string,
  editLink: PropTypes.string,
};

export default SummaryDetailValue;
