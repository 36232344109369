import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, reduxForm, SubmissionError, change } from 'redux-form';
import moment from 'moment';
import IraAccountForm from '../components/IraAccount/IraAccountForm';
import IraAccountFormValidation from '../validations/IraAccountFormValidation';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as iraAccountActions from '../actions/iraAccountActions';
import * as assetClassActions from '../actions/assetClassActions';
import iraBeneficiaryTexts from '../components/IraAccount/IraBeneficiaryTexts';
import * as assetTypes from '../components/AssetClass/assetTypes';
import * as RouteNavigator from './RouteNavigator';
import * as beneficiaryTypes from '../components/IraAccount/IraBeneficiaryRelationshipTypes';
import optimizeHelper from '../lib/optimizeHelper';
import Config from '../Config';

const config = new Config();

const isNumber = (value) => {
  return (value && !isNaN(value));
};

const noDOBRelationships =
  [beneficiaryTypes.IRA_RELATIONSHIP_ESTATE, beneficiaryTypes.IRA_RELATIONSHIP_ORGANIZATIONCHARIRY];

const shouldRequireDOB = (relationship) => {
  return noDOBRelationships.indexOf(relationship) === -1;
};

function buildDateOfBirth(x) {
  const dob = moment();
  dob.date(x.birthDay);
  dob.month(x.birthMonth - 1);
  dob.year(x.birthYear);
  dob.millisecond(0);
  dob.second(0);
  dob.minute(0);
  dob.hour(0);
  return moment.utc(dob).format();
}

const monthOptions = [
  { value: '', text: '' },
  { value: '1', text: 'January' },
  { value: '2', text: 'February' },
  { value: '3', text: 'March' },
  { value: '4', text: 'April' },
  { value: '5', text: 'May' },
  { value: '6', text: 'June' },
  { value: '7', text: 'July' },
  { value: '8', text: 'August' },
  { value: '9', text: 'September' },
  { value: '10', text: 'October' },
  { value: '11', text: 'November' },
  { value: '12', text: 'December' },
];

const mapDayOptions = () => {
  const options = [{ value: '', text: '' }];

  [...Array(31)].map((_, d) => {
    const day = d + 1;
    options.push({ value: (day).toString(), text: (day).toString() });
    return true;
  });

  return options;
};

const mapBirthYearOptions = () => {
  const options = [{ value: '', text: '' }];

  const max = new Date().getFullYear();

  [...Array(120)].map((_, y) => {
    options.push({ value: (max - y).toString(), text: (max - y).toString() });
    return true;
  });

  return options;
};

export function mapValuesToIraAccount(values) {
  const primaryBeneficiaries = values.iraPrimaryBeneficiaries ? values.iraPrimaryBeneficiaries : [];
  const contingentBeneficiaries = values.iraContingentBeneficiaries ? values.iraContingentBeneficiaries : [];

  for (let i = 0, len = primaryBeneficiaries.length; i < len; i += 1) {
    primaryBeneficiaries[i].type = 'primary';
    if (!shouldRequireDOB(primaryBeneficiaries[i].relationship)) {
      primaryBeneficiaries[i].dateOfBirth = '';
    }
  }

  for (let i = 0, len = contingentBeneficiaries.length; i < len; i += 1) {
    contingentBeneficiaries[i].type = 'contingent';
    if (!shouldRequireDOB(contingentBeneficiaries[i].relationship)) {
      contingentBeneficiaries[i].dateOfBirth = '';
    }
  }

  const iraAccount = {
    iraBeneficiaries: primaryBeneficiaries
      ? primaryBeneficiaries.concat(contingentBeneficiaries)
      : [],

  };

  return iraAccount;
}

export class IraAccountFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExitModal: false,
    };

    this.handleOnChangeRelationship = this.handleOnChangeRelationship.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleExitModelOn = this.handleExitModelOn.bind(this);
    this.handleExitModelOff = this.handleExitModelOff.bind(this);
  }

  componentDidMount() {
    if (!this.props.accountType) {
      this.props.actions.fetchAccountType(this.props.applicationId, this.props.authToken);
    }
    if (!this.props.iraAccount) {
      this.props.actions.fetchIraAccount(this.props.applicationId, this.props.authToken);
    }
    if (!this.props.assetClass) {
      this.props.actions.fetchAssetClass(this.props.applicationId, this.props.authToken);
    }

    optimizeHelper.notify();
  }

  handleOnChangeRelationship(event, value) {
    if (event) {
      const beneficiary = event.split('.');
      const birthYear = beneficiary[0].concat('.birthYear');
      const birthMonth = beneficiary[0].concat('.birthMonth');
      const birthDay = beneficiary[0].concat('.birthDay');
      const ssn = beneficiary[0].concat('.ssn');
      const dateOfBirthRow = document.getElementById(beneficiary[0].concat('.dateOfBirthRow'));

      if (!shouldRequireDOB(value)) {
        this.props.dispatch(change('iraAccount', birthYear, ''));
        this.props.dispatch(change('iraAccount', birthMonth, ''));
        this.props.dispatch(change('iraAccount', birthDay, ''));
        this.props.dispatch(change('iraAccount', ssn, ''));
        dateOfBirthRow.className = 'hideBeneficiaryDOB';
      } else {
        dateOfBirthRow.className = 'showBeneficiaryDOB';
      }
    }
  }

  handleSubmit(values) {
    const submit = !this.props.iraAccountExists ?
      this.props.actions.submitIraAccount :
      this.props.actions.updateIraAccount;
    const iraAccount = mapValuesToIraAccount(values);
    return submit(iraAccount, this.props.applicationId, this.props.authToken)
      .then(() => { RouteNavigator.push('/additional-info'); })
      .catch((error) => { throw new SubmissionError({ _error: error.message }); });
  }

  // eslint-disable-next-line
  handleBack() {
    if (config.optionsEnhancementsEnabled && this.props.isEquitiesApplication) {
      RouteNavigator.push('/equities-account-settings');
    } else {
      RouteNavigator.push('/financial');
    }
  }

  handleExitModelOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModelOff() {
    this.setState({ showExitModal: false });
  }

  render() {
    return (
      <IraAccountForm
        {...this.props}
        iraBeneficiaryRelationshipOptions={iraBeneficiaryTexts}
        onSubmit={this.handleSubmit}
        onBack={this.handleBack}
        handleOnChangeRelationship={this.handleOnChangeRelationship}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModelOn}
        onExitModalOff={this.handleExitModelOff}
      />
    );
  }
}

IraAccountFormContainer.propTypes = {
  actions: PropTypes.shape({
    fetchAccountType: PropTypes.func.isRequired,
    fetchIraAccount: PropTypes.func.isRequired,
    submitIraAccount: PropTypes.func.isRequired,
    updateIraAccount: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
  }).isRequired,
  accountType: PropTypes.shape(),
  iraAccount: PropTypes.shape(),
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  iraAccountExists: PropTypes.bool.isRequired,
  assetClass: PropTypes.shape(),
  dispatch: PropTypes.func,
  isEquitiesApplication: PropTypes.bool,
};


export function mapStateToInitialValues(state) {
  const iraAccount = {
    primaryTotal: 0,
    contingentTotal: 0,
    iraPrimaryBeneficiaries: [{}],
    iraContingentBeneficiaries: [],
  };
  if (state.iraAccount) {
    if (state.accountType && state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_EQUITIES) !== -1 &&
     state.iraAccount.iraBeneficiaries) {
      iraAccount.iraPrimaryBeneficiaries =
        state.iraAccount.iraBeneficiaries.filter(x => x.type === 'primary').map((x) => {
          const item = x;
          const dob = moment(x.dateOfBirth);
          item.birthDay = dob.date();
          item.birthMonth = dob.month() + 1;
          item.birthYear = dob.year();
          item.ssn = '';
          return item;
        });

      iraAccount.iraContingentBeneficiaries =
        state.iraAccount.iraBeneficiaries.filter(x => x.type === 'contingent').map((x) => {
          const item = x;
          const dob = moment(x.dateOfBirth);
          item.birthDay = dob.date();
          item.birthMonth = dob.month() + 1;
          item.birthYear = dob.year();
          item.ssn = '';
          return item;
        });
    }
  }
  return iraAccount;
}

function mapStateToProps(state) {
  const selector = formValueSelector('iraAccount');
  const values = selector(state,
    'rothIraConversion',
    'rothAccountOrigin',
    'iraPrimaryBeneficiaries',
    'iraContingentBeneficiaries',
  );

  const initialValues = mapStateToInitialValues(state);
  let showBeneficiaries = false;
  let primaryTotal = 0;
  let contingentTotal = 0;
  let isFuturesOrFuturesOptions = false;
  let isEquitiesApplication = false;

  if (state.accountType && state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_EQUITIES) !== -1) {
    showBeneficiaries = true;
    isEquitiesApplication = true;
    if (values.iraPrimaryBeneficiaries) {
      values.iraPrimaryBeneficiaries.map((x) => {
        if (x.share) {
          primaryTotal = parseInt(primaryTotal, 10) + parseInt(x.share, 10);
        }
        const item = x;
        if (shouldRequireDOB(x.relationship) &&
          (isNumber(x.birthYear) && isNumber(x.birthMonth) && isNumber(x.birthDay))) {
          item.dateOfBirth = buildDateOfBirth(x);
        }
        return item;
      });
    }

    if (values.iraContingentBeneficiaries) {
      values.iraContingentBeneficiaries.map((x) => {
        if (x.share) {
          contingentTotal = parseInt(contingentTotal, 10) + parseInt(x.share, 10);
        }
        const item = x;
        if (shouldRequireDOB(x.relationship) &&
          (isNumber(x.birthYear) && isNumber(x.birthMonth) && isNumber(x.birthDay))) {
          item.dateOfBirth = buildDateOfBirth(x);
        }
        return item;
      });
    }

    isFuturesOrFuturesOptions =
      state.accountType.assetTypes.includes('futures') || state.accountType.assetTypes.includes('futuresoptions');
  }

  return {
    initialValues,
    authToken: state.authToken,
    applicationId: state.applicationId,
    iraAccountExists: !!state.iraAccount,
    showBeneficiaries,
    months: monthOptions,
    days: mapDayOptions(),
    yearsBirthdate: mapBirthYearOptions(),
    primaryTotal,
    contingentTotal,
    isFuturesOrFuturesOptions,
    assetClass: state.assetClass,
    isEquitiesApplication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(
      {},
      accountTypeActions,
      iraAccountActions,
      assetClassActions,
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'iraAccount',
  validate: IraAccountFormValidation,
})(IraAccountFormContainer));
