import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as summaryValueMapper from './summaryValueMapper';
import SummarySection from '../../components/Summary/SummarySection';
import * as tradingAuthorizationActions from '../../actions/tradingAuthorizationActions';
import * as trustedContactActions from '../../actions/trustedContactActions';
import * as summaryRowTypes from '../../components/Summary/summaryRowTypes';

export class SummaryTradingAuthContainer extends React.Component {
  componentDidMount() {
    if (!this.props.tradingAuthorization) {
      this.props.actions.fetchTradingAuthorization(this.props.applicationId, this.props.authToken);
    }

    if (!this.props.trustedContact) {
      this.props.actions.fetchTrustedContact(this.props.applicationId, this.props.authToken);
    }
  }

  render() {
    return (
      <SummarySection {...this.props} />
    );
  }
}

SummaryTradingAuthContainer.propTypes = {
  actions: PropTypes.shape({
    fetchTradingAuthorization: PropTypes.func,
    fetchTrustedContact: PropTypes.func,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  tradingAuthorization: PropTypes.shape(),
  trustedContact: PropTypes.shape(),
};

export function mapTrustedContactGeneralInfo(trustedContact) {
  let authInfo = [];

  if (trustedContact) {
    if (trustedContact.relationship) {
      authInfo = authInfo.concat([{
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'Relationship',
        value: trustedContact.relationship,
      }]);
    }

    if (trustedContact.email) {
      authInfo = authInfo.concat([{
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'Email Address',
        value: trustedContact.email,
      }]);
    }

    if (trustedContact.phone) {
      authInfo = authInfo.concat([{
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'Phone Number',
        value: trustedContact.phone,
      }]);
    }
  }

  return authInfo;
}

export function mapTrustedContactAddressInfo(trustedContact, countries) {
  let authInfo = [];

  if (trustedContact) {
    if (trustedContact.countryOfResidence) {
      authInfo = authInfo.concat([{
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'Country',
        value: summaryValueMapper.getCountryName(countries, trustedContact.countryOfResidence),
      }]);
    }

    if (trustedContact.address1) {
      authInfo = authInfo.concat([{
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'Street Address 1',
        value: trustedContact.address1,
      }]);
    }

    if (trustedContact.address2) {
      authInfo = authInfo.concat([{
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'Street Address 2',
        value: trustedContact.address2,
      }]);
    }

    if (trustedContact.city) {
      authInfo = authInfo.concat([{
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'City',
        value: trustedContact.city,
      }]);
    }

    if (trustedContact.regionOfResidence) {
      authInfo = authInfo.concat([{
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'State/Province',
        value: summaryValueMapper.getRegionName(
          countries, trustedContact.countryOfResidence, trustedContact.regionOfResidence,
        ),
      }]);
    }

    if (trustedContact.zip) {
      authInfo = authInfo.concat([{
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'Zip/Postal Code',
        value: trustedContact.zip,
      }]);
    }
  }

  return authInfo;
}

function mapStateToInitialValues(state) {
  let isLoading = true;

  if (state.tradingAuthotization && state.trustedContact) {
    isLoading = false;
  }

  const trustedContact = state.trustedContact;

  let authInfo = [
    {
      keyType: summaryRowTypes.SUMMARY_ROW_EDIT,
      valueType: summaryRowTypes.SUMMARY_ROW_EDIT,
      key: 'Trusted Contact',
      value: 'Trusted Contact',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'Name',
      value: trustedContact && trustedContact.fullName ? trustedContact.fullName : 'N/A',
    },
  ];

  if (trustedContact) {
    authInfo = authInfo.concat(
      mapTrustedContactGeneralInfo(trustedContact),
    );

    authInfo = authInfo.concat(
      mapTrustedContactAddressInfo(trustedContact, state.countries),
    );
  }

  return {
    isLoading,
    key: 'tradingAuthentication',
    value: authInfo,
    title: 'Additional Information',
    editLink: '/additional-info',
  };
}

function mapStateToProps(state) {
  const initialValues = mapStateToInitialValues(state);
  const errorMessage = '';

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    isLoading: initialValues.isLoading,
    entity: initialValues.key,
    summaryInfo: initialValues.value,
    title: initialValues.title,
    editLink: initialValues.editLink,
    errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, tradingAuthorizationActions, trustedContactActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTradingAuthContainer);
