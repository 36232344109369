import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as summaryValueMapper from './summaryValueMapper';
import SummarySection from '../../components/SummaryV1/SummarySection';
import * as contactActions from '../../actions/contactActions';
import * as salesRepsActions from '../../actions/salesRepsActions';
import * as hearAboutUsActions from '../../actions/hearAboutUsActions';
import * as summaryRowTypes from '../../components/SummaryV1/summaryRowTypes';
import * as hearAboutUsHelper from '../../lib/containerHelpers/registration/hearAboutUsHelper';

export class SummaryBasicInfoContainer extends React.Component {
  componentDidMount() {
    if (!this.props.contact) {
      this.props.actions.fetchContact(this.props.applicationId, this.props.authToken);
    }
    if (!this.props.salesReps || this.props.salesReps.length === 0) {
      this.props.actions.fetchSalesReps();
    }
    if (!this.props.hearAboutUs || this.props.hearAboutUs.length === 0) {
      this.props.actions.fetchHearAboutUs();
    }
  }

  render() {
    return (
      <SummarySection {...this.props} />
    );
  }
}

SummaryBasicInfoContainer.propTypes = {
  actions: PropTypes.shape({
    fetchContact: PropTypes.func,
    fetchCountries: PropTypes.func,
    fetchSalesReps: PropTypes.func.isRequired,
    fetchHearAboutUs: PropTypes.func.isRequired,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  contact: PropTypes.shape(),
  salesReps: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  hearAboutUs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
};

const getHearAboutUs = (hearAboutUsOptions, hearAboutUsCode) => {
  return summaryValueMapper.getHearAboutUsName(hearAboutUsOptions, hearAboutUsCode);
};

const getHearAboutUsSalesRep = (hearAboutUsSalesRepOptions, hearAboutUsSalesRepCode) => {
  if (!hearAboutUsSalesRepOptions || hearAboutUsSalesRepOptions.length === 0) {
    return hearAboutUsSalesRepCode;
  }

  return hearAboutUsSalesRepOptions
    .filter(o => o.key === hearAboutUsSalesRepCode)
    .slice(0, 1)
    .map((o) => { return o.title; })
    .pop();
};

function mapStateToInitialValues(state, groupIndex) {
  const contacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;

  let isLoading = true;

  if (contacts) {
    isLoading = false;
  }

  const reg = contacts ? contacts[groupIndex] : null;

  let basicInformation = [
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'FIRST NAME',
      value: reg ? reg.firstName : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'MIDDLE INITIAL',
      value: reg ? reg.middleInitial : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'LAST NAME',
      value: reg ? reg.lastName : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'EMAIL ADDRESS',
      value: reg ? reg.email : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'PHONE NUMBER',
      value: reg ? reg.phone : '',
    },
  ];

  if (reg && reg.countryOfResidence) {
    basicInformation = basicInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'COUNTRY OF RESIDENCE',
        value: reg ? summaryValueMapper.getCountryName(state.countries, reg.countryOfResidence) : '',
      },
    ]);
  }

  if (reg && reg.hearAboutUs) {
    basicInformation = basicInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'HOW DID YOU HEAR ABOUT US?',
        value: reg ? getHearAboutUs(state.hearAboutUs, reg.hearAboutUs) : '',
      },
    ]);
  }

  if (reg && (reg.hearAboutUs === hearAboutUsHelper.HEAR_ABOUT_US_SOURCE_OTHER
    || reg.hearAboutUs === hearAboutUsHelper.HEAR_ABOUT_US_EDUCATIONAL_PARTNER)) {
    basicInformation = basicInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'OTHER SOURCE',
        value: reg ? reg.hearAboutUsOther : '',
      },
    ]);
  }

  if (reg && reg.hearAboutUsSalesRep) {
    basicInformation = basicInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'SALES REP. NAME',
        value: reg ? getHearAboutUsSalesRep(state.salesReps, reg.hearAboutUsSalesRep) : '',
      },
    ]);
  }

  return {
    isLoading,
    key: 'registration',
    value: basicInformation,
    title: 'CUSTOMER PROFILE',
    editLink: '/registration',
  };
}

function mapStateToProps(state, ownProps) {
  const groupIndex = ownProps.groupIndex;
  const initialValues = mapStateToInitialValues(state, groupIndex);
  const errorMessage = '';

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    isLoading: initialValues.isLoading,
    entity: initialValues.key,
    summaryInfo: initialValues.value,
    title: initialValues.title,
    editLink: initialValues.editLink,
    errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, contactActions, salesRepsActions, hearAboutUsActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryBasicInfoContainer);
