import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SummarySection from '../../components/SummaryV1/SummarySection';
import accountTypesTexts from '../../components/AccountType/accountTypesTexts';
import * as accountTypesCodes from '../../components/AccountType/accountTypes';
import * as assetClassActions from '../../actions/assetClassActions';
import * as accountTypeActions from '../../actions/accountTypeActions';
import * as contactActions from '../../actions/contactActions';
import * as assetClassTexts from '../../components/AssetClass/assetClassTexts';
import * as summaryRowTypes from '../../components/SummaryV1/summaryRowTypes';
import * as assetTypes from '../../components/AssetClass/assetTypes';
import {
  getAccountType,
  joinArray,
  getAssetClass,
  applyTsgoChanges,
} from '../../lib/containerHelpers/summary/summaryAccountTypeAssetFormContainerHelper';

export class SummaryAccountTypeAssetContainer extends React.Component {
  componentDidMount() {
    if (!this.props.assetClass) {
      this.props.actions.fetchAssetClass(this.props.applicationId, this.props.authToken);
    }

    if (!this.props.accountType) {
      this.props.actions.fetchAccountType(this.props.applicationId, this.props.authToken);
    }

    if (!this.props.contact) {
      this.props.actions.fetchContact(this.props.applicationId, this.props.authToken);
    }
  }

  render() {
    return (
      <SummarySection {...this.props} />
    );
  }
}

SummaryAccountTypeAssetContainer.propTypes = {
  actions: PropTypes.shape({
    fetchAssetClass: PropTypes.func,
    fetchAccountType: PropTypes.func,
    fetchContact: PropTypes.func,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  assetClass: PropTypes.shape(),
  accountType: PropTypes.shape(),
  contact: PropTypes.shape(),
};

function mapStateToInitialValues(state) {
  let isLoading = true;
  let accountType = accountTypesCodes.ACCOUNT_TYPE_INDIVIDUAL;
  let jointType = accountTypesCodes.JOINT_WITH_RIGHTS_OF_SURVIVORSHIP;
  let entityType = accountTypesCodes.ENTITY_CORPORATE;
  let iraType = accountTypesCodes.IRA_TRADITIONAL;
  const accountTypesValues = {};

  if (state.assetClass) {
    isLoading = false;
  }

  if (state.accountType) {
    if (state.accountType.accountType.indexOf('joint') > -1) {
      accountType = accountTypesCodes.ACCOUNT_TYPE_JOINT;
      jointType = state.accountType.accountType;
    } else if (state.accountType.accountType.indexOf('entity') > -1) {
      accountType = accountTypesCodes.ACCOUNT_TYPE_ENTITY;
      entityType = state.accountType.accountType;
    } else if (state.accountType.accountType.indexOf('ira') > -1) {
      accountType = accountTypesCodes.ACCOUNT_TYPE_IRA;
      iraType = state.accountType.accountType;
    } else {
      accountType = state.accountType.accountType;
    }
  }

  accountTypesValues.accountType = getAccountType(accountTypesTexts, accountType);
  accountTypesValues.entityType = getAccountType(accountTypesTexts, entityType);
  accountTypesValues.jointType = getAccountType(accountTypesTexts, jointType);
  accountTypesValues.iraType = getAccountType(accountTypesTexts, iraType);

  const accountTypeValue = accountTypesValues.accountType;
  const accountInformation = [
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'ACCOUNT TYPE',
      value: accountTypeValue,
    },
  ];

  const INDIVIDUAL_TEXT = getAccountType(accountTypesTexts, accountTypesCodes.ACCOUNT_TYPE_INDIVIDUAL);
  const ENTITY_TEXT = getAccountType(accountTypesTexts, accountTypesCodes.ACCOUNT_TYPE_ENTITY);
  const IRA_TEXT = getAccountType(accountTypesTexts, accountTypesCodes.ACCOUNT_TYPE_IRA);

  if (accountTypeValue !== INDIVIDUAL_TEXT) {
    let subTypeValue = '';
    if (accountTypeValue === ENTITY_TEXT) {
      subTypeValue = accountTypesValues.entityType;
    } else if (accountTypeValue === IRA_TEXT) {
      subTypeValue = accountTypesValues.iraType;
    } else {
      subTypeValue = accountTypesValues.jointType;
    }

    accountInformation.splice(1, 0,
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: `TYPE OF ${accountTypeValue.toUpperCase()}`,
        value: subTypeValue,
      },
    );
  }
  /* ASSET CLASS */
  const assetClass = state.assetClass;
  const assetTypesList = state.accountType ? state.accountType.assetTypes : [];
  const numberOfAccounts = assetTypesList.filter(
    assetType => assetType !== assetTypes.ASSET_CLASS_TYPES_CRYPTO_WAITLIST,
  ).length;

  let assetInformation = [
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_BOX,
      key: 'ACCOUNTS TO OPEN',
      value: assetTypesList ? joinArray(assetTypesList, assetClassTexts.assetClassTexts) : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'NUMBER OF ACCOUNTS',
      value: numberOfAccounts,
    },
  ];

  if (
    assetTypesList.includes(assetTypes.ASSET_CLASS_TYPES_EQUITIES) ||
    assetTypesList.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO_WAITLIST) ||
    assetTypesList.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO)) {
    assetInformation = assetInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_BOLD,
        valueType: summaryRowTypes.SUMMARY_ROW_NONE,
        key: 'Equities Account Settings',
        value: 'EQUITIES SECTION TITLE',
      }]);
    assetInformation = assetInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'EQUITY ACCOUNT CLASS',
        value: assetClass && assetClass.marginAccount ?
          getAssetClass(assetClassTexts.assetClassTexts, 'margin') :
          getAssetClass(assetClassTexts.assetClassTexts, 'cash'),
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'EQUITY COMMISSION SCHEDULE',
        value: assetClass ? getAssetClass(assetClassTexts.assetClassTexts, assetClass.commissionSchedule) : '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'TRADE STOCK OPTIONS',
        value: assetClass && assetClass.tradeStockOptions ? 'Yes' : 'No',
      },
    ]);

    if (assetClass && assetClass.tradeStockOptions) {
      assetInformation = assetInformation.concat([
        {
          keyType: summaryRowTypes.SUMMARY_ROW_TXT,
          valueType: summaryRowTypes.SUMMARY_ROW_BOX,
          key: 'INVESTMENT RISK TOLERANCE',
          value: assetClass ? assetClassTexts.investmentRiskTolerance(assetClass.optionsInvestmentGoals) : '',
        },
        {
          keyType: summaryRowTypes.SUMMARY_ROW_TXT,
          valueType: summaryRowTypes.SUMMARY_ROW_BOX,
          key: 'STOCK OPTIONS TRADING LEVEL',
          value: assetClass ? assetClassTexts.stockOptionsTradingLevel(assetClass.optionsStrategies) : '',
        },
      ]);
    }

    assetInformation = assetInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'FULLY PAID LENDING PROGRAM',
        value: assetClass && assetClass.fullyPaidLendingProgram ? 'Yes' : 'No',
      },
    ]);
  }

  if (state.contact) {
    assetInformation = applyTsgoChanges(state, assetInformation);
  }

  Array.prototype.push.apply(accountInformation, assetInformation);
  return {
    isLoading,
    key: 'accountType',
    value: accountInformation,
    title: 'ACCOUNTS',
    editLink: '/account-type',
  };
}

function mapStateToProps(state) {
  const initialValues = mapStateToInitialValues(state);
  const errorMessage = '';

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    isLoading: initialValues.isLoading,
    entity: initialValues.key,
    summaryInfo: initialValues.value,
    title: initialValues.title,
    editLink: initialValues.editLink,
    errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, assetClassActions, accountTypeActions, contactActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryAccountTypeAssetContainer);
