import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Navigation from '../Navigation';
import ExitModal from '../ExitModal';
import InvestmentObjectivesSection from '../Financial/InvestmentObjectivesSection';
import MarginAndOptionsSettings from './MarginAndOptionsSettings';
import OptionsLevel from './OptionsLevel';
import OptionsLevelConfirmationModal from './OptionsLevelConfirmationModal';

const EquitiesAccountSettings = (props) => {
  const {
    handleSubmit,
    onSubmit,
    onBack,
    onExitModalOn,
    onExitModalOff,
    showExitModal,
    canTradeOptions,
    canTradeMargin,
    tradeStockOptions,
    handleMaxOptionsLevel,
    investmentObjective,
    handleTradeStockOptionsChange,
    primaryLiquidNetWorth,
    primaryAnnualIncome,
    jointLiquidNetWorth,
    jointAnnualIncome,
    primaryYearsExperienceOptions,
    jointYearsExperienceOptions,
    marginAccount,
    selectedLevel,
  } = props;

  const [maxOptionsLevel, setMaxOptionsLevel] = useState(handleMaxOptionsLevel());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    setMaxOptionsLevel(handleMaxOptionsLevel());
  }, [
    investmentObjective,
    handleMaxOptionsLevel,
    primaryLiquidNetWorth,
    primaryAnnualIncome,
    primaryYearsExperienceOptions,
    jointLiquidNetWorth,
    jointAnnualIncome,
    jointYearsExperienceOptions,
    marginAccount,
  ]);
  const shouldShowModal =
    selectedLevel < maxOptionsLevel && !props.meta.error && tradeStockOptions && investmentObjective;

  // function for next button
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (shouldShowModal) {
      setShowConfirmationModal(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  // function for modal's continue button
  const handleConfirmSubmit = () => {
    setShowConfirmationModal(false);
    handleSubmit(onSubmit)();
  };

  // function for modal's "Select Another Level" button and X button
  const handleCancelSubmit = () => {
    setShowConfirmationModal(false);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <MarginAndOptionsSettings
        {...props}
        canTradeOptions={canTradeOptions}
        canTradeMargin={canTradeMargin}
        handleTradeStockOptionsChange={handleTradeStockOptionsChange}
      />
      {tradeStockOptions &&
        <Field
          name="investmentObjective"
          component={InvestmentObjectivesSection}
        />
      }
      {investmentObjective &&
        <OptionsLevel
          {...props}
          maxOptionsLevel={maxOptionsLevel}
        />
      }
      <div className="row">
        {showExitModal && (
          <ExitModal onExitModalOn={onExitModalOn} onExitModalOff={onExitModalOff} />
        )}
        <div className="col col-xs-12">
          <Navigation onBack={onBack} onExitModalOn={onExitModalOn} />
        </div>
      </div>
      {showConfirmationModal && (
        <OptionsLevelConfirmationModal
          selectedLevel={selectedLevel}
          handleConfirmSubmit={handleConfirmSubmit}
          handleCancelSubmit={handleCancelSubmit}
          showConfirmationModal={showConfirmationModal}
          maxOptionsLevel={maxOptionsLevel}
        />
      )}
    </form>
  );
};

EquitiesAccountSettings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  showExitModal: PropTypes.bool,
  onExitModalOn: PropTypes.func.isRequired,
  onExitModalOff: PropTypes.func.isRequired,
  initialValues: PropTypes.shape(),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  handleMaxOptionsLevel: PropTypes.func,
  canTradeOptions: PropTypes.bool,
  canTradeMargin: PropTypes.bool,
  tradeStockOptions: PropTypes.bool,
  investmentObjective: PropTypes.string,
  handleTradeStockOptionsChange: PropTypes.func,
  selectedLevel: PropTypes.number,
  primaryLiquidNetWorth: PropTypes.string,
  primaryAnnualIncome: PropTypes.string,
  jointLiquidNetWorth: PropTypes.string,
  jointAnnualIncome: PropTypes.string,
  primaryYearsExperienceOptions: PropTypes.string,
  jointYearsExperienceOptions: PropTypes.string,
  marginAccount: PropTypes.bool,
};

export default EquitiesAccountSettings;
