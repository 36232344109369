import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import OptionsLevelColumn from './OptionsLevelColumn';
import { strategies, levels } from './EquitiesAccountSettingsHelper';

const OptionsLevel = (props) => {
  const {
    selectedLevel,
    maxOptionsLevel,
    meta: { touched, error },
  } = props;

  return (
    <div className="row">
      <div className="col col-md-12">
        <h1 className="h2_subHeading">Options Level</h1>
        <p className="panel-question qualification-text">
          You qualify for level {maxOptionsLevel} options strategies!
        </p>
        <p className="panel-question">Select the desired options level from the available choices below.</p>
      </div>
      <div className="options-level-container">
        {/* Left Side - Strategies List */}
        <div className="options-level-strategies">
          <h5 className="strategies-title">STRATEGIES</h5>
          {strategies.map((strategy, index) => (
            <div key={index} className="options-level-strategy">
              {strategy}
            </div>
          ))}
        </div>
        {/* Right Side - Level Selection (Columns) */}
        <div className="options-level-columns">
          {levels.map(level => (
            <Field
              key={level.id}
              name="selectedLevel"
              component={OptionsLevelColumn}
              level={level}
              selectedLevel={selectedLevel}
              maxOptionsLevel={maxOptionsLevel}
              strategies={strategies}
            />
          ))}
        </div>
      </div>
      {touched && error && (
        <div className="col col-md-12">
          <span className="text-danger">{error}</span>
        </div>
      )}
    </div>
  );
};

OptionsLevel.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  selectedLevel: PropTypes.number,
  maxOptionsLevel: PropTypes.number,
};

export default OptionsLevel;
