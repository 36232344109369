import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { SvgCheckmark, strategies, levels } from './EquitiesAccountSettingsHelper';

const OptionsLevelConfirmationModal = (props) => {
  const {
    selectedLevel,
    handleConfirmSubmit,
    handleCancelSubmit,
    showConfirmationModal,
    maxOptionsLevel,
  } = props;
  const selectedLevelData = levels[selectedLevel - 1];
  const maxLevelData = levels[maxOptionsLevel - 1];
  const selectedLevelName = `${selectedLevelData.name} (Selected)`;
  const selectedLevelShortName = `${selectedLevelData.shortName} (Selected)`;
  const maxLevelShortName = `${maxLevelData.shortName} (Max)`;

  return (
    <Modal
      show={showConfirmationModal}
      onHide={handleCancelSubmit}
      backdrop
      centered
      className="options-submit-modal"
    >
      <div className="options-submit-modal-div">
        <Modal.Header closeButton id="options-modal-title">
          <h1 id="page-header" className="h1_secondary">
            Confirm Options Level Selection
          </h1>
        </Modal.Header>
        <Modal.Body className="options-submit-modal-body">
          <p className="panel-question">
            You have selected a lower options level than you qualify to receive.
            This will limit your access to available options trading strategies.
            How would you like to proceed?
          </p>
          <div className="options-level-comparison">
            <table className="comparison-table">
              <thead>
                <tr>
                  <th className="strategy-text options-submit-modal-header">STRATEGIES</th>
                  <th className="options-submit-modal-header" data-short-name={selectedLevelShortName}>
                    <span id="options-submit-modal-level-header"> {selectedLevelName} </span>
                  </th>
                  <th className="max-level-header options-submit-modal-header" data-short-name={maxLevelShortName}>
                    <span id="options-submit-modal-level-header">Level {maxOptionsLevel} (Max)</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {strategies
                  .filter((_, index) => maxLevelData.strategies.includes(index))
                  .map((strategy, index) => (
                    <tr key={index}>
                      <td className="strategy-text">{strategy}</td>
                      <td className="selected-level-check">
                        {selectedLevelData.strategies.includes(index) ?
                          <SvgCheckmark color="black" cssClass="options-submit-modal-checkmark" /> : ''}
                      </td>
                      <td><SvgCheckmark color="#0089cf" cssClass="options-submit-modal-checkmark" /></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-default back-button do_capitalize options-submit-modal-button"
              onClick={handleConfirmSubmit}
            >
              Continue with Level {selectedLevel}
            </button>
            <button
              type="button"
              className="btn btn-raised btn-default do_capitalize options-submit-modal-button"
              onClick={handleCancelSubmit}
            >
              Select Another Level
            </button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

OptionsLevelConfirmationModal.propTypes = {
  selectedLevel: PropTypes.number,
  handleConfirmSubmit: PropTypes.func.isRequired,
  handleCancelSubmit: PropTypes.func.isRequired,
  showConfirmationModal: PropTypes.bool.isRequired,
  maxOptionsLevel: PropTypes.number.isRequired,
};

export default OptionsLevelConfirmationModal;
