export const configs = {
  test: {
    apiUrl: 'https://ellis-qa-1.tstdev.io',
    cashieringUrl: 'https://funding.tradestation.io/embed.js',
    legacyLogoutUrl: 'https://auth.qa.tradestation.com/logout',
    legacyAOPUrl: 'https://getstarted.qa.tradestation.com',
    autoLogoutURL: 'http://localhost:3001/logout.html?auto',
    fundingUrl: 'https://funding-dev.tradestation.io',
    clientCenterUrl: 'https://clientcenter.qa.tradestation.com/support',
    maxUploadFiles: 5,
    maxUploadFilesProofOfIdentity: 2,
    promoCodeCookieExpirationDays: 15,
    clientId: '983469E5-212D-45D6-9074-6E86FA422CFF',
    clientIdAuth0: 'FfbGiwzT3Y4Yw7nuQ7M9mUNtIAUTtyVn',
    limitTimeMilliSeconds: 60000,
    warningTimeMilliSeconds: 10000,
    tokenTime: 900,
    recaptchaSiteKey: '6LdZBbQUAAAAAAXc8bZAKJC1xqrO124HRqBtr7vT',
    recaptchaSiteKeyV2: '6Ld5YNUUAAAAAL2FMRNwl2J94swS-k5X7NIYL16u',
    writeAnalytics: false,
    credentialsValidationVersion: 'V2',
    introPageVersion: 'V2',
    pendingApplicationsCookieExpirationDays: 30,
    socureSDKKey: 'b586dada-75aa-4354-9b74-c8190b738afd',
    docvAttemptLimit: 3,
    docvExpiredIdLimit: 2,
    mitekErrorLimit: 2,
    inlineFunding: 'V3',
    duplicateRemediationEnabled: true,
    SNIDER_OFFER_CODE: 'SA23AGIB',
    acatAppUrl: 'https://acat.aop-eng-usva.nite.tradestation.io/',
    auth0Enabled: true,
    auth0Tenant: 'https://signin-qa.tradestation.io',
    auth0RedirectUri: 'https://getstarted-qa.tstdev.io/registration',
    // auth0RedirectUri: 'http://localhost:3001/registration',
    auth0Audience: 'https://api.tradestation.io',
    auth0ClientId: 'FfbGiwzT3Y4Yw7nuQ7M9mUNtIAUTtyVn',
    auth0Connection: 'auth0-api-connection',
    auth0Scopes: 'openid profile offline_access MarketData TradeStation',
    auth0LogoutUrl: 'https://signin-qa.tradestation.io/logout',
    getStartedBaseUrl: 'https://getstarted-qa.tstdev.io',
    hubBaseUrl: 'https://hub-dev.tradestation.io?optin=true',
    optionsEnhancementsEnabled: true,
    arkosePublicKey: 'A90F0B17-40B4-4E9F-94B1-AA637F2971D3',
    arkoseEnabled: false,
  },
  internal: {
    apiUrl: 'https://ellis-qa-1.tstdev.io',
    cashieringUrl: 'https://funding.tradestation.io/embed.js',
    legacyLogoutUrl: 'https://auth.qa.tradestation.com/logout',
    autoLogoutURL: 'https://getstarted-qa.tstdev.io/logout.html?auto',
    legacyAOPUrl: 'https://getstarted.qa.tradestation.com',
    fundingUrl: 'https://funding.tradestation.io',
    clientCenterUrl: 'https://clientcenter.qa.tradestation.com/support',
    maxUploadFiles: 5,
    maxUploadFilesProofOfIdentity: 2,
    promoCodeCookieExpirationDays: 15,
    clientId: '983469E5-212D-45D6-9074-6E86FA422CFF',
    clientIdAuth0: 'FfbGiwzT3Y4Yw7nuQ7M9mUNtIAUTtyVn',
    limitTimeMilliSeconds: 60000,
    warningTimeMilliSeconds: 10000,
    tokenTime: 900,
    recaptchaSiteKey: '6LdZBbQUAAAAAAXc8bZAKJC1xqrO124HRqBtr7vT',
    recaptchaSiteKeyV2: '6Ld5YNUUAAAAAL2FMRNwl2J94swS-k5X7NIYL16u',
    writeAnalytics: false,
    credentialsValidationVersion: 'V2',
    introPageVersion: 'V2',
    pendingApplicationsCookieExpirationDays: 30,
    socureSDKKey: 'b586dada-75aa-4354-9b74-c8190b738afd',
    docvAttemptLimit: 3,
    docvExpiredIdLimit: 2,
    mitekErrorLimit: 2,
    inlineFunding: 'V3',
    duplicateRemediationEnabled: true,
    SNIDER_OFFER_CODE: '0035ADAN',
    acatAppUrl: 'https://acat.aop-eng-usva.nite.tradestation.io/',
    auth0Enabled: true,
    auth0Tenant: 'https://signin-qa.tradestation.io',
    auth0RedirectUri: 'https://getstarted-internal.tstdev.io//registration',
    // auth0RedirectUri: 'http://localhost:3001/registration',
    auth0Audience: 'https://api.tradestation.io',
    auth0ClientId: 'FfbGiwzT3Y4Yw7nuQ7M9mUNtIAUTtyVn',
    auth0Connection: 'auth0-api-connection',
    auth0Scopes: 'openid profile offline_access MarketData TradeStation',
    auth0LogoutUrl: 'https://signin-qa.tradestation.io/logout',
    getStartedBaseUrl: 'https://getstarted-internal.tstdev.io',
    hubBaseUrl: 'https://hub-dev.tradestation.io?optin=true',
    optionsEnhancementsEnabled: true,
    arkosePublicKey: 'A90F0B17-40B4-4E9F-94B1-AA637F2971D3',
    arkoseEnabled: false,
  },
  staging: {
    apiUrl: 'https://ellis-staging-1.tradestation.io',
    cashieringUrl: 'https://funding.tradestation.com/embed.js',
    legacyLogoutUrl: 'https://auth.stg.tradestation.com/logout',
    autoLogoutURL: 'https://getstarted-staging.tradestation.io/logout.html?auto',
    legacyAOPUrl: 'https://getstarted.stg.tradestation.com',
    fundingUrl: 'https://funding.tradestation.io',
    clientCenterUrl: 'https://clientcenter.stg.tradestation.com/support',
    maxUploadFiles: 5,
    maxUploadFilesProofOfIdentity: 2,
    promoCodeCookieExpirationDays: 15,
    clientId: '983469E5-212D-45D6-9074-6E86FA422CFF',
    clientIdAuth0: 'vyK1mNru4O2XxuPoVO1QC1fcH0z8QN8E',
    limitTimeMilliSeconds: 1800000,
    warningTimeMilliSeconds: 30000,
    tokenTime: 900,
    recaptchaSiteKey: '6LdrBbQUAAAAAGUaU3s4MBdKK_vonxldZ9vn4oEl',
    recaptchaSiteKeyV2: '6LdsQNgUAAAAAEtf6aTzjLWGZacXr-O_XM4yA6cf',
    writeAnalytics: false,
    credentialsValidationVersion: 'V2',
    introPageVersion: 'V2',
    pendingApplicationsCookieExpirationDays: 30,
    socureSDKKey: 'b586dada-75aa-4354-9b74-c8190b738afd',
    docvAttemptLimit: 3,
    docvExpiredIdLimit: 2,
    mitekErrorLimit: 2,
    inlineFunding: 'V3',
    duplicateRemediationEnabled: true,
    SNIDER_OFFER_CODE: '0035ADAN',
    acatAppUrl: 'https://acat.aop-stg-usva.nite.tradestation.io/',
    auth0Enabled: true,
    auth0Tenant: 'https://signin-stg.tradestation.io',
    auth0RedirectUri: 'https://getstarted-staging.tradestation.io/registration',
    auth0Audience: 'https://api.tradestation.io',
    auth0ClientId: 'vyK1mNru4O2XxuPoVO1QC1fcH0z8QN8E',
    auth0Connection: 'auth0-api-connection',
    auth0Scopes: 'openid profile offline_access MarketData TradeStation',
    auth0LogoutUrl: 'https://signin-stg.tradestation.io/logout',
    getStartedBaseUrl: 'https://getstarted-staging.tradestation.io',
    hubBaseUrl: 'https://hub.tradestation.io?optin=true',
    optionsEnhancementsEnabled: true,
    arkosePublicKey: 'A90F0B17-40B4-4E9F-94B1-AA637F2971D3',
    arkoseEnabled: false,
  },
  staging_2: {
    apiUrl: 'https://ellis-staging-2.tradestation.io',
    cashieringUrl: 'https://funding.tradestation.com/embed.js',
    legacyLogoutUrl: 'https://auth.stg.tradestation.com/logout',
    autoLogoutURL: 'https://getstarted-staging-2.tradestation.io/logout.html?auto',
    legacyAOPUrl: 'https://getstarted.stg.tradestation.com',
    fundingUrl: 'https://funding.tradestation.io',
    clientCenterUrl: 'https://clientcenter.tradestation.io/support',
    maxUploadFiles: 5,
    maxUploadFilesProofOfIdentity: 2,
    promoCodeCookieExpirationDays: 15,
    clientId: '983469E5-212D-45D6-9074-6E86FA422CFF',
    clientIdAuth0: 'vyK1mNru4O2XxuPoVO1QC1fcH0z8QN8E',
    limitTimeMilliSeconds: 1800000,
    warningTimeMilliSeconds: 30000,
    tokenTime: 900,
    recaptchaSiteKey: '6LdrBbQUAAAAAGUaU3s4MBdKK_vonxldZ9vn4oEl',
    recaptchaSiteKeyV2: '6LdsQNgUAAAAAEtf6aTzjLWGZacXr-O_XM4yA6cf',
    writeAnalytics: false,
    credentialsValidationVersion: 'V2',
    introPageVersion: 'V2',
    pendingApplicationsCookieExpirationDays: 30,
    idVerifyEnabled: false,
    socureSDKKey: 'b586dada-75aa-4354-9b74-c8190b738afd',
    docvAttemptLimit: 3,
    docvExpiredIdLimit: 2,
    mitekErrorLimit: 2,
    inlineFunding: 'V3',
    duplicateRemediationEnabled: true,
    SNIDER_OFFER_CODE: '0035ADAP',
    acatAppUrl: 'https://acat.aop-stg-usva.nite.tradestation.io/',
    auth0Enabled: true,
    auth0Tenant: 'https://signin-stg.tradestation.io',
    auth0RedirectUri: 'https://getstarted-staging-2.tradestation.io/registration',
    auth0Audience: 'https://api.tradestation.io',
    auth0ClientId: 'vyK1mNru4O2XxuPoVO1QC1fcH0z8QN8E',
    auth0Connection: 'auth0-api-connection',
    auth0Scopes: 'openid profile offline_access MarketData TradeStation',
    auth0LogoutUrl: 'https://signin-stg.tradestation.io/logout',
    getStartedBaseUrl: 'https://getstarted-staging-2.tradestation.io',
    hubBaseUrl: 'https://hub.tradestation.io?optin=true',
    optionsEnhancementsEnabled: true,
    arkosePublicKey: 'A90F0B17-40B4-4E9F-94B1-AA637F2971D3',
    arkoseEnabled: false,
  },
  staging_3: {
    apiUrl: 'https://ellis-staging-2.tradestation.io',
    cashieringUrl: 'https://funding.tradestation.com/embed.js',
    legacyLogoutUrl: 'https://auth.stg.tradestation.com/logout',
    autoLogoutURL: 'http://getstarted-dev.tradestation.io:3001/logout.html?auto',
    legacyAOPUrl: 'https://getstarted.stg.tradestation.com',
    fundingUrl: 'https://funding.tradestation.io',
    clientCenterUrl: 'https://clientcenter.tradestation.io/support',
    maxUploadFiles: 5,
    maxUploadFilesProofOfIdentity: 2,
    promoCodeCookieExpirationDays: 15,
    clientId: '983469E5-212D-45D6-9074-6E86FA422CFF',
    clientIdAuth0: 'vyK1mNru4O2XxuPoVO1QC1fcH0z8QN8E',
    limitTimeMilliSeconds: 1800000,
    warningTimeMilliSeconds: 30000,
    tokenTime: 900,
    recaptchaSiteKey: '6LdrBbQUAAAAAGUaU3s4MBdKK_vonxldZ9vn4oEl',
    recaptchaSiteKeyV2: '6LdsQNgUAAAAAEtf6aTzjLWGZacXr-O_XM4yA6cf',
    writeAnalytics: false,
    credentialsValidationVersion: 'V2',
    introPageVersion: 'V2',
    pendingApplicationsCookieExpirationDays: 30,
    idVerifyEnabled: false,
    socureSDKKey: 'b586dada-75aa-4354-9b74-c8190b738afd',
    docvAttemptLimit: 3,
    docvExpiredIdLimit: 2,
    mitekErrorLimit: 2,
    inlineFunding: 'V3',
    duplicateRemediationEnabled: true,
    SNIDER_OFFER_CODE: '0035ADAP',
    acatAppUrl: 'https://acat.aop-stg-usva.nite.tradestation.io/',
    // acatAppUrl: 'http://localhost:3000/',
    auth0Enabled: true,
    auth0Tenant: 'https://signin-stg.tradestation.io',
    auth0RedirectUri: 'http://getstarted-dev.tradestation.io:3001/registration',
    auth0Audience: 'https://api.tradestation.io',
    auth0ClientId: 'vyK1mNru4O2XxuPoVO1QC1fcH0z8QN8E',
    auth0Connection: 'auth0-api-connection',
    auth0Scopes: 'openid profile offline_access MarketData TradeStation',
    auth0LogoutUrl: 'https://signin-stg.tradestation.io/logout',
    getStartedBaseUrl: 'http://getstarted-dev.tradestation.io:3001',
    // getStartedBaseUrl: 'http://localhost:3001',
    hubBaseUrl: 'https://hub.tradestation.io?optin=true',
    optionsEnhancementsEnabled: true,
    arkosePublicKey: 'A90F0B17-40B4-4E9F-94B1-AA637F2971D3',
    arkoseEnabled: false,
  },
  production: {
    apiUrl: 'https://ellis-1.tradestation.com',
    cashieringUrl: 'https://funding.tradestation.com/embed.js',
    legacyLogoutUrl: 'https://auth.tradestation.com/logout',
    autoLogoutURL: 'https://getstarted2.tradestation.com/logout.html?auto',
    legacyAOPUrl: 'https://getstarted.tradestation.com',
    fundingUrl: 'https://funding.tradestation.com',
    clientCenterUrl: 'https://clientcenter.tradestation.com/support',
    maxUploadFiles: 5,
    maxUploadFilesProofOfIdentity: 2,
    promoCodeCookieExpirationDays: 15,
    clientId: '983469E5-212D-45D6-9074-6E86FA422CFF',
    clientIdAuth0: 'ingXrTQYXaEobPUzWtcI2rZsiPasezKh',
    limitTimeMilliSeconds: 1800000,
    warningTimeMilliSeconds: 30000,
    tokenTime: 900,
    recaptchaSiteKey: '6LdyBbQUAAAAAAxzhNRrYLpgudQhwd17lTUlak2j',
    recaptchaSiteKeyV2: '6Le7QNgUAAAAANb5gy8fNgp-1C-9WBrIqmb1jvR3',
    writeAnalytics: false,
    credentialsValidationVersion: 'V2',
    introPageVersion: 'V2',
    pendingApplicationsCookieExpirationDays: 30,
    socureSDKKey: '44b484c1-93be-46d1-af16-4967e28f9dfa',
    docvAttemptLimit: 3,
    docvExpiredIdLimit: 2,
    mitekErrorLimit: 2,
    inlineFunding: 'V3',
    duplicateRemediationEnabled: true,
    SNIDER_OFFER_CODE: 'SA23AGIB',
    acatAppUrl: 'https://acat.aop-prod-usva.nite.tradestation.com',
    auth0Enabled: true,
    auth0Tenant: 'https://signin.tradestation.com',
    auth0RedirectUri: 'https://getstarted2.tradestation.com/registration',
    auth0Audience: 'https://api.tradestation.com',
    auth0ClientId: 'gxwUrHXsuWxdFg3UBAk1wxCbndBav1wi',
    auth0Connection: 'auth0-api-connection',
    auth0Scopes: 'openid profile offline_access MarketData TradeStation',
    auth0LogoutUrl: 'https://signin.tradestation.com/logout',
    getStartedBaseUrl: 'https://getstarted2.tradestation.com',
    hubBaseUrl: 'https://my.tradestation.com?optin=true',
    optionsEnhancementsEnabled: false,
    arkosePublicKey: '8C616E00-ED2C-4576-B702-340D3E304E13',
    arkoseEnabled: false,
  },
  static: {
    accountTransferFormUrl: 'https://uploads.tradestation.com/uploads/Customer-Account-Transfer.pdf',
    adobeUrl: 'https://get.adobe.com/reader/',
    currencyFairTransferServiceUrl: 'https://www.currencyfair.com/',
    depositCryptoDesktopAppUrl: 'https://crypto.tradestation.com/profile/transfers',
    depositCryptoMobileAppUrl: 'https://cryptomobile.tradestation.com/transfer',
    entityAccountApplicationKit: 'https://www.tradestation.com/account-applications/entity-application/',
    niumTransferServiceUrl: 'https://www.nium.com/',
    ofxTransferServiceUrl: 'https://www.ofx.com/',
    onlineTransferUrl: 'https://www.tradestation.com/single-forms/online-transfer-and-ach-authorization/',
    payoneerTransferServiceUrl: 'https://www.payoneer.com/',
    stcPayTransferServiceUrl: 'https://stcpay.com.sa/',
    tradestationLearnUrl: 'https://www.tradestation.com/learn/',
    tsInternationalURL: 'https://www.tradestation-international.com/countries-landing/',
  },
};

export function Config(hostname = window.location.hostname, localStorage = window.localStorage) {
  let environment = 'production';

  if (hostname === 'getstarted-qa.tstdev.io'
    || hostname === 'getstarted-dev.tradestation.io'
    || hostname === 'localhost'
    || hostname === '127.0.0.1'
    || hostname === '0.0.0.0') {
    environment = 'test';
  } else if (hostname === 'getstarted-staging.tradestation.io'
    || hostname === 'getstarted-staging.tradestation.com') {
    environment = 'staging';
  } else if (hostname === 'getstarted-staging-2.tradestation.io') {
    environment = 'staging_2';
  } else if (hostname === 'getstarted-internal.tstdev.io') {
    environment = 'internal';
  }

  if (localStorage) {
    // Override environment setting with value from local storage
    environment = localStorage.getItem('environment') || environment;
  }

  let config = configs[environment];
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in configs.static) {
    config[key] = configs.static[key];
  }

  if (localStorage) {
    // Override config with value from local storage
    let customConfig = localStorage.getItem('config');

    if (customConfig) {
      // Apply custom config settings to the current config so users can just override the properties they need
      customConfig = Object.assign({}, config, JSON.parse(customConfig));
    }

    config = customConfig || config;
  }

  return config;
}

export default Config;
