import React from 'react';
import EquitiesAccountSettingsContainer from '../../containers/EquitiesAccountSettingsContainer';
import ProgressBar2 from '../ProgressBar2';

const EquitiesAccountSettingsPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container container-center">
        <div className="row container-width">
          <h1 id="page-header" className="h1_secondary text-center">
            Equities Account Settings
          </h1>
          <div id="appFinancial" className="col col-md-12 col-lg-8 col-lg-offset-2 no-side-padding container-width">
            <EquitiesAccountSettingsContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EquitiesAccountSettingsPage;
