import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as summaryValueMapper from './summaryValueMapper';
import SummarySection from '../../components/SummaryV1/SummarySection';
import * as documentsActions from '../../actions/documentsActions';
import * as documentsRequiredActions from '../../actions/documentsRequiredActions';
import documentsTypesTexts from '../../components/Documents/documentsTypesTexts';
import * as summaryRowTypes from '../../components/SummaryV1/summaryRowTypes';
import * as contactSearchAndFormat from '../../containers/contactSearchAndFormat';
import getSortOrder from '../../containers/documentsSort';

export class SummaryDocumentsContainer extends React.Component {
  componentDidMount() {
    this.props.actions.fetchDocuments(this.props.applicationId, this.props.authToken);
    this.props.actions.fetchDocumentsRequired(this.props.applicationId, this.props.authToken);
  }

  render() {
    const {
      summaryInfo,
      errorMessage,
    } = this.props;

    return (summaryInfo && summaryInfo.length > 0) || errorMessage ? (
      <SummarySection {...this.props} />
    ) : null;
  }
}

SummaryDocumentsContainer.propTypes = {
  actions: PropTypes.shape({
    fetchDocuments: PropTypes.func,
    fetchDocumentsRequired: PropTypes.func,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  summaryInfo: PropTypes.arrayOf(PropTypes.shape()),
  errorMessage: PropTypes.string,
};

const getDocumentType = (documentTypes, documentTypeCode) => {
  return summaryValueMapper.getTextFromValue(documentTypes, documentTypeCode);
};

const getDocumentsError = (state, contact) => {
  let errorMessage = '';
  if (state.applicationErrors && Object.keys(state.applicationErrors).length > 0) {
    const documentsMissing = [];
    const { documentsErrors } = state.applicationErrors;
    documentsErrors.forEach((e) => {
      if (e.contactId === contact.id) {
        [...e.documentsMissing].forEach((r) => {
          documentsMissing.push(getDocumentType(documentsTypesTexts, r));
        });
      }
    });

    if (documentsMissing.length > 0) {
      errorMessage = `Please upload required document(s): ${documentsMissing.join(', ')}`;
    }
  }
  return errorMessage;
};

function mapStateToInitialValues(state, groupIndex) {
  let isLoading = true;
  let document = null;
  const contacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;
  const documents = (state.documents && state.documents.length > 0) ? state.documents : null;
  if (state.documents) {
    isLoading = false;
  }

  const contactType = (groupIndex === 0) ? 'primary' : 'joint';
  const contact = contacts ? contactSearchAndFormat.findContactByType(contactType, contacts) : null;
  if (contact) {
    document = documents ? contactSearchAndFormat.findEntityByContactId(contact.id, documents) : null;
  }

  /* eslint no-param-reassign: ["error", { "props": false }] */
  let documentsParsed = document && document.filesInfo ? document.filesInfo.reduce((builder, doc) => {
    const key = doc.documentType;
    (builder[key] = builder[key] || []).push(doc);
    return builder;
  }, {}) : {};

  documentsParsed = Object.keys(documentsParsed).map(key => documentsParsed[key]);

  let documentsUpload = documentsParsed.map((docs) => {
    return [
      {
        sort: getSortOrder(docs[0].documentType),
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_FILE,
        key: getDocumentType(documentsTypesTexts, docs[0].documentType),
        value: JSON.stringify(docs),
      },
    ];
  });

  documentsUpload = [].concat(...documentsUpload);

  return {
    isLoading,
    key: 'documents',
    value: documentsUpload,
    title: 'UPLOADED DOCUMENTS',
    editLink: '/documents',
    contact,
  };
}

export function mapStateToProps(state, ownProps) {
  const groupIndex = ownProps.groupIndex;
  const initialValues = mapStateToInitialValues(state, groupIndex);
  const errorMessage = getDocumentsError(state, initialValues.contact);
  let editDisabled = false;

  initialValues.value.sort((a, b) => {
    return a.sort - b.sort;
  });

  if (state.documentsRequired) {
    if (state.documentsRequired[groupIndex].requiredDocsUpload.length === 0) {
      editDisabled = true;
    }
  }

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    isLoading: initialValues.isLoading,
    documentsMissing: initialValues.documentsMissing,
    entity: initialValues.key,
    summaryInfo: initialValues.value,
    title: initialValues.title,
    editLink: initialValues.editLink,
    errorMessage,
    editDisabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      documentsRequiredActions,
      documentsActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDocumentsContainer);
